/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewMMUALifeEventContext
 *
 * @class NewMMUALifeEventContext
 */
class NewMMUALifeEventContext {
  /**
   * Creates an instance of NewMMUALifeEventContext.
   * @param {*} json
   * @memberof NewMMUALifeEventContext
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventContextId = json.lifeEventContextId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.common = json.common;

      // string
      this.imageName = json.imageName;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.additionalInfo = json.additionalInfo;

      // string
      this.submissionText = json.submissionText;

      // string
      this.launchDescription = json.launchDescription;

      // boolean
      this.isCitizenConsentEnabled = json.isCitizenConsentEnabled;

      // boolean
      this.isCitizenConsentOneChoiceEnabled = json.isCitizenConsentOneChoiceEnabled;

      // string
      this.imageAltText = json.imageAltText;

      // string
      this.integratedCaseID = json.integratedCaseID;

      // string
      this.applicationEffectiveDate = json.applicationEffectiveDate;

      // string
      this.primaryApplicantName = json.primaryApplicantName;

      // string
      this.caseReference = json.caseReference;
    }
  }
}

/**
 * Models the NewUALifeEventFormIdResponse
 *
 * @class NewUALifeEventFormIdResponse
 */
class NewUALifeEventFormIdResponse {
  /**
   * Creates an instance of NewUALifeEventFormIdResponse.
   * @param {*} json
   * @memberof NewUALifeEventFormIdResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.formId = json.formId;
    }
  }
}

/**
 * Models the NewMMUALifeEventSubmitKey
 *
 * @class NewMMUALifeEventSubmitKey
 */
class NewMMUALifeEventSubmitKey {
  /**
   * Creates an instance of NewMMUALifeEventSubmitKey.
   * @param {*} json
   * @memberof NewMMUALifeEventSubmitKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.formId = json.formId;

      // boolean
      this.isRightsAndResponsibilitiesInd = json.isRightsAndResponsibilitiesInd;

      // boolean
      this.isRenewalofCoverageInd = json.isRenewalofCoverageInd;

      // string
      this.eSignFirstName = json.eSignFirstName;

      // string
      this.eSignLastName = json.eSignLastName;

      // string
      this.eSignMiddleName = json.eSignMiddleName;
    }
  }
}

/**
 * Models the NewMMUAFinishLifeEventResultResponse
 *
 * @class NewMMUAFinishLifeEventResultResponse
 */
class NewMMUAFinishLifeEventResultResponse {
  /**
   * Creates an instance of NewMMUAFinishLifeEventResultResponse.
   * @param {*} json
   * @memberof NewMMUAFinishLifeEventResultResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventReferenceNumber = json.lifeEventReferenceNumber;
    }
  }
}

export default {
  NewMMUALifeEventContext,

  NewUALifeEventFormIdResponse,

  NewMMUALifeEventSubmitKey,

  NewMMUAFinishLifeEventResultResponse,
};
