/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { EnvUtils } from '@spm/core';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button } from '@govhhs/govhhs-design-system-react';
import translations from './ApplicationHeaderMessages';
import { ID } from '../constants';
import MMPATHS from '../../../paths';

/**
 * Returns the sign in menu for display on pages that are accessible without
 * being logged in.
 */
const SignInMenuComponent = props => (
  <>
    <span className="wds-u-divider--bottom" />
    {!EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_ENABLED') && (
      <Button
        key="signup"
        category="link"
        data-testid={ID.APPLICATION_HEADER_SIGNUP_LINK_ID}
        navClose
        onClick={e => {
          // This is the large screen size signup button
          e.preventDefault();
          props.history.push({
            pathname: MMPATHS.SIGNUP,
            params: { previousPathname: props.location.pathname },
          });
        }}
        size="small"
      >
        {props.intl.formatMessage(translations.headerSignUpText)}
      </Button>
    )}
    <Button
      key="login"
      // START : MMPROD-4106: dhdkuma - changing the category from primary to success.
      category="success"
      // END : MMPROD-4106: dhdkuma.
      className="wds-u-hide--nav-width-down"
      data-testid={ID.APPLICATION_HEADER_LOGIN_LINK_ID}
      navClose
      onClick={e => {
        // This is the large screen size login button
        e.preventDefault();
        if (EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_ENABLED')) {
          props.history.push({
            pathname: MMPATHS.SIGNUPORLOGIN,
            params: { previousPathname: props.location.pathname },
          });
        } else {
          props.history.push({
            pathname: MMPATHS.LOGIN,
            params: { previousPathname: props.location.pathname },
          });
        }
      }}
      size="small"
    >
      {props.intl.formatMessage(translations.headerLogInText)}
    </Button>
  </>
);

/**
 * The SignInMenuComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof SignInMenuComponent
 */
SignInMenuComponent.propTypes = {
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(SignInMenuComponent));
