/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { POST_MM_LIFE_EVENTS_FORM, POST_MM_LIFE_EVENTS_FORM_SUBMIT } from './actionTypes';

import { postMmLifeEventsFormUtil, postMmLifeEventsFormSubmitUtil } from './utils';

/**
 * Contains the action creator functions that update the MMLifeEvents slice of the store.
 *
 * @namespace MMLifeEventsActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/life_events_form
   *
   * @param {NewMMUALifeEventContext} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMLifeEventsActions
   */
  static postMmLifeEventsForm = (dispatch, newMMUALifeEventContext, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmLifeEventsFormUtil.bind(null, newMMUALifeEventContext),
      POST_MM_LIFE_EVENTS_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmLifeEventsForm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMLifeEventsActions
   */
  static resetPostMmLifeEventsForm = (dispatch, data) => {
    const json = {
      type: POST_MM_LIFE_EVENTS_FORM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmLifeEventsForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMLifeEventsActions
   */
  static resetPostMmLifeEventsFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_LIFE_EVENTS_FORM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/life_events_form/submit
   *
   * @param {NewMMUALifeEventSubmitKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMLifeEventsActions
   */
  static postMmLifeEventsFormSubmit = (dispatch, newMMUALifeEventSubmitKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmLifeEventsFormSubmitUtil.bind(null, newMMUALifeEventSubmitKey),
      POST_MM_LIFE_EVENTS_FORM_SUBMIT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmLifeEventsFormSubmit.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMLifeEventsActions
   */
  static resetPostMmLifeEventsFormSubmit = (dispatch, data) => {
    const json = {
      type: POST_MM_LIFE_EVENTS_FORM_SUBMIT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmLifeEventsFormSubmit.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMLifeEventsActions
   */
  static resetPostMmLifeEventsFormSubmitError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_LIFE_EVENTS_FORM_SUBMIT.failureAction);
  };
}
