/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { EnvUtils } from '@spm/core';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Header,
  PrimaryNavigation,
  SecondaryNavigation,
  Tabs,
  Tab,
} from '@govhhs/govhhs-design-system-react';
import PATHS from '@spm/universal-access-ui/src/router/Paths';
import featureToggle from '@spm/universal-access-ui/src/features/featureToggles';
import translations from './ApplicationHeaderMessages';
import SignInMenuComponent from './SignInMenuComponent';
import MMPATHS from '../../../paths';
import * as ApplicationHeaderLogic from './ApplicationHeaderLogic';
import Authentication from '../../../modules/Authentication/Authentication';
import { ID, NAVIGATION_HEADER_TABS } from '../constants';
import logo from '../../../img/SCDHHS-Healthy-Connections-Medicaid-Logo.png';
import { MMProfileModels } from '../../../modules/generated';

/**
 * Returns true if the Your Account tab is active for the current url pathname. If
 * true the primary navigation contains specific navigation tabs.
 *
 * @param {*} urlPathname current url pathname.
 * @returns true if tab should be active.
 */
const isYourAccountTabActive = urlPathname =>
  ApplicationHeaderLogic.isTabActiveForUrlPathname(
    urlPathname,
    NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.NAME
  );

/**
 * Returns the ApplicationHeaderComponent.
 *
 * @param {*} props props passed to this component.
 * @returns {Component} ApplicationHeaderComponent.
 */
const ApplicationHeaderComponent = props => {
  const {
    intl,
    location,
    profile: userProfile,
    hasErrorBeenCaught,
    history,
    // START MMPROD-7976
    isBaseFormIegRunning,
    // END MMPROD-7976
    isAppealsEnabled,
    isALinkedUser,
    isEmpty,
    mainContentId,
  } = props;

  const { formatMessage } = intl;
  const { pathname: currentUrlPathname } = location;
  const { getActivePrimaryNavigationTabId, getActiveSecondaryNavigationTabId } =
    ApplicationHeaderLogic;

  const marginDirection = document.dir === 'rtl' ? { marginRight: 'auto' } : { marginLeft: 'auto' };

  const skipProps = {};
  if (mainContentId) {
    skipProps.mainContentId = mainContentId;
    skipProps.skipNavigationLinkText = intl.formatMessage(translations.skipNav);
  }

  if (isEmpty) {
    return (
      <Header
        key="application-header"
        data-testid={ID.APPLICATION_HEADER_COMPONENT_ID}
        logo={<img alt="agency logo" aria-hidden id="sso-verifier-header" src={logo} />}
        menuHidden
        title=" "
        titleHoverText={formatMessage(translations.headerHomeLabel)}
        type="scrollable"
        {...skipProps}
      >
        <span />
      </Header>
    );
  }

  // START MMPROD-7976
  // MMPROD-9089 removed login screen from condition displaying header
  // MMPROD-9146 - removed signup screen from logo-only header condition
  const displayLogoOnly = () => {
    if (
      isBaseFormIegRunning ||
      location.pathname === PATHS.IEGVERIFICATIONS.DETAILS ||
      location.pathname === PATHS.IEGVERIFICATIONS.ROOT ||
      location.pathname.startsWith(MMPATHS.MM_HCR_ESIGNATURE) ||
      (/\/apply\/-?\d+/.test(location.pathname) && !/confirm/.test(location.pathname))
    ) {
      return true;
    }
    return false;
  };
  // END MMPROD-7976

  // START MMPROD-7976 - do not display menu options in IEG form
  return displayLogoOnly() ? (
    <Header
      key="application-header"
      data-testid={ID.APPLICATION_HEADER_COMPONENT_ID}
      logo={<img alt="agency logo" aria-hidden id="sso-verifier-header" src={logo} />}
      menuHidden
      title=" "
      titleHoverText={formatMessage(translations.headerHomeLabel)}
      type="scrollable"
      {...skipProps}
    >
      <span />
    </Header>
  ) : (
    // END MMPROD-7976 - do not display menu options in IEG form
    <Header
      key="application-header"
      data-testid={ID.APPLICATION_HEADER_COMPONENT_ID}
      link={<RouterLink to={PATHS.HOME} />}
      logo={
        <img
          alt="agency logo"
          aria-hidden
          className="custom-banner-logo"
          data-testid={ID.APPLICATION_HEADER_HOME_LINK_ID}
          id={ID.APPLICATION_HEADER_HOME_LINK_ID}
          src={logo}
        />
      }
      menuButtonTitle={formatMessage(translations.headerMenuLabel)}
      title=" "
      titleHoverText={formatMessage(translations.headerHomeLabel)}
      type="scrollable"
      {...skipProps}
    >
      <PrimaryNavigation type="scrollable">
        {isYourAccountTabActive(currentUrlPathname) && (
          <Tabs
            key="primary-tabs"
            ariaLabel="primary-tabs"
            current={getActivePrimaryNavigationTabId(currentUrlPathname)}
            scrollable
          >
            {/* START : MMPROD-4496 : dhdkuma - Account Dashboard Changes, modifying the tabs */}
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.DASHBOARD.ID}
              id={NAVIGATION_HEADER_TABS.DASHBOARD.ID}
              label={formatMessage(translations.headerDashboardLabel)}
              link={<RouterLink to={PATHS.ACCOUNT} />}
            />
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.YOUR_BENEFITS.ID}
              id={NAVIGATION_HEADER_TABS.YOUR_BENEFITS.ID}
              label={formatMessage(translations.headerYourBenefitsLabel)}
              link={<RouterLink to={MMPATHS.MM_APPLICATIONS} />}
            />
            {/* START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications */}
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.YOUR_INFORMATION.ID}
              id={NAVIGATION_HEADER_TABS.YOUR_INFORMATION.ID}
              label={formatMessage(translations.headerProfileText)}
              link={<RouterLink to={MMPATHS.YOUR_INFORMATION} />}
            />
            {/* END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications */}
            {/* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom path */}
            {featureToggle.verifications.isEnabled() && isALinkedUser() && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.VERIFICATIONS.ID}
                id={NAVIGATION_HEADER_TABS.VERIFICATIONS.ID}
                label={formatMessage(translations.headerYourVerificationsText)}
                link={<RouterLink to={MMPATHS.MM_VERIFICATIONS.ROOT} />}
              />
            )}
            {/* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */}
            {isALinkedUser() && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.NOTICES.ID}
                id={NAVIGATION_HEADER_TABS.NOTICES.ID}
                label={formatMessage(translations.headerNoticesLabel)}
                link={<RouterLink to={PATHS.NOTICES.ROOT} />}
              />
            )}
            {/* START DHRCHAD - MMPROD-6582 - V8 Upgrade - Remove condition to display Appeals tab */}
            {isAppealsEnabled && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.APPEALS.ID}
                id={NAVIGATION_HEADER_TABS.APPEALS.ID}
                label={formatMessage(translations.headerAppealsLabel)}
                link={<RouterLink to={PATHS.APPEALS.ROOT} />}
              />
            )}
            {/* END DHRCHAD - MMPROD-6582 - V8 Upgrade - Remove condition to display Appeals tab */}
            {/* BEGIN : MMPROD-4990 : DHRYOUN - renamed path */}
            {!EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_ENABLED') && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.RESETPASSWORD.ID}
                id={NAVIGATION_HEADER_TABS.RESETPASSWORD.ID}
                label={formatMessage(translations.headerResetPasswordText)}
                link={<RouterLink to={MMPATHS.MM_RESETPASSWORD} />}
              />
            )}
            {/* END : MMPROD-4990 : DHRYOUN - renamed path */}
            {/* END : MMPROD-4496 : dhdkuma */}
          </Tabs>
        )}
      </PrimaryNavigation>
      <SecondaryNavigation type="scrollable">
        <Tabs
          key="secondary-tabs"
          ariaLabel="secondary-tabs"
          className="wds-u-mr--small"
          current={getActiveSecondaryNavigationTabId(currentUrlPathname)}
        >
          <Tab
            data-testid={NAVIGATION_HEADER_TABS.HOME.ID}
            id={NAVIGATION_HEADER_TABS.HOME.ID}
            label={formatMessage(translations.headerHomeLabel)}
            link={<RouterLink to={PATHS.HOME} />}
            // START MMPROD-9092
            onClick={() => {
              if (Authentication.userTypeIs([Authentication.USER_TYPES.GENERATED])) {
                Authentication.logout();
                history.push(PATHS.HOME);
              }
            }}
            // END MMPROD-9092
          />
          {userProfile && (
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.ID}
              id={NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.ID}
              label={formatMessage(translations.headerYourAccountLabel)}
              link={<RouterLink to={PATHS.ACCOUNT} />}
            />
          )}
        </Tabs>
        {/* Show profile menu or signed out menu */}
        {userProfile ? (
          <Button
            key="logout"
            category="success"
            className="wds-u-mr--small"
            data-testid={ID.PROFILE_MENU_LOGOUT_BUTTON_ID}
            onClick={() => {
              if (location.pathname !== PATHS.HOME) {
                history.push(PATHS.HOME);
              }
              Authentication.logout();
            }}
            size="small"
            style={marginDirection}
          >
            {formatMessage(translations.headerLogOutText)}
          </Button>
        ) : (
          !hasErrorBeenCaught && <SignInMenuComponent />
        )}
      </SecondaryNavigation>
      {!userProfile && !hasErrorBeenCaught && (
        <Button
          key="login"
          category="link"
          className="wds-u-hide--nav-width-up"
          data-testid={ID.APPLICATION_HEADER_LOGIN_LINK_ID}
          onClick={e => {
            // This is the mobile screen login button
            e.preventDefault();
            props.history.push({
              pathname: MMPATHS.LOGIN,
              params: { previousPathname: location.pathname },
            });
          }}
          style={marginDirection}
        >
          {formatMessage(translations.headerLogInText)}
        </Button>
      )}
    </Header>
  );
};

/**
 * The ApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof ApplicationHeaderComponent
 */
ApplicationHeaderComponent.propTypes = {
  hasErrorBeenCaught: PropTypes.bool,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isALinkedUser: PropTypes.func.isRequired,
  isAppealsEnabled: PropTypes.bool.isRequired,
  // START MMPROD-7976
  isBaseFormIegRunning: PropTypes.string,
  // END MMPROD-7976
  isEmpty: PropTypes.bool,
  isUserCanAppeal: PropTypes.bool,
  location: PropTypes.object.isRequired,
  mainContentId: PropTypes.string,
  profile: PropTypes.instanceOf(MMProfileModels),
};

/**
 * The ApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof ApplicationHeaderComponent
 */
ApplicationHeaderComponent.defaultProps = {
  hasErrorBeenCaught: false,
  // START MMPROD-7976
  isBaseFormIegRunning: null,
  // END MMPROD-7976
  isEmpty: false,
  isUserCanAppeal: false,
  mainContentId: null,
  profile: null,
};

export default withRouter(injectIntl(ApplicationHeaderComponent));
