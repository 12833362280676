/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { Button, Dialog } from '@govhhs/govhhs-design-system-react';
import { injectIntl } from 'react-intl';
import translations from './SessionTimeoutDialogComponentMessages';
import Authentication from '../../modules/Authentication/Authentication';
import { ID } from './constants';
import paths from '../../paths';

const MMIdleTimer = props => {
  const timeoutEnvValue = `${process.env.REACT_APP_SESSION_INACTIVITY_TIMEOUT}`
    ? `${process.env.REACT_APP_SESSION_INACTIVITY_TIMEOUT}` * 1000
    : 60000 * 30;

  const { intl } = props;

  const timeout = timeoutEnvValue;

  // timeout dialog  displayed for this duration in milliseconds
  const promptBeforeIdle = 120000;

  const [state, setState] = useState('Active');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [remaining, setRemaining] = useState(timeoutEnvValue);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);

  const navigate = useHistory();

  /**
   * Log the user out of the session and logs in as public citizen.
   *
   * @memberof SessionTimeoutContainer
   */
  const logout = callback => {
    console.log('logout triggered');
    // START MMPROD-19629
    const location = navigate.location.pathname;
    const userTypes = Authentication.USER_TYPES;

    if (
      !Authentication.userTypeIs([userTypes.PUBLIC]) ||
      (location !== paths.HOME &&
        location !== paths.SIGNUPORLOGIN &&
        location !== `${paths.RENEWAL.ROOT}/beforeWeStart`)
    ) {
      // END MMPROD-19629
      console.log('redirecting');
      const pathname = `${process.env.REACT_APP_SESSION_TIMEOUT_REDIRECT_URL || '/login'}`;
      Authentication.logout(callback, false);

      navigate.push({
        pathname,
        state: {
          sessionCountdownTimerEnd: true,
        },
      });
    }
  };

  /**
   * Updates the session timeout dialogs visibility status to false, closing the
   * dialog.
   *
   * @memberof SessionTimeoutContainer
   */
  const closeSessionTimeoutDialog = () => {
    setIsDialogVisible(false);
    window.govhhswds.dialog.close(document);
  };

  const onIdle = () => {
    closeSessionTimeoutDialog();
    setState('Idle');
    logout();
    setOpen(false);
  };

  const onActive = () => {
    setState('Active');
    setOpen(false);
  };

  const onPrompt = () => {
    setIsDialogVisible(true);
    setState('Prompted');
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: [
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  });

  const userLoggedIn = () =>
    Authentication.userTypeIs([
      Authentication.USER_TYPES.STANDARD,
      Authentication.USER_TYPES.LINKED,
      Authentication.USER_TYPES.GENERATED,
    ]);

  const isPublicCitizen = () => Authentication.userTypeIs([Authentication.USER_TYPES.PUBLIC]);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  // console.log(`remaining - ${remaining}`);

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  // console.log(`timeTillPrompt - ${timeTillPrompt}`);

  /**
   * function to construct the time displayed on the popup.
   */
  function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const timeseconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${timeseconds < 10 ? '0' : ''}${timeseconds}`;
  }

  /**
   * Handle signing out the user. If this is called because the session timeout
   * countdown has expired forward this data to the login page during the redirect.
   *
   * @memberof SessionTimeoutContainer
   * @param countdownTimerEnd true if the session countdown timer reaches zero.
   */
  const handleSignOutAndRedirectToLogin = () => {
    setIsDialogVisible(false);
    closeSessionTimeoutDialog();
    logout();
  };

  const titleText = intl.formatMessage(translations.title);
  const logOutBtnText = intl.formatMessage(translations.signOutButton);
  const stayLoggedInBtnText = intl.formatMessage(translations.stayButton);

  // inactivityDialogCount={120} - timeout popup window display time in seconds
  return (
    <>
      {userLoggedIn() && !isPublicCitizen && state === 'Prompted' && isDialogVisible && (
        <Dialog
          ariaLabel={`${intl.formatMessage(translations.titleAriaLabel, {
            titleText,
            logOutBtnText,
            stayLoggedInBtnText,
          })}`}
          blocking
          data-testid={ID.SESSION_TIMEOUT_MODAL_DIALOG_ID}
          onActionButtonClick={handleStillHere}
          onClose={closeSessionTimeoutDialog}
          open
          primaryButton={
            <Button
              category="primary"
              data-testid={ID.SESSION_TIMEOUT_MODAL_DIALOG_STAY_LOGGED_IN_BUTTON_ID}
            >
              {stayLoggedInBtnText}
            </Button>
          }
          secondaryButton={
            <Button
              category="link"
              data-testid={ID.SESSION_TIMEOUT_MODAL_DIALOG_STAY_SIGN_OUT_BUTTON_ID}
              onClick={handleSignOutAndRedirectToLogin}
            >
              {logOutBtnText}
            </Button>
          }
          title={intl.formatMessage(translations.title)}
        >
          <p data-testid={ID.SESSION_TIMEOUT_MODAL_DIALOG_INFORMATIONAL_MESSAGE_ID}>
            {intl.formatMessage(translations.primaryMsg)}
          </p>
          <p data-testid={ID.SESSION_TIMEOUT_MODAL_DIALOG_COUNTDOWN_TIMER_ID}>
            {intl.formatMessage(translations.timerHeading)}
            <b role="timer">{millisToMinutesAndSeconds(remaining * 1000)}</b>
          </p>
        </Dialog>
      )}
    </>
  );
};

MMIdleTimer.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MMIdleTimer);
