/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { RESTService } from '@spm/core';
import React from 'react';
import Authentication from '../../modules/Authentication/Authentication';

let pollingId;
let isCurrentlyOnline = true;

const connectivityURL = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/validate_session`;
const connectivityInterval = process.env.REACT_APP_CONNECTIVITY_INTERVAL
  ? process.env.REACT_APP_CONNECTIVITY_INTERVAL
  : '5000';

const isPollingEnabled = process.env.REACT_APP_CONNECTIVITY_POLLING_ENABLED !== 'false';

/**
 * ConnectivityPolling. ConnectivityPolling. This function is used to make the connectivity ping
 *
 */
let pollingFunction = pingCallback => {
  const sessionID =
    sessionStorage.getItem('sessionID') !== null ? sessionStorage.getItem('sessionID') : 'dummy';

  RESTService.get(
    connectivityURL,
    pingCallback,
    // START MMPROD-16126 - Concurrent session
    { sessionID }
    // END MMPROD-16126 - Concurrent session
  );
};

/**
 * ConnectivityPolling. This function is used to handle the response of the connectivity ping
 *
 */
/* eslint-disable sonarjs/cognitive-complexity */
const pingCallbackResponseHandler = (response, goOnline, goOffline, history) => {
  /* We need !! to convert the value into a boolean, otherwise every new response is considered a status change.
     With this, only transitions from true to false or viceversa are considered status changes. */
  const isOnline = !!(
    response &&
    (response.status || (response.response && response.response.status))
  );

  if (response) {
    if (response.isInvalidSession) {
      const pathname = `${process.env.REACT_APP_SESSION_TIMEOUT_REDIRECT_URL || '/login'}`;

      sessionStorage.removeItem('sessionID');
      Authentication.logout();
      history.push(pathname);
    } else if (!response.isInvalidSession) {
      console.log('The session is valid.');
    }
  }

  const onlineStatusChange = isOnline !== isCurrentlyOnline;
  if (onlineStatusChange) {
    isCurrentlyOnline = isOnline;
    return isOnline ? goOnline() : goOffline();
  }
  return null;
};

/**
 * ConnectivityPolling. This function is used to register the connectivity polling function
 *
 */
const registerConnectivityPollingFunction = newFunction => {
  pollingFunction = newFunction;
};

/**
 * ConnectivityPolling. This function is used to set the connectivity polling and process the response
 *
 */
const startPolling = (goOnline, goOffline, history) => {
  /** ConnectivityPolling. This function is the callback for the connectivity ping */
  if (isPollingEnabled) {
    const pingCallback = (success, response) => {
      pingCallbackResponseHandler(response, goOnline, goOffline, history);
    };

    pollingId = window.setInterval(() => {
      pollingFunction(pingCallback);
    }, connectivityInterval);
  }
};

/**
 * ConnectivityPolling. This function is used to clean up the connectivity polling
 *
 */
const stopPolling = () => {
  if (isPollingEnabled) {
    clearInterval(pollingId);
  }
};

/**
 * ConnectivityPolling. This function is used to set isCurrentlyOnline
 *
 */
const setPollingOnlineStatus = status => {
  isCurrentlyOnline = status;
};

/**
 * ConnectivityPolling. This function is used to get isCurrentlyOnline
 *
 */
const getPollingOnlineStatus = () => isCurrentlyOnline;

/**
 * ConnectivityPolling. This function is used to get customURLGenerator
 *
 */
const getConnectivityPollingFunction = () => pollingFunction;

/**
 * ConnectivityPolling. This function is used to get pollingId
 *
 */
const getPollingId = () => pollingId;

/**  Design System bug * https://github.ibm.com/WH-GovHHS/GovHHS-Design-System/issues/81
 *   SPM-WebApps Issue https://github.ibm.com/WH-GovSPM/SPM-WebApps/issues/6767
 */
const DismissIcon = (
  <svg
    // eslint-disable-next-line no-restricted-syntax
    className="wds-c-icon wds-c-icon-16"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.87762249,8.00074375 L15.8192693,1.05909697 C16.0602436,0.817130993 16.0602436,0.423440454 15.8192693,0.18147448 C15.5773033,-0.0604914934 15.1836128,-0.0604914934 14.9416468,0.18147448 L8,7.12312126 L1.05835322,0.18147448 C0.816387245,-0.0604914934 0.42368837,-0.0604914934 0.180730732,0.18147448 C-0.0602435774,0.423440454 -0.0602435774,0.817130993 0.180730732,1.05909697 L7.12237751,8.00074375 L0.180730732,14.9423905 C-0.0602435774,15.1843565 -0.0602435774,15.578047 0.180730732,15.820013 C0.302705383,15.940996 0.461371595,16.0004958 0.620037807,16.0004958 C0.778704019,16.0004958 0.937370231,15.940996 1.05835322,15.820013 L8,8.87836623 L14.9416468,15.820013 C15.0626298,15.940996 15.221296,16.0004958 15.3809539,16.0004958 C15.5396201,16.0004958 15.6982863,15.940996 15.8192693,15.820013 C16.0602436,15.578047 16.0602436,15.1843565 15.8192693,14.9423905 L8.87762249,8.00074375 Z"
      fillRule="evenodd"
    />
  </svg>
);
export {
  startPolling,
  stopPolling,
  setPollingOnlineStatus,
  getPollingId,
  pingCallbackResponseHandler,
  connectivityURL,
  connectivityInterval,
  getPollingOnlineStatus,
  DismissIcon,
  registerConnectivityPollingFunction,
  getConnectivityPollingFunction,
};
