/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  footerContactUs: {
    id: 'Footer_ContactUs',
    defaultMessage: 'Contact us',
  },
  footerPrivacy: {
    id: 'Footer_Privacy',
    defaultMessage: 'Privacy',
  },
  footerFAQ: {
    id: 'Footer_FAQ',
    // START MMPROD-14382: updated label text
    defaultMessage: 'Help/FAQ',
    // END MMPROD-14382
  },
  footerNavigation: {
    id: 'Footer_Navigation',
    defaultMessage: 'Footer navigation',
  },
  // START MMPROD-4362 : dhdkuma - Application Footer Labels Added
  footerNoticeofNonDiscrimination: {
    id: 'Footer_NND',
    defaultMessage: 'Notice of Non-Discrimination',
  },
  footerNoticeofPrivacyPractices: {
    id: 'Footer_NPP',
    defaultMessage: 'Notice of Privacy Practices',
  },
  footerPrivacyStatement: {
    id: 'Footer_PrivacyStatement',
    defaultMessage: 'Privacy Statement',
  },
  // END MMPROD-4362 : dhdkuma - Application Footer Labels Added
});
