/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon } from '@govhhs/govhhs-design-system-react';
import { ToasterActions, ConnectivityActions, ConnectivitySelectors } from '@spm/core';
import Toast from '@spm/core-ui/lib/components/Toaster/Toast';
import { useHistory } from 'react-router-dom';
import messages from './ConnectivityHandlerMessages';
import {
  startPolling,
  stopPolling,
  setPollingOnlineStatus,
  DismissIcon,
} from './ConnectivityPolling';

export const CloseIcon = <Icon decorative pathNode={DismissIcon} size="small" />;

/**
 * ConnectivityHandler component. This component will manage the online/offline state of the app that wraps
 *
 */
const ConnectivityHandler = ({ intl, children }) => {
  const history = useHistory();
  const preventActionOnOffline = useSelector(state =>
    ConnectivitySelectors.getPreventActionOnOffline(state)
  );

  const dispatch = useDispatch();

  /**
   * Redux function to display the toast
   *
   * @memberof ConnectivityHandler
   */
  const fillToaster = data => {
    ToasterActions.fillToaster(dispatch, data);
  };

  /**
   * Redux function to set the app online/offline
   *
   * @memberof ConnectivityHandler
   */
  const setOnline = data => {
    ConnectivityActions.setOnline(dispatch, data);
    setPollingOnlineStatus(data);
  };

  /**
   * Function to set the app online
   *
   * @memberof ConnectivityHandler
   */
  const goOnline = () => {
    fillToaster(null); // cleanup offline Toast in case is not dismissed
    fillToaster(
      <Toast
        dismissable
        dismissIcon={CloseIcon}
        expireAfter={7}
        text={intl.formatMessage(messages.online)}
        type="success"
      />
    );
    setOnline(true);
  };

  /**
   * Function to set the app offline
   *
   * @memberof ConnectivityHandler
   */
  const goOffline = () => {
    fillToaster(null); // cleanup possible online Toast in case is not dismissed
    fillToaster(
      <Toast
        dismissable
        dismissIcon={CloseIcon}
        text={intl.formatMessage(messages.offline)}
        type="warning"
      />
    );
    setOnline(false);
  };

  /**
   * Redux function to show the prevent action on offline toast
   *
   * @memberof ConnectivityHandler
   */
  const setPreventActionOnOffline = data => {
    ConnectivityActions.setPreventActionOnOffline(dispatch, data);
  };

  /**
   * Executed when the component did mount and return a function to be executed on unmount
   *
   * @memberof ConnectivityHandler
   */
  useEffect(() => {
    window.addEventListener('online', () => {
      goOnline();
    });

    window.addEventListener('offline', () => {
      goOffline();
    });

    startPolling(goOnline, goOffline, history);

    return () => {
      window.removeEventListener('online', null);
      window.removeEventListener('offline', null);

      stopPolling();
    };
  }, []);

  /**
   * Executed when preventActionOnOffline is updated
   *
   * @memberof ConnectivityHandler
   */
  useEffect(() => {
    if (preventActionOnOffline) {
      fillToaster(
        <Toast
          dismissable
          dismissIcon={CloseIcon}
          text={intl.formatMessage(messages.offlineError)}
          type="danger"
        />
      );
      setPreventActionOnOffline(false);
    }
  }, [preventActionOnOffline]);

  return children;
};

/**
 * The ConnectivityHandler properties
 */
ConnectivityHandler.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ConnectivityHandler);
