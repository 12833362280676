/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets MMExternalUserLinkingResult from api:
 * /v1/ua/mm/medicaidIDSearch
 *
 * @param {string} medicaidID.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmMedicaidIDSearchUtil = (medicaidID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/medicaidIDSearch?medicaidID=${medicaidID}`,
    models.MMExternalUserLinkingResult
  );
};

/**
 * Gets MMExternalUserLinkingResult from api:
 * /v1/ua/mm/accountLink
 *
 * @param {string} sessionID.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmAccountLinkUtil = (sessionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/accountLink?sessionID=${sessionID}`,
    models.MMExternalUserLinkingResult
  );
};

/**
 * Gets MMIDPLogoutResponseDetails from api:
 * /v1/ua/mm/send_logout_request
 *
 * @param {string} sessionID.
 * @param {any} callback function to call once this function has finished
 */
const fetchMmSendLogoutRequestUtil = (sessionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/send_logout_request?sessionID=${sessionID}`,
    models.MMIDPLogoutResponseDetails
  );
};

export { fetchMmMedicaidIDSearchUtil, fetchMmAccountLinkUtil, fetchMmSendLogoutRequestUtil };
