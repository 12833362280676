/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * MMPROD-4362 : dhdkuma - Customized Application Footer from ootb.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { FooterSection, Footer, FooterLinkList, Link } from '@govhhs/govhhs-design-system-react';
// import { RouterLink } from '@spm/core-ui';
import PATHS from '@spm/universal-access-ui/src/router/Paths';
import LanguageDropdown from '@spm/universal-access-ui/lib/features/LanguageDropdown';
import { ID } from '../constants';
import ApplicationFooterMessages from './MMApplicationFooterMessages';
import MMPATHS from '../../../paths';
// import { ID } from '../constants';
/**
 * The ApplicationFooter component handles the rendering of the Application Footer
 * feature incorporating the {@link LanguageDropdown} component to manage the local selection.
 *
 * @param intl The API to format dates, numbers and strings.
 * @param location Locations represent where the app is now, where you want it to go
 * and where it was.
 *
 * @namespace ApplicationFooter
 * @memberof features.ApplicationFooter
 */
const ApplicationFooter = ({ intl, location }) => {
  const isApplicationOrSubmssionForm =
    new RegExp(`${PATHS.APPLY}/-?\\d+`).test(location.pathname) &&
    !/confirm/.test(location.pathname);

  if (
    isApplicationOrSubmssionForm ||
    [PATHS.IEGVERIFICATIONS.DETAILS].includes(location.pathname) ||
    [PATHS.IEGVERIFICATIONS.ROOT].includes(location.pathname) ||
    // START MMPROD-7976
    location.pathname.startsWith(MMPATHS.MM_HCR_ESIGNATURE)
    // END MMPROD-7976
  ) {
    return (
      <Footer type="small">
        <FooterSection
          ariaLabel={intl.formatMessage(ApplicationFooterMessages.footerNavigation) || 'Footer'}
          category="secondary"
          languageDropdown={<LanguageDropdown />}
          languageDropdownWidth="1/6"
        >
          <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6" />
        </FooterSection>
      </Footer>
    );
  }
  return (
    <Footer type="small">
      <FooterSection
        ariaLabel={intl.formatMessage(ApplicationFooterMessages.footerNavigation) || 'Footer'}
        category="secondary"
        languageDropdown={<LanguageDropdown />}
        languageDropdownWidth="1/6"
      >
        <FooterLinkList listItemsWidth="1/4" listSectionWidth="5/6">
          {/* eslint-disable no-restricted-syntax */}
          {/* START MMPROD-4362 : dhdkuma - Application Footer Links Customized */}
          <Link
            data-testid={ID.CONTACT_US_FOOTER_ID}
            target="_blank"
            to="https://www.scdhhs.gov/Contact-Info"
          >
            {intl.formatMessage(ApplicationFooterMessages.footerContactUs)}
          </Link>
          <Link
            data-testid={ID.CONTACT_US_FOOTER_ID}
            target="_blank"
            to="https://www.scdhhs.gov/FAQs"
          >
            {intl.formatMessage(ApplicationFooterMessages.footerFAQ)}
          </Link>
          <Link
            data-testid={ID.NOTICE_NON_DISCRIMINATION_FOOTER_ID}
            target="_blank"
            to="https://www.scdhhs.gov/site-page/notice-non-discrimination"
          >
            {intl.formatMessage(ApplicationFooterMessages.footerNoticeofNonDiscrimination)}
          </Link>
          <br />
          <Link
            data-testid={ID.NOTICE_PRIVACY_PRACTICES_FOOTER_ID}
            target="_blank"
            to="https://www.scdhhs.gov/internet/pdf/SCDHHSNoticeofPrivacyPractices080107.pdf"
          >
            {intl.formatMessage(ApplicationFooterMessages.footerNoticeofPrivacyPractices)}
          </Link>
          <Link
            data-testid={ID.PRIVACY_STATEMENT_FOOTER_ID}
            target="_blank"
            to="https://www.scdhhs.gov/sites/default/files/SCDHHS_PrivacyStatement.pdf"
          >
            {intl.formatMessage(ApplicationFooterMessages.footerPrivacyStatement)}
          </Link>
          {/* END MMPROD-4362 : dhdkuma - Application Footer Links Customized */}
        </FooterLinkList>
      </FooterSection>
    </Footer>
  );
};

ApplicationFooter.propTypes = {
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

/**
 * Controls the rendering of the Application Footer component incorporating the
 * {@link LanguageDropdown} component to manage the locale selection.
 * @namespace
 */
export default withRouter(injectIntl(ApplicationFooter));
