/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'MMNotices' store object.
 * @namespace MMNoticesSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAMMNotices';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static getMmCommunicationsByCommunicationId = state =>
    state[selectors.moduleIdentifier].getMmCommunicationsByCommunicationId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static isFetchingGetMmCommunicationsByCommunicationId = state =>
    state[selectors.moduleIdentifier].isFetchingGetMmCommunicationsByCommunicationId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static getMmCommunicationsByCommunicationIdError = state =>
    state[selectors.moduleIdentifier].getMmCommunicationsByCommunicationIdError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static getMmCommunicationsAttachmentsByAttachmentId = state =>
    state[selectors.moduleIdentifier].getMmCommunicationsAttachmentsByAttachmentId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static isFetchingGetMmCommunicationsAttachmentsByAttachmentId = state =>
    state[selectors.moduleIdentifier].isFetchingGetMmCommunicationsAttachmentsByAttachmentId
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMNoticesSelectors
   */
  static getMmCommunicationsAttachmentsByAttachmentIdError = state =>
    state[selectors.moduleIdentifier].getMmCommunicationsAttachmentsByAttachmentIdError.payload;
}
