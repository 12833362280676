import _Promise from '@babel/runtime-corejs3/core-js-stable/promise';
import _includesInstanceProperty from '@babel/runtime-corejs3/core-js-stable/instance/includes';
import _concatInstanceProperty from '@babel/runtime-corejs3/core-js-stable/instance/concat';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
// cSpell:ignore JAAS

/* eslint-disable import/no-unresolved */
import { RESTService } from '@spm/core';
import { CoreReduxStore } from '@spm/core';
import { sessionStorage } from '@spm/core';
import { EnvUtils } from '@spm/core';
import SAMLParser from './SAMLParser';
import AuthenticationActions from './actions';
import AuthenticationSelectors from './selectors';
import UserAccountModel from './UserAccountModel';
/* eslint-disable import/no-cycle */

import deprecate from './deprecate';
import AuthenticationRegistry from './AuthenticationRegistry';
import SSOAuthentication from './SSOAuthentication';
/**
 * Authentication related utilities.
 *
 * @name Authentication
 */

const Authentication = /* #__PURE__ */ _createClass(function Authentication() {
  _classCallCheck(this, Authentication);
});

_defineProperty(Authentication, 'getSAMLParser', response => new SAMLParser(response.text));

_defineProperty(Authentication, 'isSSOEnabled', () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_ENABLED')
);

_defineProperty(
  Authentication,
  'isDevAuthEnabled',
  () => EnvUtils.getEnvironmentProperty('REACT_APP_AUTH_METHOD') === 'DevAuthentication'
);

_defineProperty(Authentication, 'isSSOTypeSP', () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_SP_MODE')
);

_defineProperty(Authentication, 'getApiUrl', restEndpoint => {
  let _context;
  let _context2;

  return _concatInstanceProperty(
    (_context = _concatInstanceProperty((_context2 = '')).call(
      _context2,
      process.env.REACT_APP_REST_URL,
      '/'
    ))
  ).call(_context, restEndpoint);
});

_defineProperty(Authentication, 'getIDPSSOLogoutUrl', () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_USERLOGOUT_URL', '')
);

_defineProperty(Authentication, 'getACSUrl', () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_SP_ACS_URL', '')
);

_defineProperty(Authentication, 'getSPSSOUrl', () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_IDP_SSOLOGIN_URL', '')
);

_defineProperty(
  Authentication,
  'callbackAfterAuthRequest',
  deprecate(
    (callbackAfterInitialSuccess, callbackSSOVerifier) =>
      Authentication.UNSAFE_callbackAfterAuthRequest(
        callbackAfterInitialSuccess,
        callbackSSOVerifier
      ),
    'callbackAfterAuthRequest() is deprecated and will be removed. See JSDoc'
  )
);

_defineProperty(
  Authentication,
  'UNSAFE_callbackAfterAuthRequest',
  (callbackAfterInitialSuccess, callbackSSOVerifier) =>
    function (success, response) {
      if (success === true) {
        if (response != null) {
          const samlParser = Authentication.getSAMLParser(response); // Fetching the SAML response key from the SSO response.

          const samlResponseKey = samlParser.getSAMLResponseKey();
          const SAMLTokenVal = samlParser.getToken();

          if (SAMLTokenVal != null) {
            CoreReduxStore.internalStore.dispatch(AuthenticationActions.setSSOToken(SAMLTokenVal));
          } // For users who logged into SSO and not logged into SPM,
          // SAML response key is returned by the SSO which can before
          // used to authenticate to SPM

          if (samlResponseKey != null) {
            callbackAfterInitialSuccess(true, response);
          } else {
            // When users do not have the SAML response key a callback
            // is needed to inform SSOVerifier that user is not logged in.
            callbackSSOVerifier();
          }
        }
      } else {
        // Problem with SSO authentication
        callbackSSOVerifier();
      }
    }
);

_defineProperty(
  Authentication,
  'performSPInitSSOAuthRequest',
  deprecate(
    (httpResponse, callbackAfterInitialSuccess, callbackSSOVerifier) =>
      Authentication.UNSAFE_performSPInitSSOAuthRequest(
        httpResponse,
        callbackAfterInitialSuccess,
        callbackSSOVerifier
      ),
    'performSPInitSSOAuthRequest() is deprecated and will be removed. See JSDoc'
  )
);

_defineProperty(
  Authentication,
  'UNSAFE_performSPInitSSOAuthRequest',
  (httpResponse, callbackAfterInitialSuccess, callbackSSOVerifier) => {
    if (httpResponse !== null) {
      const samlParser = new SAMLParser(httpResponse.text); // Fetching the SAML request key from the AuthRequest of SP

      const samlRequest = samlParser.getSAMLRequestKey();
      const samlRelayState = samlParser.getRelayState();

      if (samlRequest === null) {
        // Problem with SP if the AuthReqest is not generated.
        // This needs to be pushed to exception handler properly.
        callbackSSOVerifier();
      } else {
        const initData = {
          RelayState: samlRelayState,
          SAMLRequest: samlRequest,
        };
        RESTService.post(
          Authentication.getSPSSOUrl(),
          initData,
          (success, response) => {
            Authentication.callbackAfterAuthRequest(
              callbackAfterInitialSuccess,
              callbackSSOVerifier
            )(success, response);
          },
          'form',
          true
        );
      }
    }
  }
);

_defineProperty(Authentication, 'login', function (username, password, callback) {
  const ssoPreCheck = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const ssoLogin =
    arguments.length > 4 && arguments[4] !== undefined
      ? arguments[4]
      : Authentication.isSSOEnabled();
  AuthenticationRegistry.authenticationType.login({
    username,
    password,
    callback,
    ssoPreCheck,
    ssoLogin,
  });
});

_defineProperty(Authentication, 'logout', function () {
  const callback =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  const reportLogoutError =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (callback && typeof callback !== 'function') {
    throw new Error('The callback argument must be of type function');
  }

  AuthenticationRegistry.authenticationType.logout(callback, reportLogoutError);
});

_defineProperty(Authentication, 'clearSessionStorage', () => {
  sessionStorage.removeItem('username');
  sessionStorage.removeItem('user_account');
});

_defineProperty(Authentication, 'clearCookiesFromStorage', () => {
  let _context3;
  let _context4;

  // cSpell:ignore JSESSIONID
  const jSessionIdCookieName = 'JSESSIONID';
  document.cookie = _concatInstanceProperty((_context3 = '')).call(
    _context3,
    jSessionIdCookieName,
    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  ); // cSpell:ignore Ltpa

  const lpaToken2CookieName = 'LtpaToken2';
  document.cookie = _concatInstanceProperty((_context4 = '')).call(
    _context4,
    lpaToken2CookieName,
    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  );
});

_defineProperty(Authentication, 'getLoggedInUser', function () {
  const state =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : CoreReduxStore.internalStore.getState();
  return AuthenticationSelectors.getLoggedInUser(state);
});

_defineProperty(
  Authentication,
  'getSSOToken',
  deprecate(
    state => Authentication.UNSAFE_getSSOToken(state),
    'getSSOToken() is deprecated and will be removed. See SSOAuthentication.getSSOToken instead'
  )
);

_defineProperty(Authentication, 'UNSAFE_getSSOToken', function () {
  const state =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : CoreReduxStore.internalStore.getState();
  return SSOAuthentication.getSSOToken(state);
});

_defineProperty(Authentication, 'getUserAccount', function () {
  const state =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : CoreReduxStore.internalStore.getState();
  const userAccount = AuthenticationSelectors.getUserAccount(state);
  return new UserAccountModel(userAccount);
});

_defineProperty(Authentication, 'userTypeIs', function (userTypes) {
  const state =
    arguments.length > 1 && arguments[1] !== undefined
      ? arguments[1]
      : CoreReduxStore.internalStore.getState();
  const userAccount = AuthenticationSelectors.getUserAccount(state);
  const model = new UserAccountModel(userAccount);
  return _includesInstanceProperty(userTypes).call(userTypes, model.userType);
});

_defineProperty(Authentication, 'loginAsPublicCitizen', callback => {
  Authentication.login('publiccitizen', 'password', callback, false, false);
});

_defineProperty(Authentication, 'loginWithGeneratedUserAccount', (username, password, callback) => {
  Authentication.login(username, password, callback, false, false);
});

_defineProperty(
  Authentication,
  'postCall',
  (URL, data, type, ssoAuth, headers) =>
    new _Promise(resolve => {
      RESTService.post(
        URL,
        data,
        (success, response) => {
          resolve({
            success,
            response,
          });
        },
        type,
        ssoAuth,
        headers
      );
    })
);

_defineProperty(
  Authentication,
  'getCall',
  (URL, params, ssoAuth, headers) =>
    new _Promise(resolve => {
      RESTService.get(
        URL,
        (success, response) => {
          resolve({
            success,
            response,
          });
        },
        params,
        ssoAuth,
        headers
      );
    })
);

_defineProperty(Authentication, 'USER_TYPES', {
  NO_USER_TYPE: null,
  PUBLIC: 'PUBLIC',
  GENERATED: 'GENERATED',
  STANDARD: 'STANDARD',
  LINKED: 'LINKED',
});

export { Authentication as default };
