/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { AppSpinner, Routes as UARoutes, TitledRoute } from '@spm/universal-access-ui';
import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthenticatedRoute } from '@spm/core-ui';
import featureToggles from '@spm/universal-access-ui/src/features/featureToggles';
import routesMessages from '@spm/universal-access-ui/src/router/RoutesMessages';
import Authentication from './modules/Authentication/Authentication';
import applicationHeaderTranslations from './features/MMApplicationHeader/components/ApplicationHeaderMessages';
import signupTranslations from './features/MMSignup/components/MMSignupComponentMessages';
import loginTranslations from './features/MMLogin/components/MMLoginComponentMessages';
import paths from './paths';
import routeTitles from './routesMessages';
import applyForBenfitsTranslations from './features/MMApplyForBenefits/components/MMApplyForBenefitsComponentMessages';

/*  This routes.jsx file can be customized to add new components or
    Redirect the existing components to new routes:

   Sample:
     <Switch>
       <Route path="/ourPayments" component={OurPayments} />
       <Redirect from="/payments" to="/ourPayments" />
       <UARoutes />
     </Switch>
*/

/**
 * Default loading component to display while loading other components.
 */
const loadingComponent = () => <AppSpinner />;

/* webpackChunkName: "SampleApplication" */
const SampleApplication = lazy(() =>
  import('./features/sampleApplication/SampleApplicationComponent')
);

/* webpackChunkName: "SampleApplicationForm" */
const SampleApplicationForm = lazy(() =>
  import('./features/sampleApplication/form/SampleApplicationFormComponent')
);

/* webpackChunkName: "SampleApplicationOverview" */
const SampleApplicationOverview = lazy(() =>
  import('./features/sampleApplication/overview/SampleApplicationOverviewComponent')
);

/* webpackChunkName: "SampleApplicationConfirmation" */
const SampleApplicationConfirmation = lazy(() =>
  import('./features/sampleApplication/confirmation/SampleApplicationConfirmation')
);

// START dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits Screen
const ApplyForBenefits = lazy(() => import('./features/MMApplyForBenefits'));
// END dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits Screen

// START DHDRAMA MMPROD-3328
// START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
const MMYourApplications = lazy(() => import('./features/MMYourApplications'));
// END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
const MMNoticeDetails = lazy(() => import('./features/MMNoticeDetails'));
// END DHDRAMA MMPROD-3328

// MMRP POC START For CE Server Configuration
const MMApplicationOverview = lazy(() => import('./features/MMApplicationOverview'));

// MMPROD-4104 START
const MMHCRESignatureForm = lazy(() =>
  import('./features/MMForms/MMHCRESignatureForm/MMHCRESignatureForm')
);
// MMPROD-4104 END

const MMHCRApplicationConfirmation = lazy(() =>
  import('./features/MMForms/MMHCRApplicationConfirmation/MMHCRApplicationConfirmation')
);

const MMHCRApplicationFailed = lazy(() =>
  import('./features/MMForms/MMHCRApplicationConfirmation/MMHCRApplicationFailed')
);
// MMPROD-5970 BEGIN, HCR to CGIS transition page
const MMHCRApplicationInterimConfirmation = lazy(() =>
  import('./features/MMForms/MMHCRApplicationConfirmation/MMHCRApplicationInterimConfirmation')
);
// MMPROD-5970 END,
const MMAccountHome = lazy(() => import('./features/MMAccountHome'));
// MMRP POC END

// START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error
const MMVerifications = lazy(() => import('./features/MMVerifications'));
const MMVerificationDetail = lazy(() => import('./features/MMVerificationDetail'));
// END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error

// START DHAKELL MMPROD-3168 - customization for CE Non-MAGI app initialization
// START DHAKELL MMPROD-5702 - merging magi/non-magi components
const MMApplicationForm = lazy(() => import('./features/MMForms/MMApplicationForm'));
// END DHAKELL MMPROD-5702
// END DHAKELL MMPROD-3168

// START DHRCHAD MMPROD-3183 - COC Submission Screen
/* webpackChunkName: "LifeEventsConfirmation" */
const LifeEventsConfirmation = lazy(() => import('./features/MMLifeEventsConfirmation'));
// END DHRCHAD MMPROD-3183 - COC Submission Screen

// START MMPROD-3360 dhakell - customizing password special character requirement
/* webpackChunkName: "Signup" */
const Signup = lazy(() => import('./features/MMSignup'));
// END MMPROD-3360 dhakell
// START MMPROD-11020 dhrkhai Non-MAGI E-Signature page changes
const MMCGISESignatureForm = lazy(() =>
  import('./features/MMForms/MMCGISESignatureForm/MMCGISESignatureForm')
);
// END MMPROD-11020

/* START MMPROD-4485 dhakell - Non-MAGI submission page */
/* webpackChunkName: "SubmissionFormConfirmation" */
const SubmissionFormConfirmation = lazy(() =>
  import('./features/MMForms/MMCGISSubmissionForm/MMSubmissionFormConfirmationComponent')
);
/* END MMPROD-4485 dhakell - Non-MAGI submission page */

// START MMPROD-4106: dhdkuma - Customize component Organization for Body changes
/* webpackChunkName: "Organization" */
const Organization = lazy(() => import('./features/MMOrganisation'));
// END MMPROD-4106: dhdkuma

/* START MMPROD-4910 dhakell - Login page updates */
/* webpackChunkName: "Login" */
const Login = lazy(() => import('./features/MMLogin'));
/* END MMPROD-4910 dhakell - Login page updates */

// START MMPROD-5653: dhdkuma - Customize component Appeal Request
/* webpackChunkName: "AppealRequests" */
const AppealRequests = lazy(() => import('./features/MMAppealRequests'));
// END MMPROD-5653: dhdkuma

/* START DHRYOUN MMPROD-4990 */
const MMResetPassword = lazy(() => import('./features/MMResetPassword'));
/* END DHRYOUN MMPROD-4990 */

/* Start MMPROD-5361: dhtkond - notices component */
/* webpackChunkName: "Notices" */
const Notices = lazy(() => import('./features/MMNotices'));
/* End MMPROD-5361: dhtkond - notices component */

// START DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC cards
/* webpackChunkName: "Profile" */
// START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
const YourInformation = lazy(() => import('./features/MMYourInformation'));
// END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
// END DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards

// START DHRCHAD MMPROD-5833 - COC Overview and Form
/* webpackChunkName: "LifeEventsOverview" */
const MMLifeEventsOverview = lazy(() => import('./features/MMLifeEventsOverview'));
/* webpackChunkName: "LifeEvents" */
const MMLifeEventsForm = lazy(() => import('./features/MMForms/MMLifeEvents'));
// END DHRCHAD MMPROD-5833 - COC Overview and Form
// START DHRCHAD MMPROD-8463
/* webpackChunkName: "SubmissionFormResolve" */
const MMSubmissionFormResolve = lazy(() =>
  import('./features/MMForms/MMSubmissionForm/MMSubmissionFormResolveComponent')
);
/* webpackChunkName: "SubmissionForm" */
const MMSubmissionForm = lazy(() => import('./features/MMForms/MMSubmissionForm'));
// END DHRCHAD MMPROD-8463

// START MMPROD-10376 dhdkuma - Save and Exit Changes
const MMExitWarning = lazy(() => import('./features/MMForms/MMExitWarning/MMExitWarningComponent'));

const MMLogOutUser = lazy(() => import('./features/MMLogoutAllUser/MMLogOutUserComponent'));
// END MMPROD-10376 dhdkuma

// START : MMPROD-10541 : dhrchad
const MMOnlineRenewalForm = lazy(() => import('./features/MMForms/MMOnlineRenewalForm'));
// END : MMPROD-10541 : dhrchad

// START MMPROD-10550
const MMOnlineAnnualReview = lazy(() => import('./features/MMOnlineAnnualReview'));
// END MMPROD-10550

// START MMPROD-10553: ACR ESignature
const MMACRESignatureForm = lazy(() =>
  import('./features/MMForms/MMACREsignatureForm/MMACRESignatureForm')
);
// END MMPROD-10553

/* START MMPROD-10555 dhrchad - Online Renewal application launch page */
/* webpackChunkName: "MMOnlineRenewalApplicationlaunch" */
const MMOnlineRenewalApplicationlaunch = lazy(() =>
  import('./features/MMForms/MMOnlineRenewalForm/MMOnlineRenewalLaunchApplicationComponent')
);
/* END MMPROD-10555 dhrchad - Online Renewal application launch page */

/* START MMPROD-10554 dhrchad - Online Renewal application already Received page */
/* webpackChunkName: "MMOnlineRenewalReceived" */
const MMMOnlineRenewalReviewFormAlreadyReceived = lazy(() =>
  import('./features/MMForms/MMOnlineRenewalForm/MMMOnlineRenewalReviewFormAlreadyReceived')
);
/* END MMPROD-10554 dhrchad - Online Renewal application already Received page */

// START DHRCHAD MMPROD-10551 - Online Renewal ACR Submission Confirmation
/* webpackChunkName: "MMAnnualRenewalACRConfirmation" */
const MMAnnualRenewalACRConfirmation = lazy(() =>
  import(
    './features/MMForms/MMOnlineRenewalSubmissionConfirmation/MMOnlineRenewalConfirmationComponent'
  )
);
// END DHRCHAD MMPROD-10551 - Online Renewal ACR Submission Confirmation

const MMSignupOrLoginComponent = lazy(() => import('./features/MMSignupOrLogin'));

const MMMOnlineRenewalBeforeWeStartComponent = lazy(() =>
  import('./features/MMForms/MMOnlineRenewalForm/MMMOnlineRenewalBeforeWeStartComponent')
);

const MMAccountLinkContactUsInformationChangedComponent = lazy(() =>
  import('./features/MMAccountLink/components/MMAccountLinkContactUsInformationChangedComponent')
);

const MMAccountLinkContactUsMultipleMatchComponent = lazy(() =>
  import('./features/MMAccountLink/components/MMAccountLinkContactUsMultipleMatchComponent')
);

const MMAccountLinkMedicaidIDSuccessComponent = lazy(() =>
  import('./features/MMAccountLink/components/MMAccountLinkMedicaidIDSuccessComponent')
);

const MMAccountLinkMedicaidIDSearchComponent = lazy(() => import('./features/MMMedicaidIDSearch'));

export default (
  <Suspense fallback={loadingComponent}>
    <Switch>
      {/* Sample Routes - Example features. Not intended to run in production. */}
      <TitledRoute
        component={Organization}
        exact
        path={paths.HOME}
        title={applicationHeaderTranslations.headerLandingPageTitle}
      />
      <TitledRoute
        component={SampleApplicationForm}
        exact
        path={`${paths.SAMPLE_APPLICATION.FORM}/:formId`}
        title={routeTitles.sampleApplicationForm}
      />
      <TitledRoute
        component={SampleApplicationConfirmation}
        exact
        path={`${paths.SAMPLE_APPLICATION.CONFIRMATION}/:formId`}
        title={routeTitles.sampleApplicationConfirmation}
      />
      <TitledRoute
        component={SampleApplicationOverview}
        exact
        path={paths.SAMPLE_APPLICATION.OVERVIEW}
        title={routeTitles.sampleApplicationOverview}
      />
      <TitledRoute
        component={SampleApplication}
        exact
        path={paths.SAMPLE_APPLICATION.ROOT}
        title={routeTitles.sampleApplication}
      />
      {/*
       START dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits
      Screen
     */}
      <TitledRoute
        component={ApplyForBenefits}
        exact
        path={paths.APPLY}
        title={applyForBenfitsTranslations.applyForBenefitsPageTitle}
      />
      {/*
       END dhsumat - MMPROD-4107 - V8 upgrade CE- Disable OOTB programs on the Apply for Benefits
      Screen
     */}
      {/*
         MMRP POC START For CE Server Configuration
      */}
      <TitledRoute
        component={MMApplicationOverview}
        exact
        path={paths.MM_APPLICATION_OVERVIEW}
        title={routeTitles.overviewTitle}
      />
      <TitledRoute
        component={MMApplicationOverview}
        exact
        path={`${paths.MM_APPLICATION_OVERVIEW}/:intakeApplicationTypeId/:programIds`}
        title={routeTitles.overviewTitle}
      />
      {/* MMPROD-5970 BEGIN, added HCR form ID to prepopulate Non-MAGI application */}
      <TitledRoute
        component={MMApplicationOverview}
        exact
        path={`${paths.MM_APPLICATION_OVERVIEW}/:intakeApplicationTypeId/:programIds/:hcrFormId`}
        title={routeTitles.overviewTitle}
      />
      {/* MMPROD-5970 END */}
      {/* MMPROD-4104 START */}
      <TitledRoute
        component={MMHCRESignatureForm}
        exact
        isIEG
        path={`${paths.MM_HCR_ESIGNATURE}/:applicationFormId`}
        title={routeTitles.applicationForm}
      />
      {/* MMPROD-4104 END */}
      <TitledRoute
        component={MMHCRApplicationConfirmation}
        exact
        path={`${paths.MM_HCR_CONFIRMATION}/:formId`}
        title={routeTitles.hcrApplicationConfirmation}
      />
      <TitledRoute
        component={MMHCRApplicationFailed}
        exact
        path={`${paths.MM_HCR_CONFIRMATION_FAILED}/:formId`}
        title={routeTitles.hcrApplicationConfirmation}
      />
      {/* MMPROD-5970 BEGIN, added HCR to CGIS transition confirmation page */}
      <TitledRoute
        component={MMHCRApplicationInterimConfirmation}
        exact
        path={`${paths.MM_HCR_TRANS_CONFIRMATION}/:formRef/:applicationFormId`}
        title={routeTitles.hcrApplicationConfirmation}
      />
      {/* MMPROD-5970 END */}
      {/* // MMRP POC END UA Routes */}
      {/* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */}
      {featureToggles.verifications.isEnabled() && (
        <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.LINKED]}
          component={MMVerifications}
          exact
          path={paths.MM_VERIFICATIONS.ROOT}
          title={applicationHeaderTranslations.headerYourVerificationsText}
        />
      )}
      {featureToggles.verifications.isEnabled() && (
        <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.LINKED]}
          component={MMVerificationDetail}
          exact
          path={paths.MM_VERIFICATIONS.DETAILS}
          title={routeTitles.verificationDetails}
        />
      )}
      {/* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */}
      {/* START dhakell MMPROD-2975 - Fix continue option for MAGI app */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAccountHome}
        exact
        path={paths.ACCOUNT}
        title={routeTitles.headerDashboardLabel}
      />
      {/* END dhakell MMPROD-2975 */}
      {/* START dhakell MMPROD-3168 - customization for CE Non-MAGI app initialization */}
      <TitledRoute
        // START DHAKELL MMPROD-5702 - merging magi/non-magi components
        component={MMApplicationForm}
        // END DHAKELL MMPROD-5702
        exact
        isIEG
        path={`${paths.APPLY}/new/:applicationTypeId`}
        title={routeTitles.applicationForm}
      />
      {/* MMPROD-5970 BEGIN, added HCR form ID to prepopulate Non-MAGI application */}
      <TitledRoute
        component={MMApplicationForm}
        exact
        isIEG
        path={`${paths.APPLY}/newcgis/:applicationTypeId/:hcrFormId`}
        title={routeTitles.applicationForm}
      />
      {/* MMPROD-5970 END, */}
      <TitledRoute
        // START DHAKELL MMPROD-5702 - merging magi/non-magi components
        component={MMApplicationForm}
        // END DHAKELL MMPROD-5702
        exact
        isIEG
        path={`${paths.APPLY}/:applicationFormId`}
        title={routeTitles.applicationForm}
      />
      {/* END dhakell MMPROD-3168 */}
      {/* START DHDRAMA MMPROD-3328 */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        component={MMYourApplications}
        exact
        path={`${paths.MM_APPLICATIONS}`}
        // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        title={applicationHeaderTranslations.headerYourBenefitsLabel}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMNoticeDetails}
        exact
        path={`${paths.MM_NOTICES.DETAILS}`}
        title={routeTitles.noticeDetails}
      />
      {/* END DHDRAMA MMPROD-3328 */}
      {/* START DHRCHAD MMPROD-3183 */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.LINKED]}
        component={LifeEventsConfirmation}
        exact
        path={`${paths.LIFE_EVENTS.ROOT}/:formId/success`}
        title={routeTitles.lifeEventsConfirmation}
      />
      {/* END DHRCHAD MMPROD-3183 */}
      {/* START MMPROD-3360 dhakell - customizing password special character requirement */}
      <TitledRoute component={Signup} exact path={paths.SIGNUP} title={signupTranslations.title} />
      {/* END MMPROD-3360 dhakell */}
      {/* START dhrkhai MMPROD-11020 Non-MAGI E-Signature page changes */}
      <TitledRoute
        component={MMCGISESignatureForm}
        exact
        isIEG
        path={`${paths.MM_CGIS_ESIGNATURE}/:applicationFormId`}
        title={routeTitles.applicationForm}
      />
      {/* END MMPROD-11020 */}
      {/* START MMPROD-4485 dhakell - Non-MAGI submission page */}
      <TitledRoute
        component={SubmissionFormConfirmation}
        exact
        path={`${paths.APPLY}/:applicationFormId/confirm`}
        title={routesMessages.submissionFormConfirmation}
      />
      {/* END MMPROD-4485 dhakell - Non-MAGI submission page */}
      {/* START MMPROD-4910 dhakell - Login page updates */}
      <TitledRoute component={Login} exact path={paths.LOGIN} title={loginTranslations.title} />
      {/* END MMPROD-4910 dhakell - Login page updates */}
      {/* START MMPROD-10376 dhdkuma - Save and Exit Changes */}
      <TitledRoute
        component={MMExitWarning}
        exact
        path={`${paths.MM_EXITWARNING}/:formId`}
        title={routeTitles.exitWarning}
      />
      <TitledRoute
        component={MMLogOutUser}
        exact
        path={`${paths.MM_LOGOUT_ALL_USERS}`}
        title={routeTitles.logoutAllUsers}
      />
      {/* END MMPROD-10376 dhdkuma */}
      {/* START MMPROD-5653: dhdkuma - Appeal Request */}
      {featureToggles.appeals.isEnabled() && (
        <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={AppealRequests}
          exact
          path={paths.APPEALS.ROOT}
          title={applicationHeaderTranslations.headerAppealsLabel}
        />
      )}
      {/* END MMPROD-5653: dhdkuma */}
      {/* START DHRYOUN MMPROD-4990 */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMResetPassword}
        exact
        path={paths.MM_RESETPASSWORD}
        title={routeTitles.headerResetPasswordLabel}
      />
      {/* END DHRYOUN MMPROD-4990 */}
      {/* Start MMPROD-5361: dhtkond - notices component */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={Notices}
        exact
        path={paths.MM_NOTICES.ROOT}
        title={applicationHeaderTranslations.headerNoticesLabel}
      />
      {/* End MMPROD-5361: dhtkond - notices component */}
      {/* START DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        component={YourInformation}
        exact
        path={paths.YOUR_INFORMATION}
        // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        title={applicationHeaderTranslations.headerProfileText}
      />
      {/* END DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards */}
      {/* START DHRCHAD MMPROD-5833 - COC Overview and Form */}
      {featureToggles.lifeEvents.isEnabled() && (
        <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.LINKED]}
          component={MMLifeEventsOverview}
          path={`${paths.LIFE_EVENTS.OVERVIEW}/:contextId`}
          title={routeTitles.lifeEventsOverviewLabel}
        />
      )}
      {featureToggles.lifeEvents.isEnabled() && (
        <AuthenticatedRoute
          // START MMPROD-12379 DHRCHAD
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          // END MMPROD-12379 DHRCHAD
          component={MMLifeEventsForm}
          isIEG
          path={`${paths.LIFE_EVENTS.FORM}/:lifeEventContextId`}
          title={routeTitles.lifeEventsFormContainerLabel}
        />
      )}
      {/* END DHRCHAD MMPROD-5833 - COC Overview and Form */}
      {/* START MMPROD-10553: ACR esignature screen */}
      {featureToggles.lifeEvents.isEnabled() && (
        <AuthenticatedRoute
          // START MMPROD-12379 DHRCHAD
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          // END MMPROD-12379 DHRCHAD
          component={MMLifeEventsForm}
          exact
          isIEG
          path={`${paths.LIFE_EVENTS.RESUME}/:formId`}
          title={routeTitles.lifeEventsFormContainerResumeLabel}
        />
      )}
      {/* END MMPROD-10553: ACR esignature screen */}
      {/* START DHRCHAD MMPROD-8463 */}
      <TitledRoute
        component={MMSubmissionFormResolve}
        exact
        path={`${paths.APPLY}/:applicationFormId/success`}
        title={routeTitles.submissionFormResolve}
      />
      <TitledRoute
        component={MMSubmissionForm}
        exact
        isIEG
        path={`${paths.APPLY}/:submissionFormId/submit`}
        title={routeTitles.submissionForm}
      />
      <TitledRoute
        component={MMSubmissionForm}
        exact
        isIEG
        path={`${paths.APPLY}/new/:applicationFormId/submit`}
        title={routeTitles.submissionForm}
      />
      {/* END DHRCHAD MMPROD-8463 */}
      {/* DHTKOND MMPROD-5653 Start- redirecting unused OOTB Appeals paths to APPEALS Home page */}
      <Redirect path={paths.APPEALS.OVERVIEW} to={paths.APPEALS.ROOT} />
      <Redirect path={paths.APPEALS.FORM} to={paths.APPEALS.ROOT} />
      {/* DHTKOND MMPROD-5653 End- redirecting unused OOTB Appeals paths to APPEALS Home page */}
      {/* START : MMPROD-10541 : dhrchad */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMOnlineRenewalForm}
        exact
        isIEG
        path={`${paths.RENEWAL.ROOT}/onlineRenewal`}
        title={routeTitles.onlineRenewalForm}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMOnlineRenewalForm}
        exact
        isIEG
        path={`${paths.RENEWAL.ROOT}/onlineRenewal/:applicationFormId`}
        title={routeTitles.onlineRenewalForm}
      />
      {/* END : MMPROD-10541 : dhrchad */}
      {/* START : MMPROD-10550 */}
      <TitledRoute
        component={MMOnlineAnnualReview}
        exact
        isIEG
        path={`${paths.RENEWAL.SELECT_FORM}`}
        title={routeTitles.onlineRenewalForm}
      />
      {/* END : MMPROD-10550 */}
      {/* START MMPROD-10553: ACR Esignature page */}
      <TitledRoute
        component={MMACRESignatureForm}
        exact
        isIEG
        path={`${paths.LIFE_EVENTS.ACR_ESIGNATURE}/:applicationFormId`}
        title={routeTitles.onlineRenewalForm}
      />
      {/* END MMPROD-10553 */}
      {/* START MMPROD-10555 dhrchad - Online Renewal application launch page */}
      <TitledRoute
        component={MMOnlineRenewalApplicationlaunch}
        exact
        path={`${paths.RENEWAL.ROOT}/launch`}
        title={routeTitles.onlineRenewalForm}
      />
      {/* END MMPROD-10555 dhrchad - Online Renewal application launch page */}
      {/* START MMPROD-10554 dhrchad - Online Renewal application Received page */}
      <TitledRoute
        component={MMMOnlineRenewalReviewFormAlreadyReceived}
        exact
        path={`${paths.RENEWAL.ROOT}/Received`}
        title={routeTitles.onlineRenewalForm}
      />
      {/* END MMPROD-10554 dhrchad - Online Renewal application Received page */}
      {/* START DHRCHAD MMPROD-10551 */}
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAnnualRenewalACRConfirmation}
        exact
        path={`${paths.RENEWAL.ROOT}/success/:formId`}
        title={routeTitles.lifeEventsConfirmation}
      />
      {/* END DHRCHAD MMPROD-10551 */}
      <TitledRoute
        component={MMSignupOrLoginComponent}
        exact
        path={paths.SIGNUPORLOGIN}
        title={loginTranslations.title}
      />
      <TitledRoute
        component={MMMOnlineRenewalBeforeWeStartComponent}
        exact
        path={`${paths.RENEWAL.ROOT}/beforeWeStart`}
        title={routeTitles.onlineRenewalForm}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAccountLinkContactUsInformationChangedComponent}
        exact
        path={`${paths.ACCOUNTLINK.INFORMATIONCHANGED}`}
        title={routeTitles.accountLink}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAccountLinkContactUsMultipleMatchComponent}
        exact
        path={`${paths.ACCOUNTLINK.MULTIPLEMATCH}`}
        title={routeTitles.accountLink}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAccountLinkMedicaidIDSuccessComponent}
        exact
        path={`${paths.ACCOUNTLINK.SUCCESS}`}
        title={routeTitles.accountLink}
      />
      <AuthenticatedRoute
        authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
        component={MMAccountLinkMedicaidIDSearchComponent}
        exact
        path={`${paths.ACCOUNTLINK.MEDICAIDIDSEARCH}`}
        title={routeTitles.accountLink}
      />
      <UARoutes />
    </Switch>
  </Suspense>
);
