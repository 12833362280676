import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/* eslint-disable-next-line import/no-cycle */
import SSOAuthentication from './SSOAuthentication';
/** Component that will be used in order to login using SSO SP Authentication */

const SSOSPAuthentication = /* #__PURE__ */ _createClass(function SSOSPAuthentication() {
  _classCallCheck(this, SSOSPAuthentication);
});

_defineProperty(SSOSPAuthentication, 'login', input => {
  SSOAuthentication.login(input, 'SP');
});

_defineProperty(SSOSPAuthentication, 'logout', function () {
  const callback =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  const reportLogoutError =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  SSOAuthentication.logout(callback, reportLogoutError);
});

export { SSOSPAuthentication as default };
