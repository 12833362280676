/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { AuthenticationTypes } from '@spm/core';
import Authentication from '../../modules/Authentication/Authentication';
import SSOAuthentication from './MMSSOAuthentication';
import displayLogStatements from '../MMCommon/components/common/MMICAMConstants';

/** Component that will be used in order to login using SSO Authentication */
export default class MMWLSSOAuthentication {
  static login = async input => {
    if (Authentication.isDevAuthEnabled()) {
      if (displayLogStatements) {
        console.warn('DevAuth enabled!!!!');
      }
      return AuthenticationTypes.DevAuthentication.login(input);
    }
    return SSOAuthentication.login(input, 'IDP');
  };

  static logout = async (callback = () => {}, reportLogoutError = true) => {
    /* eslint-disable no-unused-expressions */
    Authentication.isDevAuthEnabled()
      ? await AuthenticationTypes.DevAuthentication.logout()
      : await SSOAuthentication.logout(callback, reportLogoutError);

    if (callback) {
      callback();
    }
  };
}
