import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import parse from 'date-fns/parse';
/**
 * User Account Model.
 */

const UserAccountModel = /* #__PURE__ */ _createClass(
  /**
   * Creates an instance of UserAccountModel.
   *
   * @param {any} json input json
   * @memberof UserAccountModel
   */
  function UserAccountModel(json) {
    _classCallCheck(this, UserAccountModel);

    if (json) {
      this.lastLoggedInDateTime =
        json.lastLoggedInDateTime !== null ? parse(json.lastLoggedInDateTime) : null;
      this.userType = json.userType;
    } else {
      this.lastLoggedInDateTime = null;
      this.userType = null;
    }
  }
);

export { UserAccountModel as default };
