/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import {
  RESTService,
  EnvUtils,
  AuthenticationTypes,
  CoreReduxStore,
  UserActions,
  SSOVerifierActions,
} from '@spm/core';

import Authentication from '../../modules/Authentication/Authentication';
import AuthenticationActions from '../../modules/Authentication/actions';
import AuthenticationSelectors from '../../modules/Authentication/selectors';
import MMProfileActions from '../../modules/generated/MMProfile/actions';
import displayLogStatements from '../MMCommon/components/common/MMICAMConstants';
/**
 * The IDP SAML Logininitial URL
 *
 * @memberof SSOAuthentication
 *
 * @private
 */
const getIdpSAMLLogininitialUrl = () =>
  EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_IDP_LOGININITIAL_URL');

/**
 * The IDP UserLogin Url
 *
 * @memberof SSOAuthentication
 *
 * @private
 */
const getIdpUserLoginUrl = () => EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_USERLOGIN_URL');

/**
 * Utility class that exposes SSO-specific functions used for SSO prechecks and SSO authentication processes
 */
export default class MMSSOAuthentication {
  /**
   * Logs the user in, using the username and password provided. This is a post request to the
   * server to authenticate the user. Also makes a call to get the user type. This is a get request
   * to the server.
   *
   * Once both requests have finished the callback function will be called.
   *
   * @param {Object} input contains critical information for the login process (username, password, callback, ssoPrecheck)
   * @param {string} ssoType the type of SSO to be used for login
   *
   * @static
   * @private
   */
  static login = async (input, ssoType) => {
    // Call SSO authentication if ssoLogin flag is set to true.

    if (displayLogStatements) {
      console.log('MMSSOAuthentication --> login ');
    }
    if (input.ssoLogin) {
      await this.ssoLogin(input, ssoType);
      if (displayLogStatements) {
        console.log('MMSSOAuthentication --> logging as SSOLogin ');
      }
    } else {
      await AuthenticationTypes.JAASAuthentication.login(input);
    }
  };

  /**
   * Logs the user in by authenticating the user with the IdP and retrieving their user account.
   *
   * This login process is an explicit login action by the user in Universal Access. If a user arrives at Universal Access unauthenticated with the IdP, they can navigate as public or generated user. When the user intends to login, they will use the Universal Access login feature. In Single-Sign-On, the login needs to communicate with the IdP to verify and authenticate the user before retrieving the user account.
   *
   * The user can be potentially authenticated with the IdP prior to arriving at Universal Access. The process to verify the user's authentication and retrieve the user account is known as a SSO precheck and is handled by the SSOVerifier React component separately. It differs from this login as it needs to handle differences between between SP-init and IdP-init as well as application server responses.
   *
   * @param {Object} input the input object
   * @param {string} input.username the user's name
   * @param {string} input.password the user's pasword
   * @param {function} input.callback the callback function will be passed a boolean indicating success and an object representing the response.
   *
   * @static
   * @memberof SSOAuthentication
   *
   * @see SSOVerifier
   */
  static ssoLogin = async input => {
    if (displayLogStatements) {
      console.log('MMSSOAuthentication --> ssoLogin ');
    }
    // SSOVerifier has already logged the user in if they were pre-authenticated.
    // If we are executing this flow, we are not logged-in to the IdP.
    const { username, password, callback: loginCallback } = input;
    const idpLoginResponse = await this.callIdPUserLogin({
      username,
      password,
    });

    let isSSOLoginSuccessful = false;

    if (idpLoginResponse.success && !!idpLoginResponse.response) {
      const idpSamlResponseData = this.parseSAMLResponsePayload(idpLoginResponse.response);

      if (idpSamlResponseData.samlResponseKey) {
        const { samlResponseKey, samlRelayState } = idpSamlResponseData;
        const acsURLResponse = await this.callACSWithSAMLResponse({
          samlResponseKey,
          samlRelayState,
        });

        if (acsURLResponse.success) {
          const getUserAccountResponse = await this.getUserAccount();
          await this.loadUserAccount(getUserAccountResponse.response, username);
          isSSOLoginSuccessful = true;
          loginCallback(true, getUserAccountResponse.response);
        }
      }

      if (!isSSOLoginSuccessful) {
        loginCallback(false);
      }
    } else {
      this.clearCookiesAndSessionStorage();
      loginCallback(false);
    }
  };

  /**
   * Logs the current user out.
   * This kills the server session associated with the current window, as well
   * as clearing the sessionStorage for the current window.
   *
   * @param {function} callback a function that will be invoked on completion.
   * The callback function will be passed a boolean indicating success and an
   * object representing the response.
   *
   * @static
   * @memberof SSOAuthentication
   */
  static logout = async (callback = () => {}, reportLogoutError = true) => {
    if (
      !Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      await AuthenticationTypes.JAASAuthentication.logout();
    } else {
      // Invalidate session with IDP
      const logoutUrl = await Authentication.getIDPSSOLogoutUrl();
      const getIDPSSOLogoutUrlResponse = await Authentication.getCall(logoutUrl);
      if (getIDPSSOLogoutUrlResponse.success) {
        // Call JAAS Logout to invalidate Weblogic Session
        await AuthenticationTypes.JAASAuthentication.logout();
        // trigger the sso verifier
        SSOVerifierActions.setSSOVerifier(CoreReduxStore.internalStore.dispatch, true);
      } else if (!getIDPSSOLogoutUrlResponse.success && reportLogoutError) {
        throw new Error(getIDPSSOLogoutUrlResponse.response);
      }
    }
    if (callback) {
      callback();
    }
  };

  /**
   * Calls a protected resource (e.g. /Rest) and returns the call response.
   *
   * This is used as part of the SP-Init flow where an initial call is required to a protected resource which will be intercepted by the Assertion Consumer Service/Trust Association Intercepter. When functioning properly, the ACS/TAI will intercept the request and respond with a SAML Request payload. This SAML payload can be used to communicate with the IdP.
   *
   * Different application servers may respond with different HTTP response codes. For example, Websphere responds with a HTTP 401 where as Weblogic responds with a HTTP 200.
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @returns {Promise} protected resource call response object
   *
   * @static
   * @memberof SSOAuthentication
   */
  static callProtectedResource = () => {
    // Using RESTService.get as-is in SSOAuth mode, different results happen. Regardless of the success, you would receive the response object (plainly) back. In errors that are specific to websphere 401's, the caller receives the response object plainly because the response contains a SAMLRequest token which needs to be parsed (the hand-off between the response and the parser is called by SSOVerifier) to get the SAML Request token. In non-SSO mode, a subset of the response is returned, like the error text if there was an error or the response.body if it was a success (providing the body isn't null in which case it attaches the response to a response property (e.g. responseData.response))
    // We call just the Rest endpoint (/Rest) because other more specific endpoints will be unauthorized when CSRF protection is enabled which blocks the user when pre-authenticated with an IdP in the SP-init flow.
    /* eslint-disable no-new */
    new Promise(resolve => {
      RESTService.get(
        process.env.REACT_APP_REST_URL,
        (success, response) => {
          resolve({ success, response });
        },
        {},
        true
      );
    });
  };

  /**
   * Calls the IDP Login Initial URL (defined by the environment property REACT_APP_SAMLSSO_IDP_LOGININITIAL_URL). This is used in IdP-Init flows as part of the initial precheck of the user for their authentication status against the IdP.
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @returns {Promise} IdP login initial call response object
   *
   * @static
   * @memberof SSOAuthentication
   */
  static callIdPLoginInitial = () => {
    Authentication.getCall(getIdpSAMLLogininitialUrl(), {}, true);
  };

  /**
   * Parses a SAML Request payload for the SAML Request Key and Relay State.
   *
   * This is used as part of the SP-init flow to parse the SAML request payload returned by the intercepted protected resource call.
   *
   * @param {Object} response response object
   * @returns {Object} saml request data (request key and relay state)
   */
  static parseSAMLRequestPayload = response => {
    const idpSamlRequestData = { samlRequestKey: null, samlRelayState: null };
    if (response) {
      const samlParser = Authentication.getSAMLParser(response);
      // these return null if not present on the response
      idpSamlRequestData.samlRequestKey = samlParser.getSAMLRequestKey();
      idpSamlRequestData.samlRelayState = samlParser.getRelayState();
    }
    return idpSamlRequestData;
  };

  /**
   * Clears any Universal Access cookies and session stored data.
   *
   * For example, username and user_account
   */
  static clearCookiesAndSessionStorage = () => {
    Authentication.clearSessionStorage();
    Authentication.clearCookiesFromStorage();
  };

  /**
   * Calls the IdP SAML Login URL (defined by the environment property REACT_APP_SAMLSSO_IDP_SSOLOGIN_URL) with a SAML Request Key and Relay State.
   *
   * This is used in the SP-init flow to verify the authentication status of the user. The contents of the response object returned indicates if the user is already logged-in with the IdP (e.g. HTML containing a login form versus a SAML message)
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @param {Object} samlRequest Object containing the SAML request key and relay state
   * @param {string} samlRequest.samlRequestKey SAML request key
   * @param {string} samlRequest.samlRelayState SAML relay state
   * @returns {Promise} IdP SAML login call response object
   */
  static callIdpSAMLLoginWithSAMLRequest = ({ samlRequestKey, samlRelayState }) => {
    const idpSamlRequestData = {
      RelayState: samlRelayState,
      SAMLRequest: samlRequestKey,
    };

    // Similar to GET, POST has an ssoAuth mode to influence the invokeCallbackAfterResponse callback to return the entire response without edit. This needs to be abstracted out
    return new Promise(resolve => {
      RESTService.post(
        Authentication.getSPSSOUrl(),
        idpSamlRequestData,
        (success, response) => {
          resolve({ success, response });
        },
        'form',
        true
      );
    });
  };

  /**
   * Sets the SAML unique token into the Redux store.
   *
   * Certain IdP providers set a hidden SAML token in HTML responses which is required on requests for authentication if defined.
   *
   * @param {string} samlToken unique SAML token set by the IdP
   */
  static setSSOToken = samlToken => {
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setSSOToken(samlToken));
  };

  /**
   * Returns the SSO token.
   *
   * @param {*} state the state can be passed in, or else defaults to
   * the global store using the CoreReduxStore handle if the calling function does not
   * have a handle on state.
   *
   * @returns {string} the SSO token.
   *
   * @static
   * @memberof Authentication
   */
  static getSSOToken = (state = CoreReduxStore.internalStore.getState()) =>
    AuthenticationSelectors.getSSOToken(state);

  /**
   * Parses a SAML Response payload for the SAML Response Key and Relay State
   *
   * @param {Object} response response object
   * @returns {Object} SAML response data (with response key and relay state)
   */
  static parseSAMLResponsePayload = response => {
    const idpSamlResponseData = { samlResponseKey: null, samlRelayState: null };
    // The response from the IdP will be HTML but will be two different versions depending on whether the user is already logged into the IdP.
    // If the user is logged in, the request will return a response with a HTML form containing the RelayState and SAMLResponse key in input fields.
    // If the user is not logged in, they are redirected from the IDP_SSOLOGIN_URL to another URL endpoint (e.g. /isam/sps/auth for ISAM) which returns a HTML form containing a login page (similar to ISAM pkmslogin.form). This form can contain a hidden input containing an SSO token. We store this value in Redux because if it's required we will need to provide it at login later.
    if (response) {
      const samlParser = Authentication.getSAMLParser(response);
      // these return null if not present on the response
      idpSamlResponseData.samlResponseKey = samlParser.getSAMLResponseKey();
      idpSamlResponseData.samlRelayState = samlParser.getRelayState();

      const samlTokenValue = samlParser.getToken();
      if (samlTokenValue != null) {
        this.setSSOToken(samlTokenValue);
      }
    }
    return idpSamlResponseData;
  };

  /**
   * Calls the Assertion Consumer Service URL (defined by environment property REACT_APP_SAMLSSO_SP_ACS_URL) with SAML Response data (response key and relay state)
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @param {Object} samlResponse Object containing the SAML response key and relay state
   * @param {string} samlResponse.samlResponseKey SAML response key
   * @param {string} samlResponse.samlRelayState SAML relay state
   * @returns {Promise} ACS call response object
   */
  static callACSWithSAMLResponse = ({ samlResponseKey, samlRelayState }) => {
    const acsData = {
      SAMLResponse: samlResponseKey,
    };
    if (samlRelayState) {
      acsData.RelayState = samlRelayState;
    }
    return Authentication.postCall(Authentication.getACSUrl(), acsData, 'form', true);
  };

  /**
   * Retrieves the user account details from the SPM server
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @returns {Promise} User account call response object
   */
  static getUserAccount = () => {
    Authentication.getCall(Authentication.getApiUrl('v1/ua/user_account_login'), {});
  };

  /**
   * Returns true if JAAS Authentication is enabled
   *
   * @returns {Promise} Logout call response object
   */
  /* eslint-disable arrow-body-style */
  static isJAASAuthEnabled = () => {
    return process.env.REACT_APP_AUTH_METHOD === 'JAASAuthentication';
  };

  /**
   * Loads the user account.
   *
   * Using the response from retrieving the user's account from SPM, this function will load the user account.
   * This can involve storing user data into both redux and session storage as well as fetching user and profile information if the user type is standard or linked.
   *
   * @param {Object} userAccountData response object from getUserAccount
   * @param {string} username username
   * @see SSOAuthentication.getUserAccount
   */
  static loadUserAccount = (userAccountData, username) => {
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('user_account', JSON.stringify(userAccountData));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setLoggedInUser(username));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setUserAccount(userAccountData));
    if (
      Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      MMProfileActions.fetchMmProfile(CoreReduxStore.internalStore.dispatch);
      UserActions.fetchUser(CoreReduxStore.internalStore.dispatch);
    }
  };

  /**
   * Calls the IdP User login URL (defined by environment property REACT_APP_SAMLSSO_USERLOGIN_URL) with a user's login credentials.
   *
   * If a unique hidden SSO token was captured in earlier SSO precheck calls (managed by the SSOVerifier) and stored into Redux, then this token will be included in the call.
   *
   * The Response object returned is Promise-based which will require an async/await caller.
   *
   * @param {Object} loginCredentials Credentials to use on the IdP user login form
   * @param {string} loginCredentials.username
   * @param {string} loginCredentials.password
   * @returns {Promise} IdP user login call response object
   * @see SSOVerifier
   */
  static callIdPUserLogin = ({ username, password }) => {
    const loginData = {
      username,
      password,
    };

    const ssoToken = this.getSSOToken();
    if (ssoToken != null) {
      loginData.token = ssoToken;
    }

    loginData['login-form-type'] = 'pwd';
    return Authentication.postCall(getIdpUserLoginUrl(), loginData, 'form', true);
  };

  /**
   * Removes the Authenticated user from the Redux store
   */
  static removeAuthenticatedUser = () => {
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.logout());
  };
}
