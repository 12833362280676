import _Object$assign from '@babel/runtime-corejs3/core-js-stable/object/assign';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/* eslint-disable import/no-cycle */
import { EnvUtils } from '@spm/core';
import AuthenticationTypes from './AuthenticationTypes';

const registry = {};
/**
 * Utility class exposing Authentication Registry utilities.
 *
 * @namespace AuthenticationRegistry
 */

const AuthenticationRegistry = /* #__PURE__ */ (function () {
  function AuthenticationRegistry() {
    _classCallCheck(this, AuthenticationRegistry);
  }

  _createClass(AuthenticationRegistry, null, [
    {
      key: 'registerAuthenticationType',
      value:
        /**
         * Register the Authentication functions.
         *
         * @param {Object} authenticationType Object that contains the named functions.
         * @memberof AuthenticationRegistry
         */
        function registerAuthenticationType(authenticationType) {
          registry.authenticationType = authenticationType;
        },
      /**
       * Gets the authenticationType
       * @memberof AuthenticationRegistry
       * @returns  The authenticationType
       */
    },
    {
      key: 'authenticationType',
      get: function get() {
        if (!registry.authenticationType) {
          const warnMessage =
            'No Authentication method defined. Fallback to JAASAuthentication in place. For more information, see the Authentication section in Knowledge Center.';
          let authMethodType = AuthenticationTypes.JAASAuthentication;

          if (EnvUtils.getEnvironmentProperty('REACT_APP_AUTH_METHOD') !== false) {
            switch (EnvUtils.getEnvironmentProperty('REACT_APP_AUTH_METHOD')) {
              case 'DevAuthentication':
                authMethodType = AuthenticationTypes.DevAuthentication;
                break;

              case 'JAASAuthentication':
                authMethodType = AuthenticationTypes.JAASAuthentication;
                break;

              case 'SSOSPAuthentication':
                authMethodType = AuthenticationTypes.SSOSPAuthentication;
                break;

              case 'SSOIDPAuthentication':
                authMethodType = AuthenticationTypes.SSOIDPAuthentication;
                break;

              default:
                /* eslint-disable no-console */
                console.warn(warnMessage);
                break;
            }
          } else {
            /* eslint-disable no-console */
            console.warn(warnMessage);
          }

          registry.authenticationType = authMethodType;
        }

        return _Object$assign({}, registry.authenticationType);
      },
    },
  ]);

  return AuthenticationRegistry;
})();

export { AuthenticationRegistry as default };
