import _toConsumableArray from '@babel/runtime-corejs3/helpers/esm/toConsumableArray';
import _forEachInstanceProperty from '@babel/runtime-corejs3/core-js-stable/instance/for-each';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Class that provides functions to extract SAML Request, SAML Response and
 * Relay State URL from the SSO SAML response HTML.
 */
import DOMPurify from 'dompurify';
/**
 * SAML Response related utilities.
 *
 * @class SAMLParser
 */

const SAMLParser = /* #__PURE__ */ _createClass(
  /**
   * Container for the SAMLResponse value.
   *
   * @memberof SAMLParser
   * @private
   */

  /**
   * Container for the SAMLRequest value.
   *
   * @memberof SAMLParser
   * @private
   */

  /**
   * Container for the RelayState value.
   *
   * @memberof SAMLParser
   * @private
   */

  /**
   * Container for the token value.
   *
   * @memberof SAMLParser
   * @private
   */

  /**
   * Constructor applies the SAX parser on the given HTML to extract the
   * SAML Response and Relay state values.
   *
   * @memberof SAMLParser
   */
  function SAMLParser(html) {
    const _this = this;
    let _context;

    _classCallCheck(this, SAMLParser);

    _defineProperty(this, 'samlRespVal', null);

    _defineProperty(this, 'samlReqVal', null);

    _defineProperty(this, 'relStateVal', null);

    _defineProperty(this, 'tokenVal', null);

    _defineProperty(this, 'getSAMLResponseKey', () => _this.samlRespVal);

    _defineProperty(this, 'getSAMLRequestKey', () => _this.samlReqVal);

    _defineProperty(this, 'getRelayState', () => _this.relStateVal);

    _defineProperty(this, 'getToken', () => _this.tokenVal);

    const samlReq = 'SAMLRequest';
    const samlResp = 'SAMLResponse';
    const relState = 'RelayState';
    const token = 'token';
    const fieldType = 'input'; // Sanitize the incoming response

    const clean = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: [fieldType],
    });
    const doc = new DOMParser().parseFromString(clean, 'text/html');

    _forEachInstanceProperty(
      (_context = _toConsumableArray(doc.getElementsByTagName(fieldType)))
    ).call(_context, element => {
      const attributeName = element.getAttribute('name');
      const attributeValue = element.getAttribute('value');

      if (attributeName === relState && _this.relStateVal === null) {
        _this.relStateVal = attributeValue;
      } else if (attributeName === samlResp && _this.samlRespVal === null) {
        _this.samlRespVal = attributeValue;
      } else if (attributeName === samlReq && _this.samlReqVal === null) {
        _this.samlReqVal = attributeValue;
      } else if (attributeName === token && _this.tokenVal === null) {
        _this.tokenVal = attributeValue;
      }
    });
  }
  /**
   * Function returns the SAML response value from the SSO SAML HTML.
   * Returns null if the required value is not populated due the reasons
   * such as invalid input HTML or the required field is absent.
   *
   * @memberof SAMLParser
   */
);

export { SAMLParser as default };
