/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 * Routes Messages
 *
 * This contains all the text Routes to be displayed on the window's title.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  sampleApplication: {
    id: 'sampleApplication',
    defaultMessage: 'Sample of Apply for Benefits',
  },
  sampleApplicationOverview: {
    id: 'sampleApplicationOverview',
    defaultMessage: 'Sample of Apply for Benefits Overview',
  },
  sampleApplicationForm: {
    id: 'sampleApplicationForm',
    defaultMessage: 'Sample of Apply for Benefits Form',
  },
  sampleApplicationConfirmation: {
    id: 'sampleApplicationConfirmation',
    defaultMessage: 'Sample of Apply for Benefits Confirmation',
  },
  // MMRP POC START For CE Server Configuration
  overviewTitle: {
    id: 'ApplicationOverview_LabelOverviewTitle',
    defaultMessage: 'Overview',
  },
  applicationForm: {
    id: 'Routes_applicationForm',
    defaultMessage: 'Application form',
  },
  hcrApplicationConfirmation: {
    id: 'hcrApplicationConfirmation',
    defaultMessage: 'Healthcare Application Benefits Confirmation',
  },
  headerYourBenefitsLabel: {
    id: 'Header_YourBenefitsLabel',
    defaultMessage: 'Your benefits',
  },
  headerDashboardLabel: {
    id: 'Header_DashboardLabel',
    defaultMessage: 'Dashboard',
  },
  // START DHDRAMA MMPROD-3328 Notice download alert
  noticeDetails: {
    id: 'Routes_noticeDetails',
    defaultMessage: 'Notice Details',
  },
  // END DHDRAMA MMPROD-3328 Notice download alert
  // MMRP POC END For CE Server Configuration
  // START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error
  verificationDetails: {
    id: 'Routes_verificationDetails',
    defaultMessage: 'Verification Details',
  },
  // END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error
  // START DHRCHAD MMPROD-3183 - COC Submission Screen
  lifeEventsConfirmation: {
    id: 'Routes_lifeEventsConfirmation',
    defaultMessage: 'Change submitted',
  },
  // END DHRCHAD MMPROD-3183 - COC Submission Screen
  // BEGIN DHRYOUN MMPROD-4990 - Section 3.16 Reset Password Changes
  headerResetPasswordLabel: {
    id: 'Header_ResetPasswordLabel',
    defaultMessage: 'Reset Password',
  },
  // END DHRYOUN MMPROD-4990 - Section 3.16 Reset Password Changes
  // START DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards
  headerProfileText: {
    id: 'Header_ProfileText',
    defaultMessage: 'Profile',
  },
  // END DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards
  // START DHRCHAD MMPROD-5833 - COC Overview and form
  lifeEventsOverviewLabel: {
    id: 'Routes_lifeEventsOverviewLabel',
    defaultMessage: 'Life Events Overview',
  },
  lifeEventsFormContainerLabel: {
    id: 'Routes_lifeEventsFormContainerLabel',
    defaultMessage: 'Life Events',
  },
  // END DHRCHAD MMPROD-5833 - COC Overview and form
  // START MMPROD-10553: ACR esignature screen
  lifeEventsFormContainerResumeLabel: {
    id: 'Routes_lifeEventsFormContainerResumeLabel',
    defaultMessage: 'Life Events',
  },
  // END MMPROD-10553: ACR esignature screen
  // START DHRCHAD MMPROD-8463
  submissionFormResolve: {
    id: 'Routes_submissionFormResolve',
    defaultMessage: 'Submission form resolve',
  },
  submissionForm: {
    id: 'Routes_submissionForm',
    defaultMessage: 'Submission form',
  },
  // END DHRCHAD MMPROD-8463
  // START MMPROD-10376 dhdkuma - Save and Exit Changes
  exitWarning: {
    id: 'Routes_exitWarning',
    defaultMessage: 'Save and Exit',
  },

  logoutAllUsers: {
    id: 'Routes_Logout_All_Users',
    defaultMessage: 'Logout all Users',
  },
  // END MMPROD-10376 dhdkuma
  // START : MMPROD-10541 : dhrchad
  onlineRenewalForm: {
    id: 'Routes_onlineRenewalForm',
    defaultMessage: 'Online Renewal form',
  },
  // END : MMPROD-10541 : dhrchad
  accountLink: {
    id: 'Routes_accountLink',
    defaultMessage: 'Account Link',
  },
});
