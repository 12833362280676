import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Contains the selectors that select state from the 'UAAuthentication' store object.
 * @namespace AuthenticationSelectors
 */
const selectors = /* #__PURE__ */ _createClass(function selectors() {
  _classCallCheck(this, selectors);
});

_defineProperty(selectors, 'moduleIdentifier', 'UAAuthentication');

_defineProperty(
  selectors,
  'getLoggedInUser',
  state => state[selectors.moduleIdentifier].loggedInUser
);

_defineProperty(
  selectors,
  'getUserAccount',
  state => state[selectors.moduleIdentifier].userAccount
);

_defineProperty(selectors, 'getSSOToken', state => state[selectors.moduleIdentifier].ssoToken);

export { selectors as default };
