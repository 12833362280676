/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const ID = {
  FAQ_FOOTER_ID: 'footer-faq',
  CONTACT_US_FOOTER_ID: 'footer-contactUs',
  NOTICE_NON_DISCRIMINATION_FOOTER_ID: 'footer-notice-non-discrimination',
  NOTICE_PRIVACY_PRACTICES_FOOTER_ID: 'footer-notice-privacy-practices',
  PRIVACY_STATEMENT_FOOTER_ID: 'footer-privacy-statement',
  SECURITY_FOOTER_ID: 'footer-security',
  PRIVACY_FOOTER_ID: 'footer-privacy',
  ACCESSIBILITY_FOOTER_ID: 'footer-accessibility',
  DISCLAIMER_FOOTER_ID: 'footer-disclaimer',
  LANGUAGE_SELECTOR_FOOTER_ID: 'footer-language-selector',
};

/* eslint-disable import/prefer-default-export */
export { ID };
