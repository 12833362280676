/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/* eslint-disable import/no-cycle */
import JAASAuthentication from './JAASAuthentication';
import DevAuthentication from './DevAuthentication';
import SSOSPAuthentication from './SSOSPAuthentication';
import SSOIDPAuthentication from './SSOIDPAuthentication';

export default {
  JAASAuthentication,
  DevAuthentication,
  SSOSPAuthentication,
  SSOIDPAuthentication,
};
