/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2023. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { EnvUtils } from '@spm/core';

const displayLogStatements = !!EnvUtils.getEnvironmentProperty('REACT_APP_DISPLAY_LOG_STATEMENTS');

export default displayLogStatements;
