import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import actionTypes from './actionTypes';
/**
 * Contains the action creator functions that update the 'UAAuthentication'
 * slice of the store. An instance of {@link UserAccountModel} will be stored
 * here.
 * @namespace AuthenticationActions
 */

const actions = /* #__PURE__ */ (function () {
  function actions() {
    _classCallCheck(this, actions);
  }

  _createClass(actions, null, [
    {
      key: 'setLoggedInUser',
      value:
        /**
         * Set authenticated user
         *
         * @param {*} user
         *
         * @function
         * @memberof AuthenticationActions
         */
        function setLoggedInUser(user) {
          return {
            type: actionTypes.SET_LOGGED_IN_USER,
            loggedInUser: user,
          };
        },
      /**
       * Set SSO token
       *
       * @param {*} token
       *
       * @function
       * @memberof AuthenticationActions
       */
    },
    {
      key: 'setSSOToken',
      value: function setSSOToken(token) {
        return {
          type: actionTypes.SET_SSO_TOKEN,
          ssoToken: token,
        };
      },
      /**
       * Set user type
       *
       * @function
       * @memberof AuthenticationActions
       */
    },
    {
      key: 'setUserAccount',
      value: function setUserAccount(userAccount) {
        return {
          type: actionTypes.SET_USER_ACCOUNT,
          userAccount,
        };
      },
      /**
       * Remove authenticated user
       *
       * @function
       * @memberof AuthenticationActions
       */
    },
    {
      key: 'logout',
      value: function logout() {
        return {
          type: actionTypes.LOGOUT,
        };
      },
    },
  ]);

  return actions;
})();

export { actions as default };
