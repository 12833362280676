/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_MM_MEDICAID_ID_SEARCH,
  FETCH_MM_ACCOUNT_LINK,
  FETCH_MM_SEND_LOGOUT_REQUEST,
} from './actionTypes';

/**
 * Updates the details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmMedicaidIDSearchReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_MEDICAID_ID_SEARCH);

/**
 * Updates the indicator of creation of details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmMedicaidIDSearchReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_MEDICAID_ID_SEARCH);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmMedicaidIDSearchErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_MEDICAID_ID_SEARCH);

/**
 * Updates the details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmAccountLinkReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_ACCOUNT_LINK);

/**
 * Updates the indicator of creation of details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmAccountLinkReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_ACCOUNT_LINK);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmAccountLinkErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_ACCOUNT_LINK);

/**
 * Updates the details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmSendLogoutRequestReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_SEND_LOGOUT_REQUEST);

/**
 * Updates the indicator of creation of details in the 'MMICAM' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmSendLogoutRequestReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_SEND_LOGOUT_REQUEST);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmSendLogoutRequestErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_SEND_LOGOUT_REQUEST);

const reducers = combineReducers({
  fetchMmMedicaidIDSearch: fetchMmMedicaidIDSearchReducer,

  isFetchingFetchMmMedicaidIDSearch: isFetchingFetchMmMedicaidIDSearchReducer,
  fetchMmMedicaidIDSearchError: fetchMmMedicaidIDSearchErrorReducer,

  fetchMmAccountLink: fetchMmAccountLinkReducer,

  isFetchingFetchMmAccountLink: isFetchingFetchMmAccountLinkReducer,
  fetchMmAccountLinkError: fetchMmAccountLinkErrorReducer,

  fetchMmSendLogoutRequest: fetchMmSendLogoutRequestReducer,

  isFetchingFetchMmSendLogoutRequest: isFetchingFetchMmSendLogoutRequestReducer,
  fetchMmSendLogoutRequestError: fetchMmSendLogoutRequestErrorReducer,
});

export default { reducers };
