/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMLifeEventCategories.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_MM_LIFE_EVENT_CATEGORIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMLIFEEVENTCATEGORIES/FETCH_MM_LIFE_EVENT_CATEGORIES`
);

export { FETCH_MM_LIFE_EVENT_CATEGORIES };
