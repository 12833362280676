/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from '@govhhs/govhhs-design-system-react';
import { ScrollToTop, ErrorBoundary, Toaster, CookiesHandler } from '@spm/universal-access-ui';
import { AuthenticationRegistry } from '@spm/core';
import DOMPurify from 'dompurify';
import SSOVerifier from './features/SSOVerifier/SSOVerifier';
import MMApplicationFooter from './features/MMApplicationFooter';
import MMApplicationHeader from './features/MMApplicationHeader';
import routes from './routes';
import ReduxInit from './redux/ReduxInit';
import IntlInit from './intl/IntlInit';
import CompatibilityInit from './compatibility/CompatibilityInit';
import MMWLSSOAuthentication from './features/MMIDPLogin/MMWLSSOAuthentication';
import MMIdleTimer from './features/SessionTimeout/MMIdleTimer';
import ConnectivityHandler from './features/Connectivity/ConnectivityHandler';

window.uaapp = {
  envVars: process.env,
};

/* START MMPROD-4447: dhrchad - New package to open external links on IEG in new tab */
DOMPurify.setConfig({ ADD_ATTR: ['target'] });
/* END MMPROD-4447: dhrchad */

AuthenticationRegistry.registerAuthenticationType(MMWLSSOAuthentication);

/**
 * App component entry point.
 */
const App = () => (
  <ReduxInit>
    <IntlInit>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <CompatibilityInit>
            <ErrorBoundary
              /* START MMPROD-4362: dhdkuma - Application Footer Customization changes */
              footer={<MMApplicationFooter />}
              /* END MMPROD-4362: dhdkuma */
              /* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom documents link */
              header={<MMApplicationHeader hasErrorBeenCaught />}
              /* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */
              isHeaderBoundary
            >
              <CookiesHandler
                /* START MMPROD-4362: dhdkuma - Application Footer Customization changes */
                footer={<MMApplicationFooter />}
                /* END MMPROD-4362: dhdkuma */
                header={
                  /* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom documents link */
                  <MMApplicationHeader
                    /* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */
                    isALinkedUser={() => false}
                    isAppealsEnabled={false}
                    isEmpty={false}
                  />
                }
              >
                <ConnectivityHandler>
                  <SSOVerifier
                    placeholder={
                      <>
                        {/* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom documents link */}
                        <MMApplicationHeader
                          /* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */
                          isALinkedUser={() => false}
                          isAppealsEnabled={false}
                          isEmpty={false}
                        />
                        <Main
                          className="wds-u-bg--page wds-u-text-word-break"
                          id="maincontent"
                          pushFooter
                        >
                          {routes}
                        </Main>
                      </>
                    }
                  >
                    {/* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom documents link */}
                    <MMApplicationHeader />
                    {/* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */}
                    <ErrorBoundary>
                      <MMIdleTimer />
                      <Toaster />
                      <Main
                        className="wds-u-bg--page wds-u-text-word-break"
                        id="maincontent"
                        pushFooter
                      >
                        {routes}
                      </Main>
                    </ErrorBoundary>
                    {/* START MMPROD-4362: dhdkuma - Application Footer Customization changes */}
                    <MMApplicationFooter />
                    {/* END MMPROD-4362: dhdkuma */}
                  </SSOVerifier>
                </ConnectivityHandler>
              </CookiesHandler>
            </ErrorBoundary>
          </CompatibilityInit>
        </ScrollToTop>
      </Router>
    </IntlInit>
  </ReduxInit>
);

export default App;
