/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  offline: {
    id: 'ConnectivityHandler_offline',
    defaultMessage:
      "You're disconnected. Check your internet connection or wait while we try to reconnect.",
  },
  offlineError: {
    id: 'ConnectivityHandler_offline_error',
    defaultMessage: "You're still disconnected. Check your internet connection or try again later.",
  },
  online: {
    id: 'ConnectivityHandler_online',
    defaultMessage: 'You are now connected.',
  },
});
