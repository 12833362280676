/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_MOTIVATION_TYPE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MOTIVATION_TYPE`
);

const GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID`
);

const POST_MOTIVATION_SETUP_AND_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MOTIVATION_SETUP_AND_START`
);

const POST_MOTIVATION_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MOTIVATION_START`
);

const POST_MOTIVATION_SETUP = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MOTIVATION_SETUP`
);

const FETCH_MOTIVATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MOTIVATIONS`
);

const POST_MOTIVATION_RESUME = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MOTIVATION_RESUME`
);

const GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID`
);

const POST_MM_MOTIVATION_CHANGE_OWNERSHIP = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_MOTIVATION_CHANGE_OWNERSHIP`
);

const CREATE_APPLICATION_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/CREATE_APPLICATION_FORM`
);

const POST_HCR_MOTIVATION_SIGNANDSUBMIT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_HCR_MOTIVATION_SIGNANDSUBMIT`
);

const DELETE_MM_MOTIVATION_BY_MOTIVATION_ID = ReduxUtils.generateActionTypes(
  'ua-library',
  `MMMODULE/DELETE_MM_MOTIVATION_BY_MOTIVATION_ID`,
  'DELETE'
);

const POST_MM_APPLICATION_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_APPLICATION_FORM`
);

const FETCH_MM_VERIFICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MM_VERIFICATIONS`
);

const POST_DBS_MM_FILES = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_DBS_MM_FILES`
);

const POST_MM_RESETPASSWORD = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_RESETPASSWORD`
);

const POST_MM_HCR_TO_CGIS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_HCR_TO_CGIS`
);

const POST_MM_LAUNCH_INTAKE_MOTIVATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_LAUNCH_INTAKE_MOTIVATION`
);

const POST_MM_APPLYING_FOR_NON_MAGI = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_APPLYING_FOR_NON_MAGI`
);

const POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK`
);

const POST_MM_IS_MOTIVATION_SCRIPT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_IS_MOTIVATION_SCRIPT`
);

const FETCH_MM_DRAFT_APPLICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MM_DRAFT_APPLICATIONS`
);

const FETCH_MM_SYSTEM_CONFIGURATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MM_SYSTEM_CONFIGURATIONS`
);

const FETCH_MM_VALIDATE_SESSION = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MM_VALIDATE_SESSION`
);

const POST_MM_VERIFICATIONS_LINK_FILE = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_VERIFICATIONS_LINK_FILE`
);

const GET_MM_VERIFICATIONS_BY_VERIFICATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/GET_MM_VERIFICATIONS_BY_VERIFICATION_ID`
);

const FETCH_MM_SUBMITTED_APPLICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/FETCH_MM_SUBMITTED_APPLICATIONS`
);

const POST_MM_APPLICATIONS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMMODULE/POST_MM_APPLICATIONS`
);

const GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMMODULE/GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID`
);

const SET_SELECTED_APPLICATION_TYPE = ReduxUtils.generateActionType(
  'ua-library',
  `MMMODULE/SET_SELECTED_APPLICATION_TYPE`,
  'SET'
);

export {
  FETCH_MOTIVATION_TYPE,
  GET_MOTIVATION_TYPE_IEG_BY_IEG_EXECUTION_ID,
  POST_MOTIVATION_SETUP_AND_START,
  POST_MOTIVATION_START,
  POST_MOTIVATION_SETUP,
  FETCH_MOTIVATIONS,
  POST_MOTIVATION_RESUME,
  GET_MOTIVATION_IEG_BY_IEG_EXECUTION_ID,
  POST_MM_MOTIVATION_CHANGE_OWNERSHIP,
  CREATE_APPLICATION_FORM,
  POST_HCR_MOTIVATION_SIGNANDSUBMIT,
  DELETE_MM_MOTIVATION_BY_MOTIVATION_ID,
  POST_MM_APPLICATION_FORM,
  FETCH_MM_VERIFICATIONS,
  POST_DBS_MM_FILES,
  POST_MM_RESETPASSWORD,
  POST_MM_HCR_TO_CGIS,
  POST_MM_LAUNCH_INTAKE_MOTIVATION,
  POST_MM_APPLYING_FOR_NON_MAGI,
  POST_MM_VERIFICATIONS_CREATE_CASEWORKER_TASK,
  POST_MM_IS_MOTIVATION_SCRIPT,
  FETCH_MM_DRAFT_APPLICATIONS,
  FETCH_MM_SYSTEM_CONFIGURATIONS,
  FETCH_MM_VALIDATE_SESSION,
  POST_MM_VERIFICATIONS_LINK_FILE,
  GET_MM_VERIFICATIONS_BY_VERIFICATION_ID,
  FETCH_MM_SUBMITTED_APPLICATIONS,
  POST_MM_APPLICATIONS,
  GET_MM_FORM_DETAILS_BY_APPLICATION_FORM_ID,
  SET_SELECTED_APPLICATION_TYPE,
};
