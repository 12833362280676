/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  skipNav: {
    id: 'skipNav_skipNav',
    defaultMessage: 'Skip to main content',
  },
  headerHomeLabel: {
    id: 'Header_HomeLabel',
    defaultMessage: 'Home',
  },
  headerMenuLabel: {
    id: 'Header_MenuLabel',
    defaultMessage: 'Menu',
  },
  headerBenefitsLabel: {
    id: 'Header_BenefitsLabel',
    defaultMessage: 'Benefits',
  },
  headerDashboardLabel: {
    id: 'Header_DashboardLabel',
    defaultMessage: 'Dashboard',
  },
  headerYourBenefitsLabel: {
    id: 'Header_YourBenefitsLabel',
    defaultMessage: 'Your applications/Annual reviews',
  },
  headerAppealsLabel: {
    id: 'Header_AppealsLabel',
    defaultMessage: 'Appeals',
  },
  headerNoticesLabel: {
    id: 'Header_NoticesLabel',
    defaultMessage: 'Notices',
  },
  headerHelpLabel: {
    id: 'Header_HelpLabel',
    defaultMessage: 'Find help',
  },
  headerYourAccountLabel: {
    id: 'Header_YourAccountLabel',
    defaultMessage: 'Your account',
  },
  headerFAQLabel: {
    id: 'Header_FAQLabel',
    defaultMessage: 'FAQs',
  },
  headerContactUsLabel: {
    id: 'Header_ContactUsLabel',
    defaultMessage: 'Contact us',
  },
  headerLandingPageTitle: {
    id: 'Header_LandingPageTitle',
    defaultMessage: 'Healthy Connections Medicaid',
  },
  headerLogInText: {
    id: 'Header_LogInText',
    defaultMessage: 'Log in',
  },
  headerSignUpText: {
    id: 'Header_SignUpText',
    defaultMessage: 'Sign up',
  },
  headerLogOutText: {
    id: 'Header_LogOutText',
    defaultMessage: 'Log out',
  },
  headerProfileText: {
    id: 'Header_ProfileText',
    defaultMessage: 'Your information',
  },
  headerYourProfileText: {
    id: 'Header_YourProfileText',
    defaultMessage: 'Your profile',
  },
  headerMenuInfoText: {
    id: 'Header_MenuInfoText',
    defaultMessage: 'Selecting {menuOptionText} will open a new page',
  },
  headerSkipNavigationText: {
    id: 'headerSkipNavigationText',
    defaultMessage: 'Skip Navigation',
  },
  headerYourVerificationsText: {
    id: 'Header_YourVerificationsText',
    defaultMessage: 'Your documents',
  },
  headerResetPasswordText: {
    id: 'Header_ResetPasswordText',
    defaultMessage: 'Reset password',
  },
});
