/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the UAMotivationType
 *
 * @class UAMotivationType
 */
class UAMotivationType {
  /**
   * Creates an instance of UAMotivationType.
   * @param {*} json
   * @memberof UAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the UAListMotivationTypes
 *
 * @class UAListMotivationTypes
 */
class UAListMotivationTypes {
  /**
   * Creates an instance of UAListMotivationTypes.
   * @param {*} json
   * @memberof UAListMotivationTypes
   */
  constructor(json = null) {
    if (json) {
      // array - UAMotivationType
      if (json.data) {
        this.data = json.data.map(object => new UAMotivationType(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UAMotivationType(object));
      }
    }
  }
}

/**
 * Models the NewUAMotivationType
 *
 * @class NewUAMotivationType
 */
class NewUAMotivationType {
  /**
   * Creates an instance of NewUAMotivationType.
   * @param {*} json
   * @memberof NewUAMotivationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // string
      this.motivationTypeId = json.motivationTypeId;

      // boolean
      this.requiresLogin = json.requiresLogin;

      // integer
      this.displayOrder = json.displayOrder;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKeyResponse
 *
 * @class NewUAMotivationIEGKeyResponse
 */
class NewUAMotivationIEGKeyResponse {
  /**
   * Creates an instance of NewUAMotivationIEGKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationIEGKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewUAMotivationKey
 *
 * @class NewUAMotivationKey
 */
class NewUAMotivationKey {
  /**
   * Creates an instance of NewUAMotivationKey.
   * @param {*} json
   * @memberof NewUAMotivationKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationID = json.motivationID;
    }
  }
}

/**
 * Models the NewUAMotivationKeyResponse
 *
 * @class NewUAMotivationKeyResponse
 */
class NewUAMotivationKeyResponse {
  /**
   * Creates an instance of NewUAMotivationKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationID = json.motivationID;
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAMotivation
 *
 * @class UAMotivation
 */
class UAMotivation {
  /**
   * Creates an instance of UAMotivation.
   * @param {*} json
   * @memberof UAMotivation
   */
  constructor(json = null) {
    if (json) {
      // string
      this.motivationId = json.motivationId;

      // string
      this.dataStoreId = json.dataStoreId;

      // string
      this.typeID = json.typeID;

      // object - CodeTableType
      if (json.state) {
        this.state = new CodeTableType(json.state);
      }

      // string
      this.scriptExecutionID = json.scriptExecutionID;

      // string
      this.username = json.username;

      // string
      this.timestamp = json.timestamp;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UAMotivationList
 *
 * @class UAMotivationList
 */
class UAMotivationList {
  /**
   * Creates an instance of UAMotivationList.
   * @param {*} json
   * @memberof UAMotivationList
   */
  constructor(json = null) {
    if (json) {
      // array - UAMotivation
      if (json.data) {
        this.data = json.data.map(object => new UAMotivation(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UAMotivation(object));
      }
    }
  }
}

/**
 * Models the NewUAResumeMotivationKey
 *
 * @class NewUAResumeMotivationKey
 */
class NewUAResumeMotivationKey {
  /**
   * Creates an instance of NewUAResumeMotivationKey.
   * @param {*} json
   * @memberof NewUAResumeMotivationKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.sessionID = json.sessionID;

      // string
      this.navigatorID = json.navigatorID;

      // string
      this.motivationID = json.motivationID;
    }
  }
}

/**
 * Models the NewUAResumeMotivationDetailsResponse
 *
 * @class NewUAResumeMotivationDetailsResponse
 */
class NewUAResumeMotivationDetailsResponse {
  /**
   * Creates an instance of NewUAResumeMotivationDetailsResponse.
   * @param {*} json
   * @memberof NewUAResumeMotivationDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.scriptExecutionID = json.scriptExecutionID;

      // boolean
      this.resumeScriptInd = json.resumeScriptInd;

      // boolean
      this.resumeResultsInd = json.resumeResultsInd;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKey
 *
 * @class NewUAMotivationIEGKey
 */
class NewUAMotivationIEGKey {
  /**
   * Creates an instance of NewUAMotivationIEGKey.
   * @param {*} json
   * @memberof NewUAMotivationIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewUAOnlineApplicationProgram
 *
 * @class NewUAOnlineApplicationProgram
 */
class NewUAOnlineApplicationProgram {
  /**
   * Creates an instance of NewUAOnlineApplicationProgram.
   * @param {*} json
   * @memberof NewUAOnlineApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;
    }
  }
}

/**
 * Models the NewUAOnlineApplicationType
 *
 * @class NewUAOnlineApplicationType
 */
class NewUAOnlineApplicationType {
  /**
   * Creates an instance of NewUAOnlineApplicationType.
   * @param {*} json
   * @memberof NewUAOnlineApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.screening_form_id = json.screening_form_id;

      // array - NewUAOnlineApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new NewUAOnlineApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new NewUAOnlineApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the NewUAApplicationFormIdentifierResponse
 *
 * @class NewUAApplicationFormIdentifierResponse
 */
class NewUAApplicationFormIdentifierResponse {
  /**
   * Creates an instance of NewUAApplicationFormIdentifierResponse.
   * @param {*} json
   * @memberof NewUAApplicationFormIdentifierResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.isPartialApplication = json.isPartialApplication;
    }
  }
}

/**
 * Models the NewCESignAndSubmit
 *
 * @class NewCESignAndSubmit
 */
class NewCESignAndSubmit {
  /**
   * Creates an instance of NewCESignAndSubmit.
   * @param {*} json
   * @memberof NewCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.perjuryESignatureInd = json.perjuryESignatureInd;

      // boolean
      this.defaultRenewalInd = json.defaultRenewalInd;

      // boolean
      this.absentParentESignatureInd = json.absentParentESignatureInd;

      // boolean
      this.informationChangedESignatureInd = json.informationChangedESignatureInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.renewalDuration = json.renewalDuration;

      // string
      this.motivation_id = json.motivation_id;

      // string
      this.categoryId = json.categoryId;

      // string
      this.navigatorID = json.navigatorID;
    }
  }
}

/**
 * Models the NewCESignAndSubmitResponseResponse
 *
 * @class NewCESignAndSubmitResponseResponse
 */
class NewCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the SPMProofType
 *
 * @class SPMProofType
 */
class SPMProofType {
  /**
   * Creates an instance of SPMProofType.
   * @param {*} json
   * @memberof SPMProofType
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.name) {
        this.name = new CodeTableType(json.name);
      }

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }
    }
  }
}

/**
 * Models the SPMAcceptedProof
 *
 * @class SPMAcceptedProof
 */
class SPMAcceptedProof {
  /**
   * Creates an instance of SPMAcceptedProof.
   * @param {*} json
   * @memberof SPMAcceptedProof
   */
  constructor(json = null) {
    if (json) {
      // string
      this.addedBy = json.addedBy;

      // string
      this.addedDate = json.addedDate;

      // string
      this.provider = json.provider;

      // string
      this.expiryDate = json.expiryDate;

      // object - SPMProofType
      if (json.proofType) {
        this.proofType = new SPMProofType(json.proofType);
      }
    }
  }
}

/**
 * Models the SPMProvidedFile
 *
 * @class SPMProvidedFile
 */
class SPMProvidedFile {
  /**
   * Creates an instance of SPMProvidedFile.
   * @param {*} json
   * @memberof SPMProvidedFile
   */
  constructor(json = null) {
    if (json) {
      // string
      this.linkId = json.linkId;

      // string
      this.fileId = json.fileId;

      // string
      this.receivedDate = json.receivedDate;

      // string
      this.fileName = json.fileName;

      // string
      this.addedBy = json.addedBy;

      // object - CodeTableType
      if (json.name) {
        this.name = new CodeTableType(json.name);
      }

      // object - CodeTableType
      if (json.reviewStatus) {
        this.reviewStatus = new CodeTableType(json.reviewStatus);
      }
    }
  }
}

/**
 * Models the SPMRelatedCase
 *
 * @class SPMRelatedCase
 */
class SPMRelatedCase {
  /**
   * Creates an instance of SPMRelatedCase.
   * @param {*} json
   * @memberof SPMRelatedCase
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // string
      this.id = json.id;

      // string
      this.caseReference = json.caseReference;
    }
  }
}

/**
 * Models the SPMRelatedPerson
 *
 * @class SPMRelatedPerson
 */
class SPMRelatedPerson {
  /**
   * Creates an instance of SPMRelatedPerson.
   * @param {*} json
   * @memberof SPMRelatedPerson
   */
  constructor(json = null) {
    if (json) {
      // string
      this.id = json.id;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the SPMRelatedEvidence
 *
 * @class SPMRelatedEvidence
 */
class SPMRelatedEvidence {
  /**
   * Creates an instance of SPMRelatedEvidence.
   * @param {*} json
   * @memberof SPMRelatedEvidence
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // string
      this.id = json.id;

      // string
      this.text = json.text;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }
    }
  }
}

/**
 * Models the SPMRequiredFor
 *
 * @class SPMRequiredFor
 */
class SPMRequiredFor {
  /**
   * Creates an instance of SPMRequiredFor.
   * @param {*} json
   * @memberof SPMRequiredFor
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.name) {
        this.name = new CodeTableType(json.name);
      }

      // string
      this.text = json.text;

      // object - SPMRelatedEvidence
      if (json.relatedEvidence) {
        this.relatedEvidence = new SPMRelatedEvidence(json.relatedEvidence);
      }
    }
  }
}

/**
 * Models the SPMGroupReference
 *
 * @class SPMGroupReference
 */
class SPMGroupReference {
  /**
   * Creates an instance of SPMGroupReference.
   * @param {*} json
   * @memberof SPMGroupReference
   */
  constructor(json = null) {
    if (json) {
      // string
      this.id = json.id;
    }
  }
}

/**
 * Models the SPMRequiredProof
 *
 * @class SPMRequiredProof
 */
class SPMRequiredProof {
  /**
   * Creates an instance of SPMRequiredProof.
   * @param {*} json
   * @memberof SPMRequiredProof
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.name) {
        this.name = new CodeTableType(json.name);
      }

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // boolean
      this.mandatory = json.mandatory;

      // object - CodeTableType
      if (json.usage) {
        this.usage = new CodeTableType(json.usage);
      }

      // string
      this.fromDate = json.fromDate;

      // string
      this.toDate = json.toDate;

      // object - SPMGroupReference
      if (json.group) {
        this.group = new SPMGroupReference(json.group);
      }
    }
  }
}

/**
 * Models the SPMWaiver
 *
 * @class SPMWaiver
 */
class SPMWaiver {
  /**
   * Creates an instance of SPMWaiver.
   * @param {*} json
   * @memberof SPMWaiver
   */
  constructor(json = null) {
    if (json) {
      // string
      this.startDate = json.startDate;

      // string
      this.endDate = json.endDate;
    }
  }
}

/**
 * Models the SPMVerification
 *
 * @class SPMVerification
 */
class SPMVerification {
  /**
   * Creates an instance of SPMVerification.
   * @param {*} json
   * @memberof SPMVerification
   */
  constructor(json = null) {
    if (json) {
      // string
      this.verificationId = json.verificationId;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // string
      this.dueDate = json.dueDate;

      // boolean
      this.mandatory = json.mandatory;

      // object - CodeTableType
      if (json.category) {
        this.category = new CodeTableType(json.category);
      }

      // boolean
      this.clientProvided = json.clientProvided;

      // array - SPMAcceptedProof
      if (json.acceptedProofs) {
        this.acceptedProofs = json.acceptedProofs.map(object => new SPMAcceptedProof(object));
      } else if (json.constructor === Array) {
        this.acceptedProofs = json.map(object => new SPMAcceptedProof(object));
      }

      // array - SPMProvidedFile
      if (json.providedFiles) {
        this.providedFiles = json.providedFiles.map(object => new SPMProvidedFile(object));
      } else if (json.constructor === Array) {
        this.providedFiles = json.map(object => new SPMProvidedFile(object));
      }

      // object - SPMRelatedCase
      if (json.relatedCase) {
        this.relatedCase = new SPMRelatedCase(json.relatedCase);
      }

      // object - SPMRelatedPerson
      if (json.relatedPerson) {
        this.relatedPerson = new SPMRelatedPerson(json.relatedPerson);
      }

      // object - SPMRequiredFor
      if (json.requiredFor) {
        this.requiredFor = new SPMRequiredFor(json.requiredFor);
      }

      // array - SPMRequiredProof
      if (json.requiredProofs) {
        this.requiredProofs = json.requiredProofs.map(object => new SPMRequiredProof(object));
      } else if (json.constructor === Array) {
        this.requiredProofs = json.map(object => new SPMRequiredProof(object));
      }

      // object - SPMWaiver
      if (json.waiver) {
        this.waiver = new SPMWaiver(json.waiver);
      }
    }
  }
}

/**
 * Models the MMSPMVerificationList
 *
 * @class MMSPMVerificationList
 */
class MMSPMVerificationList {
  /**
   * Creates an instance of MMSPMVerificationList.
   * @param {*} json
   * @memberof MMSPMVerificationList
   */
  constructor(json = null) {
    if (json) {
      // array - SPMVerification
      if (json.data) {
        this.data = json.data.map(object => new SPMVerification(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new SPMVerification(object));
      }
    }
  }
}

/**
 * Models the NewSPMFileUploadContents
 *
 * @class NewSPMFileUploadContents
 */
class NewSPMFileUploadContents {
  /**
   * Creates an instance of NewSPMFileUploadContents.
   * @param {*} json
   * @memberof NewSPMFileUploadContents
   */
  constructor(json = null) {
    if (json) {
      // string
      this.data = json.data;
    }
  }
}

/**
 * Models the NewMMResetPasswordDetails
 *
 * @class NewMMResetPasswordDetails
 */
class NewMMResetPasswordDetails {
  /**
   * Creates an instance of NewMMResetPasswordDetails.
   * @param {*} json
   * @memberof NewMMResetPasswordDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // string
      this.password = json.password;

      // string
      this.existingPassword = json.existingPassword;

      // string
      this.confirmedPassword = json.confirmedPassword;
    }
  }
}

/**
 * Models the NewUAApplicationFormIdentifier
 *
 * @class NewUAApplicationFormIdentifier
 */
class NewUAApplicationFormIdentifier {
  /**
   * Creates an instance of NewUAApplicationFormIdentifier.
   * @param {*} json
   * @memberof NewUAApplicationFormIdentifier
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.isPartialApplication = json.isPartialApplication;
    }
  }
}

/**
 * Models the NewMMNonMagiApplicationFormResponse
 *
 * @class NewMMNonMagiApplicationFormResponse
 */
class NewMMNonMagiApplicationFormResponse {
  /**
   * Creates an instance of NewMMNonMagiApplicationFormResponse.
   * @param {*} json
   * @memberof NewMMNonMagiApplicationFormResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.isApplying = json.isApplying;
    }
  }
}

/**
 * Models the NewMMPopulateNonMagi
 *
 * @class NewMMPopulateNonMagi
 */
class NewMMPopulateNonMagi {
  /**
   * Creates an instance of NewMMPopulateNonMagi.
   * @param {*} json
   * @memberof NewMMPopulateNonMagi
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // string
      this.hcr_form_id = json.hcr_form_id;
    }
  }
}

/**
 * Models the NewUAApplicationFormId
 *
 * @class NewUAApplicationFormId
 */
class NewUAApplicationFormId {
  /**
   * Creates an instance of NewUAApplicationFormId.
   * @param {*} json
   * @memberof NewUAApplicationFormId
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;
    }
  }
}

/**
 * Models the NewCaseParticipantEvdUploadKey
 *
 * @class NewCaseParticipantEvdUploadKey
 */
class NewCaseParticipantEvdUploadKey {
  /**
   * Creates an instance of NewCaseParticipantEvdUploadKey.
   * @param {*} json
   * @memberof NewCaseParticipantEvdUploadKey
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isCaseEvidence = json.isCaseEvidence;

      // string
      this.caseID = json.caseID;

      // string
      this.participantID = json.participantID;

      // string
      this.verificationID = json.verificationID;
    }
  }
}

/**
 * Models the MMIsMotivationScriptResponse
 *
 * @class MMIsMotivationScriptResponse
 */
class MMIsMotivationScriptResponse {
  /**
   * Creates an instance of MMIsMotivationScriptResponse.
   * @param {*} json
   * @memberof MMIsMotivationScriptResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isMotivationScript = json.isMotivationScript;
    }
  }
}

/**
 * Models the UADraftApplicationProgram
 *
 * @class UADraftApplicationProgram
 */
class UADraftApplicationProgram {
  /**
   * Creates an instance of UADraftApplicationProgram.
   * @param {*} json
   * @memberof UADraftApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the UADraftApplication
 *
 * @class UADraftApplication
 */
class UADraftApplication {
  /**
   * Creates an instance of UADraftApplication.
   * @param {*} json
   * @memberof UADraftApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.createdOn = json.createdOn;

      // string
      this.lastUpdatedOn = json.lastUpdatedOn;

      // string
      this.draft_application_id = json.draft_application_id;

      // boolean
      this.isSubmissionForm = json.isSubmissionForm;

      // boolean
      this.isMotivation = json.isMotivation;

      // string
      this.motivationID = json.motivationID;

      // boolean
      this.isRenewal = json.isRenewal;

      // array - UADraftApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UADraftApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UADraftApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the UADraftApplicationList
 *
 * @class UADraftApplicationList
 */
class UADraftApplicationList {
  /**
   * Creates an instance of UADraftApplicationList.
   * @param {*} json
   * @memberof UADraftApplicationList
   */
  constructor(json = null) {
    if (json) {
      // array - UADraftApplication
      if (json.data) {
        this.data = json.data.map(object => new UADraftApplication(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UADraftApplication(object));
      }
    }
  }
}

/**
 * Models the UASystemProperty
 *
 * @class UASystemProperty
 */
class UASystemProperty {
  /**
   * Creates an instance of UASystemProperty.
   * @param {*} json
   * @memberof UASystemProperty
   */
  constructor(json = null) {
    if (json) {
      // string
      this.propertyValue = json.propertyValue;

      // string
      this.property_name = json.property_name;
    }
  }
}

/**
 * Models the UASystemPropertyList
 *
 * @class UASystemPropertyList
 */
class UASystemPropertyList {
  /**
   * Creates an instance of UASystemPropertyList.
   * @param {*} json
   * @memberof UASystemPropertyList
   */
  constructor(json = null) {
    if (json) {
      // array - UASystemProperty
      if (json.data) {
        this.data = json.data.map(object => new UASystemProperty(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UASystemProperty(object));
      }
    }
  }
}

/**
 * Models the MMValidateSession
 *
 * @class MMValidateSession
 */
class MMValidateSession {
  /**
   * Creates an instance of MMValidateSession.
   * @param {*} json
   * @memberof MMValidateSession
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isInvalidSession = json.isInvalidSession;
    }
  }
}

/**
 * Models the NewSPMVerificationFileLinkCreation
 *
 * @class NewSPMVerificationFileLinkCreation
 */
class NewSPMVerificationFileLinkCreation {
  /**
   * Creates an instance of NewSPMVerificationFileLinkCreation.
   * @param {*} json
   * @memberof NewSPMVerificationFileLinkCreation
   */
  constructor(json = null) {
    if (json) {
      // string
      this.fileId = json.fileId;

      // string
      this.verificationId = json.verificationId;
    }
  }
}

/**
 * Models the UAApplicationProgramStatus
 *
 * @class UAApplicationProgramStatus
 */
class UAApplicationProgramStatus {
  /**
   * Creates an instance of UAApplicationProgramStatus.
   * @param {*} json
   * @memberof UAApplicationProgramStatus
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.hasPendingWithdrawalRequest = json.hasPendingWithdrawalRequest;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // string
      this.withdrawalRequestStatusMessage = json.withdrawalRequestStatusMessage;

      // boolean
      this.canBeWithdrawn = json.canBeWithdrawn;
    }
  }
}

/**
 * Models the UASubmittedApplicationProgram
 *
 * @class UASubmittedApplicationProgram
 */
class UASubmittedApplicationProgram {
  /**
   * Creates an instance of UASubmittedApplicationProgram.
   * @param {*} json
   * @memberof UASubmittedApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.application_program_id = json.application_program_id;

      // string
      this.case_id = json.case_id;

      // object - UAApplicationProgramStatus
      if (json.programStatusDetails) {
        this.programStatusDetails = new UAApplicationProgramStatus(json.programStatusDetails);
      }
    }
  }
}

/**
 * Models the UASubmittedApplication
 *
 * @class UASubmittedApplication
 */
class UASubmittedApplication {
  /**
   * Creates an instance of UASubmittedApplication.
   * @param {*} json
   * @memberof UASubmittedApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_id = json.application_id;

      // string
      this.submittedOn = json.submittedOn;

      // string
      this.name = json.name;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // boolean
      this.isRenewal = json.isRenewal;

      // array - UASubmittedApplicationProgram
      if (json.applicationPrograms) {
        this.applicationPrograms = json.applicationPrograms.map(
          object => new UASubmittedApplicationProgram(object)
        );
      } else if (json.constructor === Array) {
        this.applicationPrograms = json.map(object => new UASubmittedApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the UASubmittedApplicationList
 *
 * @class UASubmittedApplicationList
 */
class UASubmittedApplicationList {
  /**
   * Creates an instance of UASubmittedApplicationList.
   * @param {*} json
   * @memberof UASubmittedApplicationList
   */
  constructor(json = null) {
    if (json) {
      // array - UASubmittedApplication
      if (json.data) {
        this.data = json.data.map(object => new UASubmittedApplication(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UASubmittedApplication(object));
      }
    }
  }
}

/**
 * Models the NewMMCESignAndSubmit
 *
 * @class NewMMCESignAndSubmit
 */
class NewMMCESignAndSubmit {
  /**
   * Creates an instance of NewMMCESignAndSubmit.
   * @param {*} json
   * @memberof NewMMCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.applicantSignatureInd = json.applicantSignatureInd;

      // boolean
      this.renewalDataQuestionInd = json.renewalDataQuestionInd;

      // boolean
      this.cooperateMedicalQuestionInd = json.cooperateMedicalQuestionInd;

      // boolean
      this.rightsAndResponsibilitiesInd = json.rightsAndResponsibilitiesInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.renewalDuration = json.renewalDuration;

      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.medicalExpenseQuestionInd = json.medicalExpenseQuestionInd;
    }
  }
}

/**
 * Models the NewUAIntakeApplicationIdentifierResponse
 *
 * @class NewUAIntakeApplicationIdentifierResponse
 */
class NewUAIntakeApplicationIdentifierResponse {
  /**
   * Creates an instance of NewUAIntakeApplicationIdentifierResponse.
   * @param {*} json
   * @memberof NewUAIntakeApplicationIdentifierResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;
    }
  }
}

/**
 * Models the UAProgramPdfForm
 *
 * @class UAProgramPdfForm
 */
class UAProgramPdfForm {
  /**
   * Creates an instance of UAProgramPdfForm.
   * @param {*} json
   * @memberof UAProgramPdfForm
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pdfFormId = json.pdfFormId;
    }
  }
}

/**
 * Models the UAProgram
 *
 * @class UAProgram
 */
class UAProgram {
  /**
   * Creates an instance of UAProgram.
   * @param {*} json
   * @memberof UAProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.associatedPendingApplicationId = json.associatedPendingApplicationId;

      // string
      this.icon = json.icon;

      // array - UAProgramPdfForm
      if (json.pdfFormIds) {
        this.pdfFormIds = json.pdfFormIds.map(object => new UAProgramPdfForm(object));
      } else if (json.constructor === Array) {
        this.pdfFormIds = json.map(object => new UAProgramPdfForm(object));
      }
    }
  }
}

/**
 * Models the UAWithdrawalReason
 *
 * @class UAWithdrawalReason
 */
class UAWithdrawalReason {
  /**
   * Creates an instance of UAWithdrawalReason.
   * @param {*} json
   * @memberof UAWithdrawalReason
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }
    }
  }
}

/**
 * Models the UAIntakeApplicationType
 *
 * @class UAIntakeApplicationType
 */
class UAIntakeApplicationType {
  /**
   * Creates an instance of UAIntakeApplicationType.
   * @param {*} json
   * @memberof UAIntakeApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.iegScriptId = json.iegScriptId;

      // boolean
      this.individualProgramSelectionAllowed = json.individualProgramSelectionAllowed;

      // string
      this.iegSubmissionScriptId = json.iegSubmissionScriptId;

      // string
      this.datastoreSchema = json.datastoreSchema;

      // string
      this.name = json.name;

      // string
      this.summary = json.summary;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.pdfFormId = json.pdfFormId;

      // string
      this.associatedDraftApplicationId = json.associatedDraftApplicationId;

      // string
      this.url = json.url;

      // boolean
      this.submitOnCompletionInd = json.submitOnCompletionInd;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }

      // array - UAWithdrawalReason
      if (json.allowedWithdrawalReasons) {
        this.allowedWithdrawalReasons = json.allowedWithdrawalReasons.map(
          object => new UAWithdrawalReason(object)
        );
      } else if (json.constructor === Array) {
        this.allowedWithdrawalReasons = json.map(object => new UAWithdrawalReason(object));
      }
    }
  }
}

export default {
  UAMotivationType,

  UAListMotivationTypes,

  NewUAMotivationType,

  NewUAMotivationIEGKeyResponse,

  NewUAMotivationKey,

  NewUAMotivationKeyResponse,

  CodeTableType,

  UAMotivation,

  UAMotivationList,

  NewUAResumeMotivationKey,

  NewUAResumeMotivationDetailsResponse,

  NewUAMotivationIEGKey,

  NewUAOnlineApplicationProgram,

  NewUAOnlineApplicationType,

  NewUAApplicationFormIdentifierResponse,

  NewCESignAndSubmit,

  NewCESignAndSubmitResponseResponse,

  SPMProofType,

  SPMAcceptedProof,

  SPMProvidedFile,

  SPMRelatedCase,

  SPMRelatedPerson,

  SPMRelatedEvidence,

  SPMRequiredFor,

  SPMGroupReference,

  SPMRequiredProof,

  SPMWaiver,

  SPMVerification,

  MMSPMVerificationList,

  NewSPMFileUploadContents,

  NewMMResetPasswordDetails,

  NewUAApplicationFormIdentifier,

  NewMMNonMagiApplicationFormResponse,

  NewMMPopulateNonMagi,

  NewUAApplicationFormId,

  NewCaseParticipantEvdUploadKey,

  MMIsMotivationScriptResponse,

  UADraftApplicationProgram,

  UADraftApplication,

  UADraftApplicationList,

  UASystemProperty,

  UASystemPropertyList,

  MMValidateSession,

  NewSPMVerificationFileLinkCreation,

  UAApplicationProgramStatus,

  UASubmittedApplicationProgram,

  UASubmittedApplication,

  UASubmittedApplicationList,

  NewMMCESignAndSubmit,

  NewUAIntakeApplicationIdentifierResponse,

  UAProgramPdfForm,

  UAProgram,

  UAWithdrawalReason,

  UAIntakeApplicationType,
};
