/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_MM_ONLINE_RENEWAL_RIDP_FORM,
  POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP,
  FETCH_MM_ONLINE_CATEGORIES,
  POST_MM_IS_RENEWAL_SCRIPT,
  FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY,
  POST_MM_STORE_ONLINE_RENEWAL,
} from './actionTypes';

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmOnlineRenewalRidpFormReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_ONLINE_RENEWAL_RIDP_FORM);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmOnlineRenewalRidpFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_ONLINE_RENEWAL_RIDP_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmOnlineRenewalRidpFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_ONLINE_RENEWAL_RIDP_FORM);

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmSubmitOnlineRenewalRidpReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmSubmitOnlineRenewalRidpReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmSubmitOnlineRenewalRidpErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP);

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmOnlineCategoriesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_ONLINE_CATEGORIES);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmOnlineCategoriesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_ONLINE_CATEGORIES);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmOnlineCategoriesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_ONLINE_CATEGORIES);

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmIsRenewalScriptReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_IS_RENEWAL_SCRIPT);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmIsRenewalScriptReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_IS_RENEWAL_SCRIPT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmIsRenewalScriptErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_IS_RENEWAL_SCRIPT);

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMmIsRenewalToDoDisplayReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMmIsRenewalToDoDisplayReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMmIsRenewalToDoDisplayErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY);

/**
 * Updates the details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMmStoreOnlineRenewalReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MM_STORE_ONLINE_RENEWAL);

/**
 * Updates the indicator of creation of details in the 'MMOnlineRenewal' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMmStoreOnlineRenewalReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MM_STORE_ONLINE_RENEWAL);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMmStoreOnlineRenewalErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MM_STORE_ONLINE_RENEWAL);

const reducers = combineReducers({
  postMmOnlineRenewalRidpForm: postMmOnlineRenewalRidpFormReducer,

  isFetchingPostMmOnlineRenewalRidpForm: isFetchingPostMmOnlineRenewalRidpFormReducer,
  postMmOnlineRenewalRidpFormError: postMmOnlineRenewalRidpFormErrorReducer,

  postMmSubmitOnlineRenewalRidp: postMmSubmitOnlineRenewalRidpReducer,

  isFetchingPostMmSubmitOnlineRenewalRidp: isFetchingPostMmSubmitOnlineRenewalRidpReducer,
  postMmSubmitOnlineRenewalRidpError: postMmSubmitOnlineRenewalRidpErrorReducer,

  fetchMmOnlineCategories: fetchMmOnlineCategoriesReducer,

  isFetchingFetchMmOnlineCategories: isFetchingFetchMmOnlineCategoriesReducer,
  fetchMmOnlineCategoriesError: fetchMmOnlineCategoriesErrorReducer,

  postMmIsRenewalScript: postMmIsRenewalScriptReducer,

  isFetchingPostMmIsRenewalScript: isFetchingPostMmIsRenewalScriptReducer,
  postMmIsRenewalScriptError: postMmIsRenewalScriptErrorReducer,

  fetchMmIsRenewalToDoDisplay: fetchMmIsRenewalToDoDisplayReducer,

  isFetchingFetchMmIsRenewalToDoDisplay: isFetchingFetchMmIsRenewalToDoDisplayReducer,
  fetchMmIsRenewalToDoDisplayError: fetchMmIsRenewalToDoDisplayErrorReducer,

  postMmStoreOnlineRenewal: postMmStoreOnlineRenewalReducer,

  isFetchingPostMmStoreOnlineRenewal: isFetchingPostMmStoreOnlineRenewalReducer,
  postMmStoreOnlineRenewalError: postMmStoreOnlineRenewalErrorReducer,
});

export default { reducers };
