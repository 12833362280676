/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const Paths = {
  // MMRP POC START For CE Server Configuration
  MM_APPLICATION_OVERVIEW: '/application-overview',
  // MMPROD-4104 START
  MM_HCR_ESIGNATURE: '/hcrapplication/esignature',
  // MMPROD-4104 END
  MM_HCR_CONFIRMATION: '/hcrapplication/confirmation',
  MM_HCR_CONFIRMATION_FAILED: '/hcrapplication/failed',
  // MMPROD-5970 BEGIN, HCR to CGIS transition page
  MM_HCR_TRANS_CONFIRMATION: '/hcrapplication/confirmation/cgis',
  // MMPROD-5970 END
  // MMRP POC END For CE Server Configuration
  // START dhrkhai MMPROD-11020 Non-MAGI E-Signature page changes
  MM_CGIS_ESIGNATURE: '/cgisapplication/esignature',
  // END MMPROD-11020
  // START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error
  MM_VERIFICATIONS: {
    ROOT: '/verifications',
    DETAILS: '/verifications/details',
  },
  // END DHAKELL MMPROD-2638 CE verification
  // START dhakell MMPROD-2975 - Fix continue option for MAGI app
  ACCOUNT: '/account',
  // END dhakell MMPROD-2975
  // START dhakell MMPROD-3168 - customization for CE Non-MAGI app initialization
  APPLY: '/apply',
  // END dhakell MMPROD-3168
  // START DHRCHAD MMPROD-3183, MMPROD-5833 - COC Overview, new form, Submission Screen
  LIFE_EVENTS: {
    ROOT: '/life-events',
    FORM: '/life-events/form/new',
    OVERVIEW: '/life-events/overview',
    // START MMPROD-10553: ACR esignature screen
    RESUME: '/life-events/form',
    ACR_ESIGNATURE: '/life-events/annualcasereview/esignature',
    // END MMPROD-10553
  },
  // END DHRCHAD MMPROD-3183, MMPROD-5833 - COC Overview, new form, Submission Screen
  // START DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards
  // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  YOUR_INFORMATION: '/yourinformation',
  // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  // END DHRCHAD MMPROD-3097, MMPROD-5358 - Profile and COC Cards
  // START MMPROD-3360 dhakell - customizing password special character requirement
  SIGNUP: '/signup',
  // END MMPROD-3360 dhakell
  // START MMPROD-3328 dhdrama - customising download alert for SC
  // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  MM_APPLICATIONS: '/applications',
  // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  MM_NOTICES: {
    ROOT: '/notices',
    DETAILS: '/notices/details',
  },
  // END MMPROD-3328 dhdrama
  SAMPLE_APPLICATION: {
    ROOT: '/sample-application',
    OVERVIEW: '/sample-application/overview',
    FORM: '/sample-application/form',
    CONFIRMATION: '/sample-application/confirmation',
  },
  // START MMPROD-4106: dhdkuma - Home from router/Paths
  HOME: '/',
  // END MMPROD-4106: dhdkuma
  // START MMPROD-4190 dhakell - login page updates
  LOGIN: '/login',
  // END MMPROD-4190 dhakell - login page updates
  // START MMPROD-5653 : dhdkuma - Appeal Request customization
  APPEALS: {
    ROOT: '/appeals',
    FORM: '/appeals/form/new',
    RESUME: '/appeals/form',
    OVERVIEW: '/appeals/overview',
    SUBMITTED: '/appeals/submitted',
  },
  // END MMPROD-5653 : dhdkuma
  // START dhryoun MMPROD-4990 - Section 3.16 Reset Password Changes
  MM_RESETPASSWORD: '/resetpassword',
  // END dhryoun MMPROD-4990
  // START MMPROD-10376 dhdkuma - Save and Exit Changes
  MM_EXITWARNING: '/save-exit',
  // END MMPROD-10376 dhdkuma
  MM_LOGOUT_ALL_USERS: '/logoutAllUsers',
  // START : MMPROD-10541 : dhrchad
  RENEWAL: {
    ROOT: '/renewal',
    SELECT_FORM: '/renewal/selectreviewform',
  },
  // END : MMPROD-10541 : dhrchad

  SIGNUPORLOGIN: '/signUpOrLogin',
  ACCOUNTLINK: {
    INFORMATIONCHANGED: '/informationchanged',
    MULTIPLEMATCH: '/multiplematch',
    SUCCESS: '/success',
    MEDICAIDIDSEARCH: '/medicaididsearch',
  },

  IDPLOGIN: '/idplogin',
};

/**
 * Provides access the the URLs that are mapped to each page via the route configuration.
 */
export default Paths;
