/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_ONLINE_CATEGORIES,
  CREATE_APPLICATION_FORM,
  SET_SELECTED_APPLICATION_TYPE,
} from './actionTypes';

import { fetchOnlineCategoriesUtil, createApplicationFormUtil } from './utils';

/**
 * Contains the action creator functions that update the SampleModule slice of the store.
 *
 * @namespace SampleModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/online_categories
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof SampleModuleActions
   */
  static fetchOnlineCategories = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchOnlineCategoriesUtil,
      FETCH_ONLINE_CATEGORIES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchOnlineCategories.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof SampleModuleActions
   */
  static resetFetchOnlineCategories = (dispatch, data) => {
    const json = {
      type: FETCH_ONLINE_CATEGORIES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchOnlineCategories.
   *
   * @param {function} dispatch
   * @function
   * @memberof SampleModuleActions
   */
  static resetFetchOnlineCategoriesError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_ONLINE_CATEGORIES.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/application_form
   *
   * @param {NewUAOnlineApplicationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof SampleModuleActions
   */
  static createApplicationForm = (dispatch, newUAOnlineApplicationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      createApplicationFormUtil.bind(null, newUAOnlineApplicationType),
      CREATE_APPLICATION_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to createApplicationForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof SampleModuleActions
   */
  static resetCreateApplicationFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, CREATE_APPLICATION_FORM.failureAction);
  };

  /**
   * Dispatches the data to the store.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof SampleModuleActions
   */
  static setSelectedApplicationType = (dispatch, data) => {
    const json = {
      type: SET_SELECTED_APPLICATION_TYPE,
      payload: data || {},
    };
    dispatch(json);
  };
}
