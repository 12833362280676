/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import PATHS from '@spm/universal-access-ui/src/router/Paths';
import { NAVIGATION_HEADER_TABS } from '../constants';
import MMPATHS from '../../../paths';

/**
 * Returns true if the selected navigation tab should be active based on the
 * current url pathname. The url pathname string includes the leading forward
 * slash. e.g. /account
 *
 * @param {string} urlPathname current url pathname.
 * @param {string} navigationTabName current navigation tab selected.
 * @returns true if navigation tab should be active based on url pathname.
 */
const isTabActiveForUrlPathname = (urlPathname, navigationTabName) => {
  const {
    FIND_HELP,
    HOME,
    BENEFITS,
    YOUR_ACCOUNT,
    DASHBOARD,
    YOUR_BENEFITS,
    // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    YOUR_INFORMATION,
    // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    APPEALS,
    NOTICES,
    LIFE_EVENTS,
    VERIFICATIONS,
    RESETPASSWORD,
  } = NAVIGATION_HEADER_TABS;

  switch (navigationTabName) {
    case FIND_HELP.NAME:
      return (
        urlPathname === PATHS.HOME ||
        urlPathname === PATHS.APPLY ||
        urlPathname === PATHS.BENEFIT_SELECTION ||
        urlPathname === PATHS.APPLICATION_OVERVIEW ||
        urlPathname === PATHS.ELIGIBILITY.ROOT ||
        urlPathname === PATHS.ELIGIBILITY.BENEFIT_SELECTION
      );
    case YOUR_ACCOUNT.NAME:
      return (
        urlPathname === PATHS.ACCOUNT ||
        urlPathname === MMPATHS.MM_APPLICATIONS ||
        // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        urlPathname === MMPATHS.YOUR_INFORMATION ||
        // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
        urlPathname === PATHS.PAYMENTS.ROOT ||
        urlPathname === PATHS.PAYMENTS.DETAILS ||
        urlPathname === PATHS.PAYMENTS.NEXT_DETAILS ||
        urlPathname === PATHS.APPEALS.ROOT ||
        urlPathname === PATHS.NOTICES.ROOT ||
        urlPathname === PATHS.NOTICES.DETAILS ||
        urlPathname === PATHS.LIFE_EVENTS.HISTORY ||
        /* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom path */
        urlPathname === MMPATHS.MM_VERIFICATIONS.ROOT ||
        urlPathname === MMPATHS.MM_VERIFICATIONS.DETAILS ||
        /* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */
        /* START DHRYOUN MMPROD-4990 CE Section 3.16 Reset Password Changes */
        urlPathname === MMPATHS.MM_RESETPASSWORD
        /* END DHRYOUN MMPROD-4990 CE Section 3.16 Reset Password Changes */
      );
    case APPEALS.NAME:
      return urlPathname === PATHS.APPEALS.ROOT || urlPathname === PATHS.APPEALS.DETAILS;
    case NOTICES.NAME:
      return urlPathname === PATHS.NOTICES.ROOT || urlPathname === PATHS.NOTICES.DETAILS;
    case HOME.NAME:
      return urlPathname === PATHS.HOME;
    case BENEFITS.NAME:
      return (
        urlPathname === PATHS.APPLY ||
        urlPathname === PATHS.BENEFIT_SELECTION ||
        urlPathname === PATHS.APPLICATION_OVERVIEW
      );
    case DASHBOARD.NAME:
      return urlPathname === PATHS.ACCOUNT;
    case YOUR_BENEFITS.NAME:
      return (
        urlPathname === MMPATHS.MM_APPLICATIONS ||
        urlPathname === PATHS.PAYMENTS.ROOT ||
        urlPathname === PATHS.PAYMENTS.DETAILS ||
        urlPathname === PATHS.PAYMENTS.NEXT_DETAILS
      );
    // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    case YOUR_INFORMATION.NAME:
      return urlPathname === MMPATHS.YOUR_INFORMATION;
    // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    case LIFE_EVENTS.NAME:
      return urlPathname === PATHS.LIFE_EVENTS.HISTORY;

    case VERIFICATIONS.NAME:
      return (
        /* START DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error - using custom path */
        urlPathname === MMPATHS.MM_VERIFICATIONS.ROOT ||
        urlPathname === MMPATHS.MM_VERIFICATIONS.DETAILS
        /* END DHAKELL MMPROD-2638 CE Verification Doc Upload 415 error */
      );

    /* START DHRYOUN MMPROD-4990 CE Section 3.16 Reset Password Changes */
    case RESETPASSWORD.NAME:
      return urlPathname === MMPATHS.MM_RESETPASSWORD;
    /* END DHRYOUN MMPROD-4990 CE Section 3.16 Reset Password Changes */
    default:
      return false;
  }
};

/**
 * Gets the id of the active primary navigation tab.
 *
 * @param {string} urlPathname current url pathname.
 * @returns id of active primary navigation tab.
 */
const getActivePrimaryNavigationTabId = urlPathname => {
  const {
    HOME,
    BENEFITS,
    DASHBOARD,
    YOUR_BENEFITS,
    APPEALS,
    NOTICES,
    // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    YOUR_INFORMATION,
    // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
    LIFE_EVENTS,
    VERIFICATIONS,
    RESETPASSWORD,
  } = NAVIGATION_HEADER_TABS;

  let result = HOME.ID;
  if (isTabActiveForUrlPathname(urlPathname, BENEFITS.NAME)) {
    result = BENEFITS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, DASHBOARD.NAME)) {
    result = DASHBOARD.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, YOUR_BENEFITS.NAME)) {
    result = YOUR_BENEFITS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, APPEALS.NAME)) {
    result = APPEALS.ID;
    // START : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  } else if (isTabActiveForUrlPathname(urlPathname, YOUR_INFORMATION.NAME)) {
    result = YOUR_INFORMATION.ID;
    // END : MMPROD-8258 :DHSUMAT - Rename Profile & My Applications
  } else if (isTabActiveForUrlPathname(urlPathname, LIFE_EVENTS.NAME)) {
    result = LIFE_EVENTS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, NOTICES.NAME)) {
    result = NOTICES.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, VERIFICATIONS.NAME)) {
    result = VERIFICATIONS.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, RESETPASSWORD.NAME)) {
    result = RESETPASSWORD.ID;
  }
  return result;
};

/**
 * Gets the id of the active secondary navigation tab. If no tabs are active
 * returns string 'blank', reason is that the SecondaryNavigation 'current' prop
 * will default to the first child tab if null.
 *
 * @param {string} urlPathname current url pathname.
 * @returns id of active secondary navigation tab or 'blank' if no active tabs.
 */
const getActiveSecondaryNavigationTabId = urlPathname => {
  const { HOME, YOUR_ACCOUNT, BENEFITS } = NAVIGATION_HEADER_TABS;

  let result = HOME.ID;
  if (isTabActiveForUrlPathname(urlPathname, YOUR_ACCOUNT.NAME)) {
    result = YOUR_ACCOUNT.ID;
  } else if (isTabActiveForUrlPathname(urlPathname, BENEFITS.NAME)) {
    result = BENEFITS.ID;
  }
  return result;
};

export {
  isTabActiveForUrlPathname,
  getActivePrimaryNavigationTabId,
  getActiveSecondaryNavigationTabId,
};
