/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Signup_Title',
    // START DHSUMAT - MMPROD-5116 V8 Upgrade Section 3.4 Common Screens Sign up changes
    defaultMessage: 'Create an account',
  },
  subtitle: {
    id: 'Signup_Subtitle',
    defaultMessage:
      'In order to set up a user account, please enter your email address below. If you have questions about creating your user account, please call the Member Services Center at 1-888-549-0820.',
  },
  personalsectiontitle: {
    id: 'PersonalSection_Title',
    defaultMessage: 'Personal details',
  },
  usernameandpasswordlabel: {
    id: 'UsernameAndPassword_Label',
    defaultMessage: 'User name and password',
  },
  usernameandpasswordtext: {
    id: 'UsernameAndPassword_Text',
    defaultMessage:
      'Your User Name must be at least 6 characters. Your password must contain a minimum of 12 characters and contain at least 3 numbers and/or special characters. We recommend setting a password with at least:',
  },
  passwordtext12characters: {
    id: 'PasswordText_12characters',
    defaultMessage: '• 12 characters',
  },
  passwordtextuppercase: {
    id: 'PasswordText_1uppercase',
    defaultMessage: '• 1 uppercase character',
  },
  passwordtextlowercase: {
    id: 'PasswordText_1lowercase',
    defaultMessage: '• 1 lowercase character',
  },
  passwordtextnumbers: {
    id: 'PasswordText_Numbers',
    defaultMessage: '• 1 or more numbers',
  },
  passwordtextspecialcharacters: {
    id: 'PasswordText_SpecialCharacters',
    defaultMessage: '• 1 or more special characters',
  },

  passwordhint: {
    id: 'PasswordHint_Label',
    defaultMessage: 'Password hint',
  },
  passwordhinttext: {
    id: 'PasswordHint_Text',
    defaultMessage:
      'If you forget your password, you can use your security question to set a new password. Please select your question and type your answer below.',
  },

  retypepassword: {
    id: 'Signup_LabelRetypePassword',
    defaultMessage: 'Re-type password',
  },
  // START : MMPROD-10362 : dhdkuma : V8Upgrade : Fixed validation messages as per USD
  retypepasswordMatch: {
    id: 'Signup_LabelRetypePassword_Match',
    defaultMessage: 'The Password field and the Confirm Password field contain different values.',
  },
  // END : MMPROD-10362 : dhdkuma : V8Upgrade
  // END DHSUMAT - MMPROD-5116 V8 Upgrade Section 3.4 Common Screens Sign up changes
  firstName: {
    id: 'Signup_LabelFirstName',
    defaultMessage: 'First name',
  },
  surname: {
    id: 'Signup_LabelSurname',
    defaultMessage: 'Last name',
  },
  emailAddress: {
    id: 'Signup_LabelEmailAddress',
    defaultMessage: 'Email address',
  },
  emailAddressHint: {
    id: 'Signup_LabelEmailAddressHint',
    defaultMessage: 'You will use this email to log in and receive updates',
  },
  username: {
    id: 'Signup_LabelUsername',
    defaultMessage: 'User name',
  },
  noEmail: {
    id: 'Signup_LabelNoEmail',
    defaultMessage: "I don't have an email address",
  },
  password: {
    id: 'Signup_LabelPassword',
    defaultMessage: 'Password',
  },
  secretQuestionType: {
    id: 'Signup_LabelSecretQuestionType',
    defaultMessage: 'Security question',
  },
  secretQuestionTypePlaceholder: {
    id: 'Signup_LabelSecretQuestionType_Placeholder',
    defaultMessage: 'Select a security question...',
  },
  secretQuestionAnswer: {
    id: 'Signup_LabelSecretQuestionAnswer',
    defaultMessage: 'Security question answer',
  },
  // START MMPROD-6582 DHRCHAD - V8Upgrade - Text change on signup page
  termsAndConditions: {
    id: 'Signup_LabelTermsAndConditions',
    defaultMessage:
      'Please check the box to let us know that you have read and agreed to the usage conditions.',
  },
  termsAndConditionsLink: {
    id: 'Signup_LabelTermsAndConditionsLink',
    defaultMessage: 'Click here to read the user agreement.',
  },
  // END MMPROD-6582 DHRCHAD - V8Upgrade - Text change on signup page
  submitButton: {
    id: 'Signup_LabelSubmitButton',
    // dhtkond start MMPROD-8077 changing it to sentence case format
    defaultMessage: 'Sign up',
    // dhtkond end MMPROD-8077 changing it to sentence case format
  },
  alreadyHaveAnAccount: {
    id: 'Signup_LabelAlreadyHaveAnAccount',
    defaultMessage: 'Already have an account? {link}',
  },
  firstNameMissing: {
    id: 'Signup_LabelFirstName_Missing',
    defaultMessage: 'First Name must be entered.',
  },
  firstNameTooLong: {
    id: 'Signup_LabelFirstName_TooLong',
    // START MMPROD-12489 DHRCHAD
    defaultMessage: 'The first name can be at most 25 characters.',
    // END MMPROD-12489 DHRCHAD
  },
  surnameMissing: {
    id: 'Signup_LabelSurname_Missing',
    defaultMessage: 'Last Name must be entered.',
  },
  surnameTooLong: {
    id: 'Signup_LabelSurname_TooLong',
    // START MMPROD-12489 DHRCHAD
    defaultMessage: 'The last name can be at most 30 characters.',
    // END MMPROD-12489 DHRCHAD
  },
  // START MMPROD-8438 DHRCHAD Modified validation text to not show entered email address
  emailAddressInvalidNoAtSymbol: {
    id: 'Signup_LabelEmailAddress_InvalidNoAtSymbol',
    defaultMessage:
      'The entered email address is not a valid email address. Valid email addresses must contain the @ character.',
  },
  // END MMPROD-8438 DHRCHAD Modified validation text to not show entered email address
  emailAddressTooShort: {
    id: 'Signup_LabelEmailAddress_TooShort',
    defaultMessage: 'Email must be at least 6 characters.',
  },
  usernameMissing: {
    id: 'Signup_LabelUsername_Missing',
    defaultMessage: 'Username must be entered.',
  },
  // START MMPROD-12651 dhdrama modified the validation message as per the design
  usernameInvalidTooShort: {
    id: 'Signup_LabelUsername_InvalidTooShort',
    defaultMessage: 'The user name must be at least {usernameMinLength} characters.',
  },
  usernameInvalidTooLong: {
    id: 'Signup_LabelUsername_InvalidTooLong',
    defaultMessage: 'The user name can be at most {usernameMaxLength} characters.',
  },
  // END MMPROD-12651
  passwordMissing: {
    id: 'Signup_LabelPassword_Missing',
    defaultMessage: 'Password must be entered.',
  },
  // START : MMPROD-10362 : dhdkuma : V8Upgrade : Fixed validation messages as per USD
  passwordInvalidTooShort: {
    id: 'Signup_LabelPassword_InvalidTooShort',
    defaultMessage: 'The password must be at least {passwordMinLength} characters.',
  },
  passwordInvalidTooLong: {
    id: 'Signup_LabelPassword_InvalidTooLong',
    defaultMessage: 'The password can be at most {passwordMaxLength} characters.',
  },
  passwordInvalidNoNumbersOrSpecialCharacters: {
    id: 'Signup_LabelPassword_InvalidNoNumbersOrSpecialCharacters',
    // START MMPROD-3360 dhakell - customizing password special character requirement
    defaultMessage: 'The password must contain at least 3 number(s) and/or special characters.',
    // END MMPROD-3360 dhakell
  },
  secretQuestionTypeMissing: {
    id: 'Signup_LabelSecretQuestionType_Missing',
    defaultMessage: 'Security question is mandatory. Please select a security question.',
  },
  // END : MMPROD-10362 : dhdkuma : V8Upgrade
  secretQuestionAnswerMissing: {
    id: 'Signup_LabelSecretQuestionAnswer_Missing',
    defaultMessage: 'You must provide an answer for the selected security question.',
  },
  termsAndConditionsAcceptedMissing: {
    id: 'Signup_LabelTermsAndConditionsAccepted_Missing',
    // START MMPROD-12489 DHRCHAD
    defaultMessage: 'You must accept the terms and conditions.',
    // END MMPROD-12489 DHRCHAD
  },
  secretQuestion1: {
    id: 'Signup_LabelSecretQuestion_1',
    defaultMessage: "Mother's maiden name",
  },
  ariaLabelPanelDescription: {
    id: 'Signup_AriaLabelPanelDescription',
    defaultMessage: 'Panel Description',
  },
  loginLinkLabel: {
    id: 'Signup_LoginLinkLabel',
    defaultMessage: 'Login',
  },
  showPasswordText: {
    id: 'Signup_ShowPasswordText',
    defaultMessage: 'Show password',
  },
  hidePasswordText: {
    id: 'Signup_HidePasswordText',
    defaultMessage: 'Hide password',
  },
  usernameAlreadyInUseErrorText: {
    id: 'Signup_UsernameAlreadyInUseErrorText',
    defaultMessage:
      'The username entered has already been assigned to another User. Please enter a different name.',
  },
  homeButton: {
    id: 'Login_HomeButton',
    defaultMessage: 'Home',
  },
  mandatoryFieldsAlertText: {
    id: 'Signup_MandatoryFieldsAlertText',
    defaultMessage: 'Complete all fields',
  },
  signupSuccessToastText: {
    defaultMessage: 'You have successfully signed up',
    id: 'SignupSuccessToast_text',
  },
});
