/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the UAProgramPdfForm
 *
 * @class UAProgramPdfForm
 */
class UAProgramPdfForm {
  /**
   * Creates an instance of UAProgramPdfForm.
   * @param {*} json
   * @memberof UAProgramPdfForm
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pdfFormId = json.pdfFormId;
    }
  }
}

/**
 * Models the UAProgram
 *
 * @class UAProgram
 */
class UAProgram {
  /**
   * Creates an instance of UAProgram.
   * @param {*} json
   * @memberof UAProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.associatedPendingApplicationId = json.associatedPendingApplicationId;

      // string
      this.icon = json.icon;

      // array - UAProgramPdfForm
      if (json.pdfFormIds) {
        this.pdfFormIds = json.pdfFormIds.map(object => new UAProgramPdfForm(object));
      } else if (json.constructor === Array) {
        this.pdfFormIds = json.map(object => new UAProgramPdfForm(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAWithdrawalReason
 *
 * @class UAWithdrawalReason
 */
class UAWithdrawalReason {
  /**
   * Creates an instance of UAWithdrawalReason.
   * @param {*} json
   * @memberof UAWithdrawalReason
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }
    }
  }
}

/**
 * Models the UAIntakeApplicationType
 *
 * @class UAIntakeApplicationType
 */
class UAIntakeApplicationType {
  /**
   * Creates an instance of UAIntakeApplicationType.
   * @param {*} json
   * @memberof UAIntakeApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.iegScriptId = json.iegScriptId;

      // boolean
      this.individualProgramSelectionAllowed = json.individualProgramSelectionAllowed;

      // string
      this.iegSubmissionScriptId = json.iegSubmissionScriptId;

      // string
      this.datastoreSchema = json.datastoreSchema;

      // string
      this.name = json.name;

      // string
      this.summary = json.summary;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.pdfFormId = json.pdfFormId;

      // string
      this.associatedDraftApplicationId = json.associatedDraftApplicationId;

      // string
      this.url = json.url;

      // boolean
      this.submitOnCompletionInd = json.submitOnCompletionInd;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }

      // array - UAWithdrawalReason
      if (json.allowedWithdrawalReasons) {
        this.allowedWithdrawalReasons = json.allowedWithdrawalReasons.map(
          object => new UAWithdrawalReason(object)
        );
      } else if (json.constructor === Array) {
        this.allowedWithdrawalReasons = json.map(object => new UAWithdrawalReason(object));
      }
    }
  }
}

/**
 * Models the UAScreeningType
 *
 * @class UAScreeningType
 */
class UAScreeningType {
  /**
   * Creates an instance of UAScreeningType.
   * @param {*} json
   * @memberof UAScreeningType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.summary = json.summary;

      // string
      this.url = json.url;

      // string
      this.howToApply = json.howToApply;

      // string
      this.amIEligibile = json.amIEligibile;

      // string
      this.iegFilterScreeningSchemaName = json.iegFilterScreeningSchemaName;

      // string
      this.iegFilterScreeningScriptId = json.iegFilterScreeningScriptId;

      // string
      this.iegScreeningSchemaName = json.iegScreeningSchemaName;

      // string
      this.iegScreeningScriptId = json.iegScreeningScriptId;

      // boolean
      this.programSelectionInd = json.programSelectionInd;

      // boolean
      this.rescreenInd = json.rescreenInd;

      // string
      this.screeningTypeId = json.screeningTypeId;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }
    }
  }
}

/**
 * Models the UAOnlineCategory
 *
 * @class UAOnlineCategory
 */
class UAOnlineCategory {
  /**
   * Creates an instance of UAOnlineCategory.
   * @param {*} json
   * @memberof UAOnlineCategory
   */
  constructor(json = null) {
    if (json) {
      // string
      this.online_category_id = json.online_category_id;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.url = json.url;

      // array - UAIntakeApplicationType
      if (json.intakeApplicationTypes) {
        this.intakeApplicationTypes = json.intakeApplicationTypes.map(
          object => new UAIntakeApplicationType(object)
        );
      } else if (json.constructor === Array) {
        this.intakeApplicationTypes = json.map(object => new UAIntakeApplicationType(object));
      }

      // array - UAScreeningType
      if (json.onlineScreeningTypes) {
        this.onlineScreeningTypes = json.onlineScreeningTypes.map(
          object => new UAScreeningType(object)
        );
      } else if (json.constructor === Array) {
        this.onlineScreeningTypes = json.map(object => new UAScreeningType(object));
      }
    }
  }
}

/**
 * Models the UAOnlineCategoryList
 *
 * @class UAOnlineCategoryList
 */
class UAOnlineCategoryList {
  /**
   * Creates an instance of UAOnlineCategoryList.
   * @param {*} json
   * @memberof UAOnlineCategoryList
   */
  constructor(json = null) {
    if (json) {
      // array - UAOnlineCategory
      if (json.data) {
        this.data = json.data.map(object => new UAOnlineCategory(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UAOnlineCategory(object));
      }
    }
  }
}

/**
 * Models the NewUAOnlineApplicationProgram
 *
 * @class NewUAOnlineApplicationProgram
 */
class NewUAOnlineApplicationProgram {
  /**
   * Creates an instance of NewUAOnlineApplicationProgram.
   * @param {*} json
   * @memberof NewUAOnlineApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;
    }
  }
}

/**
 * Models the NewUAOnlineApplicationType
 *
 * @class NewUAOnlineApplicationType
 */
class NewUAOnlineApplicationType {
  /**
   * Creates an instance of NewUAOnlineApplicationType.
   * @param {*} json
   * @memberof NewUAOnlineApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.screening_form_id = json.screening_form_id;

      // array - NewUAOnlineApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new NewUAOnlineApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new NewUAOnlineApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the NewUAApplicationFormIdentifierResponse
 *
 * @class NewUAApplicationFormIdentifierResponse
 */
class NewUAApplicationFormIdentifierResponse {
  /**
   * Creates an instance of NewUAApplicationFormIdentifierResponse.
   * @param {*} json
   * @memberof NewUAApplicationFormIdentifierResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.isPartialApplication = json.isPartialApplication;
    }
  }
}

export default {
  UAProgramPdfForm,

  UAProgram,

  CodeTableType,

  UAWithdrawalReason,

  UAIntakeApplicationType,

  UAScreeningType,

  UAOnlineCategory,

  UAOnlineCategoryList,

  NewUAOnlineApplicationProgram,

  NewUAOnlineApplicationType,

  NewUAApplicationFormIdentifierResponse,
};
