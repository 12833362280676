/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'MMModule' store object.
 * @namespace MMModuleSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAMMModule';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMotivationType = state =>
    state[selectors.moduleIdentifier].fetchMotivationType.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMotivationType = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMotivationType.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMotivationTypeError = state =>
    state[selectors.moduleIdentifier].fetchMotivationTypeError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMotivationTypeIegByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getMotivationTypeIegByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingGetMotivationTypeIegByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetMotivationTypeIegByIegExecutionID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMotivationTypeIegByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getMotivationTypeIegByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetupAndStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationSetupAndStartError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationStart = state =>
    state[selectors.moduleIdentifier].postMotivationStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMotivationStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationStartError = state =>
    state[selectors.moduleIdentifier].postMotivationStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationSetup = state =>
    state[selectors.moduleIdentifier].postMotivationSetup.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMotivationSetup = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetup.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationSetupError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMotivations = state => state[selectors.moduleIdentifier].fetchMotivations.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMotivations = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMotivations.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMotivationsError = state =>
    state[selectors.moduleIdentifier].fetchMotivationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationResume = state =>
    state[selectors.moduleIdentifier].postMotivationResume.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMotivationResume = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationResume.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMotivationResumeError = state =>
    state[selectors.moduleIdentifier].postMotivationResumeError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMotivationIegByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getMotivationIegByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingGetMotivationIegByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetMotivationIegByIegExecutionID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMotivationIegByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getMotivationIegByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmMotivationChangeOwnership = state =>
    state[selectors.moduleIdentifier].postMmMotivationChangeOwnership.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmMotivationChangeOwnership = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmMotivationChangeOwnership.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmMotivationChangeOwnershipError = state =>
    state[selectors.moduleIdentifier].postMmMotivationChangeOwnershipError.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostApplicationForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostApplicationForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static createApplicationFormError = state =>
    state[selectors.moduleIdentifier].createApplicationFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postHcrMotivationSignandsubmit = state =>
    state[selectors.moduleIdentifier].postHcrMotivationSignandsubmit.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostHcrMotivationSignandsubmit = state =>
    state[selectors.moduleIdentifier].isFetchingPostHcrMotivationSignandsubmit.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postHcrMotivationSignandsubmitError = state =>
    state[selectors.moduleIdentifier].postHcrMotivationSignandsubmitError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static deleteMmMotivationByMotivationId = state =>
    state[selectors.moduleIdentifier].deleteMmMotivationByMotivationId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingDeleteMmMotivationByMotivationId = state =>
    state[selectors.moduleIdentifier].isFetchingDeleteMmMotivationByMotivationId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static deleteMmMotivationByMotivationIdError = state =>
    state[selectors.moduleIdentifier].deleteMmMotivationByMotivationIdError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplicationForm = state =>
    state[selectors.moduleIdentifier].postMmApplicationForm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmApplicationForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmApplicationForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplicationFormError = state =>
    state[selectors.moduleIdentifier].postMmApplicationFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmVerifications = state =>
    state[selectors.moduleIdentifier].fetchMmVerifications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMmVerifications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmVerifications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmVerificationsError = state =>
    state[selectors.moduleIdentifier].fetchMmVerificationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postDbsMmFiles = state => state[selectors.moduleIdentifier].postDbsMmFiles.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostDbsMmFiles = state =>
    state[selectors.moduleIdentifier].isFetchingPostDbsMmFiles.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postDbsMmFilesError = state =>
    state[selectors.moduleIdentifier].postDbsMmFilesError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmResetpassword = state =>
    state[selectors.moduleIdentifier].postMmResetpassword.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmResetpassword = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmResetpassword.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmResetpasswordError = state =>
    state[selectors.moduleIdentifier].postMmResetpasswordError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmHcrToCgis = state => state[selectors.moduleIdentifier].postMmHcrToCgis.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmHcrToCgis = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmHcrToCgis.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmHcrToCgisError = state =>
    state[selectors.moduleIdentifier].postMmHcrToCgisError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmLaunchIntakeMotivation = state =>
    state[selectors.moduleIdentifier].postMmLaunchIntakeMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmLaunchIntakeMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmLaunchIntakeMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmLaunchIntakeMotivationError = state =>
    state[selectors.moduleIdentifier].postMmLaunchIntakeMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplyingForNonMagi = state =>
    state[selectors.moduleIdentifier].postMmApplyingForNonMagi.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmApplyingForNonMagi = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmApplyingForNonMagi.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplyingForNonMagiError = state =>
    state[selectors.moduleIdentifier].postMmApplyingForNonMagiError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmVerificationsCreateCaseworkerTask = state =>
    state[selectors.moduleIdentifier].postMmVerificationsCreateCaseworkerTask.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmVerificationsCreateCaseworkerTask = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmVerificationsCreateCaseworkerTask.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmVerificationsCreateCaseworkerTaskError = state =>
    state[selectors.moduleIdentifier].postMmVerificationsCreateCaseworkerTaskError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmIsMotivationScript = state =>
    state[selectors.moduleIdentifier].postMmIsMotivationScript.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmIsMotivationScript = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmIsMotivationScript.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmIsMotivationScriptError = state =>
    state[selectors.moduleIdentifier].postMmIsMotivationScriptError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmDraftApplications = state =>
    state[selectors.moduleIdentifier].fetchMmDraftApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMmDraftApplications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmDraftApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmDraftApplicationsError = state =>
    state[selectors.moduleIdentifier].fetchMmDraftApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmSystemConfigurations = state =>
    state[selectors.moduleIdentifier].fetchMmSystemConfigurations.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMmSystemConfigurations = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmSystemConfigurations.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmSystemConfigurationsError = state =>
    state[selectors.moduleIdentifier].fetchMmSystemConfigurationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmValidateSession = state =>
    state[selectors.moduleIdentifier].fetchMmValidateSession.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMmValidateSession = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmValidateSession.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmValidateSessionError = state =>
    state[selectors.moduleIdentifier].fetchMmValidateSessionError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmVerificationsLinkFile = state =>
    state[selectors.moduleIdentifier].postMmVerificationsLinkFile.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmVerificationsLinkFile = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmVerificationsLinkFile.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmVerificationsLinkFileError = state =>
    state[selectors.moduleIdentifier].postMmVerificationsLinkFileError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMmVerificationsByVerificationId = state =>
    state[selectors.moduleIdentifier].getMmVerificationsByVerificationId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingGetMmVerificationsByVerificationId = state =>
    state[selectors.moduleIdentifier].isFetchingGetMmVerificationsByVerificationId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMmVerificationsByVerificationIdError = state =>
    state[selectors.moduleIdentifier].getMmVerificationsByVerificationIdError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmSubmittedApplications = state =>
    state[selectors.moduleIdentifier].fetchMmSubmittedApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingFetchMmSubmittedApplications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMmSubmittedApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static fetchMmSubmittedApplicationsError = state =>
    state[selectors.moduleIdentifier].fetchMmSubmittedApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplications = state => state[selectors.moduleIdentifier].postMmApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingPostMmApplications = state =>
    state[selectors.moduleIdentifier].isFetchingPostMmApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static postMmApplicationsError = state =>
    state[selectors.moduleIdentifier].postMmApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMmFormDetailsByApplicationFormId = state =>
    state[selectors.moduleIdentifier].getMmFormDetailsByApplicationFormId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static isFetchingGetMmFormDetailsByApplicationFormId = state =>
    state[selectors.moduleIdentifier].isFetchingGetMmFormDetailsByApplicationFormId.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static getMmFormDetailsByApplicationFormIdError = state =>
    state[selectors.moduleIdentifier].getMmFormDetailsByApplicationFormIdError.payload;

  /**
   * Selects the data from the store
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof MMModuleSelectors
   */
  static selectedApplicationType = state =>
    state[selectors.moduleIdentifier].selectedApplicationType.payload;
}
