/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ariaSection: {
    id: 'Login_AriaSection',
    defaultMessage: 'Log in Section',
  },
  title: {
    id: 'Login_LabelTitle',
    defaultMessage: 'Log in',
  },
  subtitle: {
    id: 'Login_LabelSubtitle',
    defaultMessage: 'To save your progress log in below',
  },
  description: {
    id: 'Login_Description',
    /* START MMPROD-4910 dhakell - Login screen updates */
    defaultMessage:
      'Log in to see your pending applications, resume an application, submit changes, or view notices.',
    /* END MMPROD-4910 dhakell - Login screen updates */
  },
  homeButton: {
    id: 'Login_HomeButton',
    defaultMessage: 'Home',
  },
  username: {
    id: 'Login_LabelUsername',
    /* START MMPROD-4910 dhakell - Login screen updates */
    defaultMessage: 'User name',
    /* END MMPROD-4910 dhakell - Login screen updates */
  },
  password: {
    id: 'Login_LabelPassword',
    defaultMessage: 'Password',
  },
  showPassword: {
    id: 'Login_LabelShowPassword',
    defaultMessage: 'Show password',
  },
  submitButton: {
    id: 'Login_LabelSubmitButton',
    defaultMessage: 'Log in',
  },
  noAccountMessage: {
    id: 'Login_LabelNoAccountMessage',
    defaultMessage: "Don't have an account? {link}",
  },
  noAccountMessageLink: {
    id: 'Login_LabelNoAccountMessageLink',
    defaultMessage: 'Sign up',
  },
  anonSubmit: {
    id: 'Login_LabelAnonSubmit',
    // MMPROD-8216 start dhtkond renaming the button as per USD
    defaultMessage: 'Submit application without account',
    // MMPROD-8216 end dhtkond renaming the button as per USD
  },
  loginFailedMessage: {
    id: 'Login_LabelLoginFailedMessage',
    /* START MMPROD-13167 dhakell - Login screen updates */
    defaultMessage:
      'Sorry, we were unable to log you in with the information provided. Please try again or create a new account.',
    /* END MMPROD-13167 dhakell - Login screen updates */
  },
  timeout: {
    id: 'Login_LabelTimeout',
    defaultMessage:
      'You were automatically logged out to keep your information secure. Log in to go back to where you left off.',
  },
  passwordChanged: {
    id: 'Login_LabelPasswordChanged',
    defaultMessage: 'Password successfully updated. You can now log in using your new password.',
  },
  signUpButtonLabel: {
    id: 'Login_LabelSignUp',
    defaultMessage: 'Sign Up',
  },
  ariaLabelPanelDescription: {
    id: 'Login_AriaLabelPanelDescription',
    defaultMessage: 'Panel Description',
  },
  altLabelAgencyLogo: {
    id: 'Login_AltLabelAgencyLogo',
    defaultMessage: 'Agency Logo',
  },
  showPasswordText: {
    id: 'Login_ShowPasswordText',
    defaultMessage: 'Show password',
  },
  hidePasswordText: {
    id: 'Login_HidePasswordText',
    defaultMessage: 'Hide password',
  },
  loginSuccessToastText: {
    defaultMessage: "You're now logged in",
    id: 'Signup_Success_Toast_text',
  },
  /* START MMPROD-4910 dhakell - Login screen updates */
  loginSupportText: {
    defaultMessage: 'Login support',
    id: 'Login_Support_text',
  },
  loginSupportDescription: {
    // START MMPROD-13167: changing message text
    defaultMessage:
      "Forgot your username or password? No problem. You can create a new account to submit your annual review or apply for Medicaid benefits by clicking the 'Sign up' link above.",
    // END MMPROD-13167: changing message text
    id: 'Login_Support_description',
  },
  usernameBlankMessage: {
    defaultMessage: 'User Name must be entered.',
    id: 'Username_Blank_message',
  },
  passwordBlankMessage: {
    defaultMessage: 'Password must be entered.',
    id: 'Password_Blank_message',
  },
  /* END MMPROD-4910 dhakell - Login screen updates */
});
