/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMLifeEvents.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MM_LIFE_EVENTS_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMLIFEEVENTS/POST_MM_LIFE_EVENTS_FORM`
);

const POST_MM_LIFE_EVENTS_FORM_SUBMIT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `MMLIFEEVENTS/POST_MM_LIFE_EVENTS_FORM_SUBMIT`
);

export { POST_MM_LIFE_EVENTS_FORM, POST_MM_LIFE_EVENTS_FORM_SUBMIT };
