import _JSON$stringify from '@babel/runtime-corejs3/core-js-stable/json/stringify';
import _Promise from '@babel/runtime-corejs3/core-js-stable/promise';
import _regeneratorRuntime from '@babel/runtime-corejs3/regenerator';
import _asyncToGenerator from '@babel/runtime-corejs3/helpers/esm/asyncToGenerator';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService } from '@spm/core';
import { CoreReduxStore } from '@spm/core';
import { resources } from '@spm/core/lib/api/resources';
import { sessionStorage } from '@spm/core';
import { EnvUtils } from '@spm/core';
import { UserActions } from '@spm/core';
import { SSOVerifierActions } from '@spm/core';
import AuthenticationActions from './actions';
import AuthenticationSelectors from './selectors';
/* eslint-disable import/no-cycle */

import Authentication from './Authentication';
import AuthenticationTypes from './AuthenticationTypes';
import MMProfileActions from '../generated/MMProfile/actions';
import MMICAMActions from '../generated/MMICAM/actions';

/**
 * The IDP SAML Logininitial URL
 *
 * @memberof SSOAuthentication
 *
 * @private
 */

const getIdpSAMLLogininitialUrl = function getIdpSAMLLogininitialUrl() {
  return EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_IDP_LOGININITIAL_URL');
};
/**
 * The IDP UserLogin Url
 *
 * @memberof SSOAuthentication
 *
 * @private
 */

const getIdpUserLoginUrl = function getIdpUserLoginUrl() {
  return EnvUtils.getEnvironmentProperty('REACT_APP_SAMLSSO_USERLOGIN_URL');
};
/**
 * Utility class that exposes SSO-specific functions used for SSO prechecks and SSO authentication processes
 */

const SSOAuthentication = /* #__PURE__ */ _createClass(function SSOAuthentication() {
  _classCallCheck(this, SSOAuthentication);
});

_defineProperty(
  SSOAuthentication,
  'login',
  /* #__PURE__ */ (function () {
    const _ref = _asyncToGenerator(
      /* #__PURE__ */ _regeneratorRuntime.mark(function _callee(input, ssoType) {
        return _regeneratorRuntime.wrap(_context => {
          while (1) {
            switch ((_context.prev = _context.next)) {
              case 0:
                _context.next = 2;
                return input.ssoLogin
                  ? SSOAuthentication.ssoLogin(input, ssoType)
                  : AuthenticationTypes.JAASAuthentication.login(input);

              case 2:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee);
      })
    );

    return function (_x, _x2) {
      return Reflect.apply(_ref, this, arguments);
    };
  })()
);

_defineProperty(
  SSOAuthentication,
  'ssoLogin',
  /* #__PURE__ */ (function () {
    const _ref2 = _asyncToGenerator(
      /* #__PURE__ */ _regeneratorRuntime.mark(function _callee2(input) {
        let username;
        let password;
        let loginCallback;
        let idpLoginResponse;
        let isSSOLoginSuccessful;
        let idpSamlResponseData;
        let samlResponseKey;
        let samlRelayState;
        let acsURLResponse;
        let getUserAccountResponse;
        return _regeneratorRuntime.wrap(_context2 => {
          while (1) {
            switch ((_context2.prev = _context2.next)) {
              case 0:
                // SSOVerifier has already logged the user in if they were pre-authenticated.
                // If we are executing this flow, we are not logged-in to the IdP.
                (username = input.username),
                  (password = input.password),
                  (loginCallback = input.callback);
                _context2.next = 3;
                return SSOAuthentication.callIdPUserLogin({
                  username,
                  password,
                });

              case 3:
                idpLoginResponse = _context2.sent;
                isSSOLoginSuccessful = false;

                if (!(idpLoginResponse.success && !!idpLoginResponse.response)) {
                  _context2.next = 23;
                  break;
                }

                idpSamlResponseData = SSOAuthentication.parseSAMLResponsePayload(
                  idpLoginResponse.response
                );

                if (!idpSamlResponseData.samlResponseKey) {
                  _context2.next = 20;
                  break;
                }

                (samlResponseKey = idpSamlResponseData.samlResponseKey),
                  (samlRelayState = idpSamlResponseData.samlRelayState);
                _context2.next = 11;
                return SSOAuthentication.callACSWithSAMLResponse({
                  samlResponseKey,
                  samlRelayState,
                });

              case 11:
                acsURLResponse = _context2.sent;

                if (!acsURLResponse.success) {
                  _context2.next = 20;
                  break;
                }

                _context2.next = 15;
                return SSOAuthentication.getUserAccount();

              case 15:
                getUserAccountResponse = _context2.sent;
                _context2.next = 18;
                return SSOAuthentication.loadUserAccount(getUserAccountResponse.response, username);

              case 18:
                isSSOLoginSuccessful = true;
                loginCallback(true, getUserAccountResponse.response);

              case 20:
                if (!isSSOLoginSuccessful) {
                  loginCallback(false);
                }

                _context2.next = 25;
                break;

              case 23:
                SSOAuthentication.clearCookiesAndSessionStorage();
                loginCallback(false);

              case 25:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2);
      })
    );

    return function (_x3) {
      return Reflect.apply(_ref2, this, arguments);
    };
  })()
);


const fetchMmSendLogoutRequestDetails = (dispatch, callback) => {
  // START MMPROD-16133
  sessionStorage.removeItem('accountLinkFinished');
  sessionStorage.removeItem('accountLinkRedirection');
  sessionStorage.removeItem('sessionID');
  // END MMPROD-16133

  MMICAMActions.fetchMmSendLogoutRequest(
    dispatch, 
    // START MMPROD-16126 - Concurrent session
    sessionStorage.getItem('sessionID'),
    // END MMPROD-16126 - Concurrent session
    callback
  );
};

_defineProperty(
  SSOAuthentication,
  'logout',
  /* #__PURE__ */ _asyncToGenerator(
    /* #__PURE__ */ _regeneratorRuntime.mark(function _callee3() {
      let callback;
      let reportLogoutError;
      let logoutUrl;
      let getIDPSSOLogoutUrlResponse;
      const _args3 = arguments;
      return _regeneratorRuntime.wrap(_context3 => {
        while (1) {
          switch ((_context3.prev = _context3.next)) {
            case 0:
              callback = _args3.length > 0 && _args3[0] !== undefined ? _args3[0] : function () {};
              reportLogoutError = _args3.length > 1 && _args3[1] !== undefined ? _args3[1] : true;

              if (
                Authentication.userTypeIs([
                  Authentication.USER_TYPES.STANDARD,
                  Authentication.USER_TYPES.LINKED,
                ])
              ) {
                _context3.next = 7;
                break;
              }

              _context3.next = 5;
              return AuthenticationTypes.JAASAuthentication.logout();

            case 5:
              _context3.next = 20;
              break;

            case 7:
              console.log('SSOAuthentication.logout - ID.me logout Request......');
              
              fetchMmSendLogoutRequestDetails(CoreReduxStore.internalStore.dispatch, (success, response) => {
                if (success) {
                  console.log('response from send logout request API');
                  console.log(JSON.stringify(response));
                }
              });

              console.log('logout user, clear cookies and session storage. ');
              SSOAuthentication.clearCookiesAndSessionStorage();
              CoreReduxStore.internalStore.dispatch(AuthenticationActions.logout());

              // logoutUrl = Authentication.getIDPSSOLogoutUrl();
              _context3.next = 20;
              // return Authentication.getCall(logoutUrl);
              break;
            case 12:
              getIDPSSOLogoutUrlResponse = _context3.sent;

              if (!getIDPSSOLogoutUrlResponse.success) {
                _context3.next = 18;
                break;
              }

              // clear the cookies
              Authentication.clearCookiesFromStorage(); // trigger the sso verifier

              SSOVerifierActions.setSSOVerifier(CoreReduxStore.internalStore.dispatch, true);
              _context3.next = 20;
              break;

            case 18:
              if (!(!getIDPSSOLogoutUrlResponse.success && reportLogoutError)) {
                _context3.next = 20;
                break;
              }

              throw new Error(getIDPSSOLogoutUrlResponse.response);

            case 20:
              if (callback) {
                callback();
              }

            case 21:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3);
    })
  )
);

_defineProperty(
  SSOAuthentication,
  'callProtectedResource',
  () =>
    // Using RESTService.get as-is in SSOAuth mode, different results happen. Regardless of the success, you would receive the response object (plainly) back. In errors that are specific to websphere 401's, the caller receives the response object plainly because the response contains a SAMLRequest token which needs to be parsed (the hand-off between the response and the parser is called by SSOVerifier) to get the SAML Request token. In non-SSO mode, a subset of the response is returned, like the error text if there was an error or the response.body if it was a success (providing the body isn't null in which case it attaches the response to a response property (e.g. responseData.response))
    // We call just the Rest endpoint (/Rest) because other more specific endpoints will be unauthorized when CSRF protection is enabled which blocks the user when pre-authenticated with an IdP in the SP-init flow.
    new _Promise(resolve => {
      // special version of get that returns the entire response object
      RESTService.get(
        process.env.REACT_APP_REST_URL,
        (success, response) => {
          resolve({
            success,
            response,
          });
        },
        {},
        true
      );
    })
);

_defineProperty(SSOAuthentication, 'callIdPLoginInitial', () =>
  Authentication.getCall(getIdpSAMLLogininitialUrl(), {}, true)
);

_defineProperty(SSOAuthentication, 'parseSAMLRequestPayload', response => {
  const idpSamlRequestData = {
    samlRequestKey: null,
    samlRelayState: null,
  };

  if (response) {
    const samlParser = Authentication.getSAMLParser(response); // these return null if not present on the response

    idpSamlRequestData.samlRequestKey = samlParser.getSAMLRequestKey();
    idpSamlRequestData.samlRelayState = samlParser.getRelayState();
  }

  return idpSamlRequestData;
});

_defineProperty(SSOAuthentication, 'clearCookiesAndSessionStorage', () => {
  Authentication.clearSessionStorage();
  Authentication.clearCookiesFromStorage();
});

_defineProperty(SSOAuthentication, 'callIdpSAMLLoginWithSAMLRequest', _ref4 => {
  const { samlRequestKey } = _ref4;
  let { samlRelayState } = _ref4;
  samlRelayState = 'https://hhs-mem-q-was02.clemson.edu:1104/CitizenPortal/';
  const idpSamlRequestData = {
    RelayState: samlRelayState,
    SAMLRequest: samlRequestKey,
  }; // Similar to GET, POST has an ssoAuth mode to influence the invokeCallbackAfterResponse callback to return the entire response without edit. This needs to be abstracted out

  return new _Promise(resolve => {
    RESTService.post(
      Authentication.getSPSSOUrl(),
      idpSamlRequestData,
      (success, response) => {
        resolve({
          success,
          response,
        });
      },
      'form',
      true
    );
  });
});

_defineProperty(SSOAuthentication, 'setSSOToken', samlToken => {
  CoreReduxStore.internalStore.dispatch(AuthenticationActions.setSSOToken(samlToken));
});

_defineProperty(SSOAuthentication, 'getSSOToken', function () {
  const state =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : CoreReduxStore.internalStore.getState();
  return AuthenticationSelectors.getSSOToken(state);
});

_defineProperty(SSOAuthentication, 'parseSAMLResponsePayload', response => {
  const idpSamlResponseData = {
    samlResponseKey: null,
    samlRelayState: null,
  }; // The response from the IdP will be HTML but will be two different versions depending on whether the user is already logged into the IdP.
  // If the user is logged in, the request will return a response with a HTML form containing the RelayState and SAMLResponse key in input fields.
  // If the user is not logged in, they are redirected from the IDP_SSOLOGIN_URL to another URL endpoint (e.g. /isam/sps/auth for ISAM) which returns a HTML form containing a login page (similar to ISAM pkmslogin.form). This form can contain a hidden input containing an SSO token. We store this value in Redux because if it's required we will need to provide it at login later.

  if (response) {
    const samlParser = Authentication.getSAMLParser(response); // these return null if not present on the response

    idpSamlResponseData.samlResponseKey = samlParser.getSAMLResponseKey();
    idpSamlResponseData.samlRelayState = samlParser.getRelayState();
    const samlTokenValue = samlParser.getToken();

    if (samlTokenValue != null) {
      SSOAuthentication.setSSOToken(samlTokenValue);
    }
  }

  return idpSamlResponseData;
});

_defineProperty(SSOAuthentication, 'callACSWithSAMLResponse', _ref5 => {
  const { samlResponseKey } = _ref5;
  const { samlRelayState } = _ref5;
  const acsData = {
    SAMLResponse: samlResponseKey,
  };

  if (samlRelayState) {
    acsData.RelayState = samlRelayState;
  }

  return Authentication.postCall(Authentication.getACSUrl(), acsData, 'form', true);
});

_defineProperty(SSOAuthentication, 'getUserAccount', () =>
  Authentication.getCall(Authentication.getApiUrl(resources.userAccountLogin), {})
);

_defineProperty(SSOAuthentication, 'loadUserAccount', (userAccountData, username) => {
  sessionStorage.setItem('username', username);
  sessionStorage.setItem('user_account', _JSON$stringify(userAccountData));
  CoreReduxStore.internalStore.dispatch(AuthenticationActions.setLoggedInUser(username));
  CoreReduxStore.internalStore.dispatch(AuthenticationActions.setUserAccount(userAccountData));

  if (
    Authentication.userTypeIs([
      Authentication.USER_TYPES.STANDARD,
      Authentication.USER_TYPES.LINKED,
    ])
  ) {
    // START MMPROD-6213 dhrchad - Custom REST API to read profile information
    MMProfileActions.fetchMmProfile(CoreReduxStore.internalStore.dispatch);
    // END MMPROD-6213 dhrchad - Custom REST API to read profile information
    UserActions.fetchUser(CoreReduxStore.internalStore.dispatch);
  }
});

_defineProperty(SSOAuthentication, 'callIdPUserLogin', _ref6 => {
  const { username } = _ref6;
  const { password } = _ref6;
  const loginData = {
    username,
    password,
  };
  const ssoToken = SSOAuthentication.getSSOToken();

  if (ssoToken != null) {
    loginData.token = ssoToken;
  }

  loginData['login-form-type'] = 'pwd';
  return Authentication.postCall(getIdpUserLoginUrl(), loginData, 'form', true);
});

export { SSOAuthentication as default };
