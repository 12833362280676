import _JSON$stringify from '@babel/runtime-corejs3/core-js-stable/json/stringify';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { sessionStorage } from '@spm/core';
import { CoreReduxStore } from '@spm/core';
import { UserActions } from '@spm/core';
import AuthenticationActions from './actions';
/* eslint-disable-next-line import/no-cycle */

import Authentication from './Authentication';
import MMProfileActions from '../generated/MMProfile/actions';
/** Component that will be used in order to login in development mode */

const DevAuthentication = /* #__PURE__ */ _createClass(function DevAuthentication() {
  _classCallCheck(this, DevAuthentication);
});

_defineProperty(DevAuthentication, 'login', input => {
  DevAuthentication.doSimpleDevLogin(input.username, input.callback);
});

_defineProperty(DevAuthentication, 'doSimpleDevLogin', (username, callback) => {
  if (username === 'dev' || username === 'dev@mock' || username === 'publiccitizen') {
    sessionStorage.setItem('username', username);
    let userType = process.env.REACT_APP_SIMPLE_AUTH_USER_TYPE;

    if (username === 'publiccitizen') {
      userType = 'PUBLIC';
    }

    const userAccount = {
      lastLoggedInDateTime: new Date(),
      userType: userType || 'LINKED',
    };
    sessionStorage.setItem('user_account', _JSON$stringify(userAccount));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setLoggedInUser(username));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setUserAccount(userAccount));

    if (
      Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      // START MMPROD-6213 dhrchad - Custom REST API to read profile information
      MMProfileActions.fetchMmProfile(CoreReduxStore.internalStore.dispatch);
      // END MMPROD-6213 dhrchad - Custom REST API to read profile information
      UserActions.fetchUser(CoreReduxStore.internalStore.dispatch);
    }

    callback(true, {});
  } else {
    callback(false, {});
  }
});

_defineProperty(DevAuthentication, 'logout', callback => {
  Authentication.clearSessionStorage();
  Authentication.clearCookiesFromStorage();
  CoreReduxStore.internalStore.dispatch(AuthenticationActions.logout());

  if (callback) {
    callback();
  }
});

export { DevAuthentication as default };
