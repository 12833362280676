/* eslint-disable global-require */
/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2022. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

// MMPROD- 7064 added a custom json file for overriding OOTB messages
export default {
  defaultLocale: 'en-US',
  locales: [
    {
      locale: 'en-US',
      displayName: 'English',
      localeData: () => {
        require('@formatjs/intl-pluralrules/locale-data/en');
        require('@formatjs/intl-numberformat/locale-data/en');
        require('@formatjs/intl-relativetimeformat/locale-data/en');
      },
      messages: require('../locale/MMOverrideOOTBMessages_en.json'),
    },
  ],
};
