import _regeneratorRuntime from '@babel/runtime-corejs3/regenerator';
import _concatInstanceProperty from '@babel/runtime-corejs3/core-js-stable/instance/concat';
import _asyncToGenerator from '@babel/runtime-corejs3/helpers/esm/asyncToGenerator';
import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';
import _JSON$stringify from '@babel/runtime-corejs3/core-js-stable/json/stringify';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService } from '@spm/core';
import { CoreReduxStore } from '@spm/core';
import { sessionStorage } from '@spm/core';
import { EnvUtils } from '@spm/core';
import { SSOVerifierActions } from '@spm/core';
import { UserActions } from '@spm/core';
import AuthenticationActions from './actions';
/* eslint-disable-next-line import/no-cycle */

import Authentication from './Authentication';
import MMProfileActions from '../generated/MMProfile/actions';
/**
 * Callback function executed after getting curam user account.
 *
 * @param {any} success Indicates if curam account successfully retrieved or not.
 * @param {any} response The HTTP response.
 *
 * @private
 */

const populateUserAccountInformation = function populateUserAccountInformation(
  success,
  response,
  input
) {
  if (success) {
    sessionStorage.setItem('username', input.username);
    sessionStorage.setItem('user_account', _JSON$stringify(response));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setLoggedInUser(input.username));
    CoreReduxStore.internalStore.dispatch(AuthenticationActions.setUserAccount(response));

    if (
      Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      // START MMPROD-6213 dhrchad - Custom REST API to read profile information
      MMProfileActions.fetchMmProfile(CoreReduxStore.internalStore.dispatch);
      // END MMPROD-6213 dhrchad - Custom REST API to read profile information
      UserActions.fetchUser(CoreReduxStore.internalStore.dispatch);
    }

    input.callback(true, response);
  } else {
    Authentication.clearSessionStorage();
    Authentication.clearCookiesFromStorage();
    input.callback(false, response);
  }
};
/** Component that will be used in order to login using JAAS Authentication */

const JAASAuthentication = /* #__PURE__ */ _createClass(function JAASAuthentication() {
  _classCallCheck(this, JAASAuthentication);
});

// START MMPROD-6213 - DHRCHAD - Fix exception when user logged in
const userAccountLogin = 'v1/ua/user_account_login';
// END MMPROD-6213 - DHRCHAD - Fix exception when user logged in

_defineProperty(
  JAASAuthentication,
  'login',
  /* #__PURE__ */ (function () {
    const _ref = _asyncToGenerator(
      /* #__PURE__ */ _regeneratorRuntime.mark(function _callee(input) {
        let _context;

        let loginUrl;
        let loginData;
        let securityCheckPostResult;
        let userAccountLoginGetResult;
        return _regeneratorRuntime.wrap(_context2 => {
          while (1) {
            switch ((_context2.prev = _context2.next)) {
              case 0:
                if (!(input.ssoPreCheck === true && input.ssoLogin !== 'true')) {
                  _context2.next = 3;
                  break;
                }

                input.callback(false);
                return _context2.abrupt('return');

              case 3:
                loginUrl = _concatInstanceProperty((_context = '')).call(
                  _context,
                  process.env.REACT_APP_REST_URL,
                  '/j_security_check'
                );
                loginData = {
                  j_username: input.username,
                  j_password: input.password,
                  user_type: 'EXTERNAL',
                };
                _context2.next = 7;
                return Authentication.postCall(loginUrl, loginData, 'form');

              case 7:
                securityCheckPostResult = _context2.sent;

                if (!securityCheckPostResult.success) {
                  _context2.next = 15;
                  break;
                }

                _context2.next = 11;
                // START MMPROD-6213 - DHRCHAD - Fix exception when user logged in
                return Authentication.getCall(
                  Authentication.getApiUrl(userAccountLogin),
                  {}
                );
                // START MMPROD-6213 - DHRCHAD - Fix exception when user logged in

              case 11:
                userAccountLoginGetResult = _context2.sent;
                populateUserAccountInformation(
                  userAccountLoginGetResult.success,
                  userAccountLoginGetResult.response,
                  input
                );
                _context2.next = 16;
                break;

              case 15:
                populateUserAccountInformation(false, securityCheckPostResult.response, input);

              case 16:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee);
      })
    );

    return function (_x) {
      return Reflect.apply(_ref, this, arguments);
    };
  })()
);

_defineProperty(
  JAASAuthentication,
  'logout',
  /* #__PURE__ */ (function () {
    const _ref2 = _asyncToGenerator(
      /* #__PURE__ */ _regeneratorRuntime.mark(function _callee2(callback, reportLogoutError) {
        let _context3;
        let _context4;

        let logoutUrl;
        let data;
        let logoutResult;
        return _regeneratorRuntime.wrap(_context5 => {
          while (1) {
            switch ((_context5.prev = _context5.next)) {
              case 0:
                logoutUrl = _concatInstanceProperty(
                  (_context3 = _concatInstanceProperty((_context4 = '')).call(
                    _context4,
                    EnvUtils.getEnvironmentProperty('REACT_APP_REST_URL', '/Rest')
                  ))
                ).call(
                  _context3,
                  EnvUtils.getEnvironmentProperty('REACT_APP_LOGOUT_END_POINT', '/logout')
                );
                data = {};
                Authentication.clearSessionStorage();
                CoreReduxStore.internalStore.dispatch(AuthenticationActions.logout());
                _context5.next = 6;
                return Authentication.postCall(logoutUrl, data);

              case 6:
                logoutResult = _context5.sent;

                if (!logoutResult.success) {
                  _context5.next = 13;
                  break;
                }

                Authentication.clearCookiesFromStorage();
                SSOVerifierActions.setSSOVerifier(CoreReduxStore.internalStore.dispatch, true);
                RESTService.csrfToken = undefined;
                _context5.next = 15;
                break;

              case 13:
                if (!(!logoutResult.success && reportLogoutError)) {
                  _context5.next = 15;
                  break;
                }

                throw new Error(logoutResult.response);

              case 15:
                if (callback) {
                  callback();
                }

              case 16:
              case 'end':
                return _context5.stop();
            }
          }
        }, _callee2);
      })
    );

    return function (_x2, _x3) {
      return Reflect.apply(_ref2, this, arguments);
    };
  })()
);

export { JAASAuthentication as default };
