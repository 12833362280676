/* eslint-disable no-console */

/* eslint-disable import/prefer-default-export */

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * HOF to deprecate functions and warn the use of the function.
 * No message is displayed on production mode.
 *
 * @param {function} fn - the function to deprecate.
 * @param {string} msg - the message to warn.
 * @param {function} warn - the function to warn.
 * @returns
 */
export var deprecate = function deprecate(fn, msg) {
  var warn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : console.warn;
  return function () {
    if (!(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test')) {
      warn(msg);
    }

    return fn.apply(void 0, arguments);
  };
};

export { deprecate as default };