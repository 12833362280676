/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID,
  GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID,
} from './actionTypes';

/**
 * Updates the details in the 'MMNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMmCommunicationsByCommunicationIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID);

/**
 * Updates the indicator of creation of details in the 'MMNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMmCommunicationsByCommunicationIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMmCommunicationsByCommunicationIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID);

/**
 * Updates the details in the 'MMNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getMmCommunicationsAttachmentsByAttachmentIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID);

/**
 * Updates the indicator of creation of details in the 'MMNotices' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetMmCommunicationsAttachmentsByAttachmentIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getMmCommunicationsAttachmentsByAttachmentIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID
  );

const reducers = combineReducers({
  getMmCommunicationsByCommunicationId: getMmCommunicationsByCommunicationIdReducer,

  isFetchingGetMmCommunicationsByCommunicationId:
    isFetchingGetMmCommunicationsByCommunicationIdReducer,
  getMmCommunicationsByCommunicationIdError: getMmCommunicationsByCommunicationIdErrorReducer,

  getMmCommunicationsAttachmentsByAttachmentId: getMmCommunicationsAttachmentsByAttachmentIdReducer,

  isFetchingGetMmCommunicationsAttachmentsByAttachmentId:
    isFetchingGetMmCommunicationsAttachmentsByAttachmentIdReducer,
  getMmCommunicationsAttachmentsByAttachmentIdError:
    getMmCommunicationsAttachmentsByAttachmentIdErrorReducer,
});

export default { reducers };
