/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/mm/online_renewal_ridp_form
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmOnlineRenewalRidpFormUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/online_renewal_ridp_form`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMMOnlineRenewalCaseDtlsResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/mm/submit_online_renewal_ridp
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmSubmitOnlineRenewalRidpUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/submit_online_renewal_ridp`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMMOnlineRenewalCaseDtlsResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets UAOnlineCategoryList from api:
 * /v1/ua/mm/online_categories
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMmOnlineCategoriesUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/online_categories`,
    models.UAOnlineCategoryList
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/is_Renewal_script
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmIsRenewalScriptUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/is_Renewal_script`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewMMIsRenewalScriptResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets MMOnlineRenewalToDo from api:
 * /v1/ua/mm/is_Renewal_ToDo_Display
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchMmIsRenewalToDoDisplayUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/is_Renewal_ToDo_Display`,
    models.MMOnlineRenewalToDo
  );
};

/**
 * Post request to path:
 * /v1/ua/mm/store_Online_Renewal
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMmStoreOnlineRenewalUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/mm/store_Online_Renewal`;

  RESTService.post(url, data, (success, response) => {
    callback(success, response);
  });
};

export {
  postMmOnlineRenewalRidpFormUtil,
  postMmSubmitOnlineRenewalRidpUtil,
  fetchMmOnlineCategoriesUtil,
  postMmIsRenewalScriptUtil,
  fetchMmIsRenewalToDoDisplayUtil,
  postMmStoreOnlineRenewalUtil,
};
