/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID,
  GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID,
} from './actionTypes';

import {
  getMmCommunicationsByCommunicationIdUtil,
  getMmCommunicationsAttachmentsByAttachmentIdUtil,
} from './utils';

/**
 * Contains the action creator functions that update the MMNotices slice of the store.
 *
 * @namespace MMNoticesActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/communications/{communication_id}
   *
   * @param {string} communication_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMNoticesActions
   */
  static getMmCommunicationsByCommunicationId = (dispatch, communication_id, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getMmCommunicationsByCommunicationIdUtil.bind(null, communication_id),

      GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getMmCommunicationsByCommunicationId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMNoticesActions
   */
  static resetGetMmCommunicationsByCommunicationId = (dispatch, data) => {
    const json = {
      type: GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getMmCommunicationsByCommunicationId.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMNoticesActions
   */
  static resetGetMmCommunicationsByCommunicationIdError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/communications/{communication_id}/attachments/{attachment_id}
   *
   * @param {string} attachment_id.
   * @param {string} communication_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMNoticesActions
   */
  static getMmCommunicationsAttachmentsByAttachmentId = (
    dispatch,
    attachment_id,
    communication_id,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getMmCommunicationsAttachmentsByAttachmentIdUtil.bind(null, attachment_id, communication_id),

      GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getMmCommunicationsAttachmentsByAttachmentId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMNoticesActions
   */
  static resetGetMmCommunicationsAttachmentsByAttachmentId = (dispatch, data) => {
    const json = {
      type: GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getMmCommunicationsAttachmentsByAttachmentId.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMNoticesActions
   */
  static resetGetMmCommunicationsAttachmentsByAttachmentIdError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID.failureAction
    );
  };
}
