/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const ID = {
  SESSION_TIMEOUT_MODAL_DIALOG_ID: 'session-timeout-modal-dialog',
  SESSION_TIMEOUT_MODAL_DIALOG_STAY_LOGGED_IN_BUTTON_ID:
    'session-timeout-modal-dialog-stay-logged-in-button',
  SESSION_TIMEOUT_MODAL_DIALOG_STAY_SIGN_OUT_BUTTON_ID:
    'session-timeout-modal-dialog-sign-out-button',
  SESSION_TIMEOUT_MODAL_DIALOG_INFORMATIONAL_MESSAGE_ID:
    'session-timeout-modal-informational-message',
  SESSION_TIMEOUT_MODAL_DIALOG_COUNTDOWN_TIMER_ID: 'session-timeout-modal-countdown-timer',
};

/* eslint-disable import/prefer-default-export */
export { ID };
