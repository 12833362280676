/* eslint-disable global-require */
/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import {
  UserPreferencesSelectors,
  IntlConfigSelectors,
  IntlConfigActions,
} from '@spm/universal-access';
/**
 * Add locale data and polyfills if the browser does not support native Intl
 *
 * @param {function} localeData - function to load locale data.
 */
const addLocaleData = localeData => {
  const isIntlSupported =
    typeof Intl !== 'undefined' &&
    Intl.DateTimeFormat &&
    Intl.NumberFormat &&
    Intl.PluralRules &&
    Intl.RelativeTimeFormat;

  if (!isIntlSupported) {
    require('@formatjs/intl-getcanonicallocales/polyfill');
    require('@formatjs/intl-locale/polyfill');
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-numberformat/polyfill');
    require('@formatjs/intl-relativetimeformat/polyfill');
  }
  if (localeData && typeof localeData === 'function') localeData();
};

/** Handles React-Intl Initialization. */
class IntlInit extends Component {
  /**
   * Creates an instance of class and loads the optional config/intl.config.js.
   *
   * @param {any} props the props for the component
   * @memberof IntlInit
   */
  constructor(props) {
    super(props);
    // Build a context to check whether the config exists
    const req = require.context('../config', false, /^\.\/intl.config.js$/);
    // if it is found load the intl config
    if (req.keys().length > 0) {
      props.loadIntlConfig(req(req.keys()[0]).default);
    } else {
      // eslint-disable-next-line no-console
      console.debug('config/intl.config.js not found');
    }
  }

  /**
   * IntlInit component entry point.
   */
  render() {
    const { userSelectedLanguage, children, localeConfig } = this.props;
    let localeMessages = {};
    if (localeConfig) {
      const { localeData, messages, direction } = localeConfig;

      // Set the document direction based on the selected locale
      document.dir = direction || 'ltr';

      // Add locale data for the associated language. This will be used to provided
      // functions such as pluralization when formatting strings.
      addLocaleData(localeData);

      // Gets the translations
      if (messages) {
        localeMessages = messages;
      }
    }

    return (
      <IntlProvider
        key={userSelectedLanguage}
        defaultLocale={userSelectedLanguage}
        locale={userSelectedLanguage}
        messages={localeMessages}
        textComponent="span"
      >
        {children}
      </IntlProvider>
    );
  }
}

IntlInit.propTypes = {
  children: PropTypes.node.isRequired,
  loadIntlConfig: PropTypes.func.isRequired,
  localeConfig: PropTypes.shape({
    direction: PropTypes.oneOf(['rtl', 'ltr']),
    displayName: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    localeData: PropTypes.func.isRequired,
    messages: PropTypes.object,
  }),
  userSelectedLanguage: PropTypes.string,
};

IntlInit.defaultProps = {
  localeConfig: {
    locale: 'en',
    displayName: 'English',
    localeData: () => {},
    messages: {},
  },
  userSelectedLanguage: 'en',
};

/**
 * Map the users selected language to the props that will trigger a
 * re-render of the App when the language changes and provides the set of
 * translations required for that language.
 * @param {*} state
 */
const mapStateToProps = state => ({
  localeConfig: IntlConfigSelectors.getLocaleConfig(
    state,
    UserPreferencesSelectors.getUserSelectedLanguage(state)
  ),
  userSelectedLanguage: UserPreferencesSelectors.getUserSelectedLanguage(state),
});

/**
 * Retrieve data from related rest APIs and updates the Redux store.
 *
 * @export
 * @param {function} dispatch the dispatch function
 * @returns {Object} the mappings.
 */
const mapDispatchToProps = dispatch => ({
  loadIntlConfig: intlConfig => IntlConfigActions.loadIntlConfig(dispatch, intlConfig),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntlInit);
