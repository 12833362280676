/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_ONLINE_CATEGORIES,
  CREATE_APPLICATION_FORM,
  SET_SELECTED_APPLICATION_TYPE,
} from './actionTypes';

/**
 * Updates the details in the 'SampleModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchOnlineCategoriesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_ONLINE_CATEGORIES);

/**
 * Updates the indicator of creation of details in the 'SampleModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchOnlineCategoriesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_ONLINE_CATEGORIES);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchOnlineCategoriesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_ONLINE_CATEGORIES);

/**
 * Updates the indicator of creation of details in the 'SampleModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostApplicationFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, CREATE_APPLICATION_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const createApplicationFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, CREATE_APPLICATION_FORM);

/**
 * Updates the store with the new data.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const setSelectedApplicationTypeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, { successAction: SET_SELECTED_APPLICATION_TYPE });

const reducers = combineReducers({
  fetchOnlineCategories: fetchOnlineCategoriesReducer,

  isFetchingFetchOnlineCategories: isFetchingFetchOnlineCategoriesReducer,
  fetchOnlineCategoriesError: fetchOnlineCategoriesErrorReducer,

  isFetchingPostApplicationForm: isFetchingPostApplicationFormReducer,
  createApplicationFormError: createApplicationFormErrorReducer,

  selectedApplicationType: setSelectedApplicationTypeReducer,
});

export default { reducers };
