/* eslint-disable no-console */
/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020,2021. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SystemConfigurationActions, SSOVerifierActions, SSOVerifierSelectors } from '@spm/core';
import { AppSpinner } from '@spm/core-ui';
import Authentication from '../../modules/Authentication/Authentication';
import SSOAuthentication from '../../modules/Authentication/SSOAuthentication';
import MMSSOAuthentication from '../MMIDPLogin/MMSSOAuthentication';
import displayLogStatements from '../MMCommon/components/common/MMICAMConstants';

import MMRandomIDGenerator from '../MMCommon/components/common/MMRandomIDGenerator';

/**
 * Logs the user in as the 'publiccitizen' account.
 *
 * If we are not authenticated at all we need to log in as PublicCitizen so that we can access the system configuration
 *
 * @param {function} dispatch function that dispatches values to Redux
 * @returns {Promise} Promise object represents if the login as public citizen completed
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function loginAsPublicCitizen(dispatch) {
  if (displayLogStatements) {
    console.log('SRC SSOVerifier.loginAsPublicCitizen() ');
  }
  return new Promise(resolve => {
    /**
     * Loads the system configuration if the previous call was successful.
     * @param {success} boolean
     */
    const callback = success => {
      if (!Authentication.userTypeIs([Authentication.USER_TYPES.NO_USER_TYPE]) && success) {
        if (displayLogStatements) {
          console.log('SRC SSOVerifier.loginAsPublicCitizen() - getSystemProperties');
        }

        SystemConfigurationActions.getSystemPropertiesAction(dispatch, () => {
          resolve();
        });
      }
    };
    if (Authentication.userTypeIs([Authentication.USER_TYPES.NO_USER_TYPE])) {
      if (displayLogStatements) {
        console.log(
          'SRC SSOVerifier.loginAsPublicCitizen() - Authentication.loginAsPublicCitizen(callback)'
        );
      }
      Authentication.loginAsPublicCitizen(callback);
    } else {
      if (displayLogStatements) {
        console.log(
          'SRC SSOVerifier.loginAsPublicCitizen() - SystemConfigurationActions.getSystemPropertiesAction'
        );
      }
      SystemConfigurationActions.getSystemPropertiesAction(dispatch, () => {
        resolve();
      });
    }
  });
}

/**
 *  Performs the against the Single-Sign-On for SP-init flow.
 *
 * The precheck is responsible for retrieving the user account only if the user is authenticated with the IdP.
 * For the SP-init flow, there is additional calls between the client and the application server/IdP.
 *
 * @param {function} dispatch function that dispatches values to Redux
 * @see SSOVerifier.executeSSOPreCheck
 */

// eslint-disable-next-line sonarjs/cognitive-complexity
async function doSPInitSSOPrecheck(dispatch) {
  // flag to indicate if we need to use fallback behaviour
  let isPrecheckSuccess = false;
  if (displayLogStatements) {
    console.log(
      'SSOVerifier.doSPInitSSOPrecheck() - call SSOAuthentication.callProtectedResource()'
    );
  }
  // Call a protected API resource (i.e. login-protected content) from the application server.
  // This call will be intercepted by the application server's Trust Assertion Interceptor.
  // The TAI responds a HTTP response with the SAML request message to be sent to the IdP.
  // The success status of the call to the protected resource is irrelevant as different application servers can respond with SAML messages but with different HTTP codes.
  const { response } = await SSOAuthentication.callProtectedResource();
  if (displayLogStatements) {
    console.log(
      `SSOVerifier.doSPInitSSOPrecheck() - call SSOAuthentication.parseSAMLRequestPayload() - response: ${JSON.stringify(
        response
      )}`
    );
  }
  // The SAML message is parsed to extract the SAMLRequestKey and optional RelayState.
  const idpSamlRequestData = SSOAuthentication.parseSAMLRequestPayload(response);

  // If there isn't a SAML request key, then there is a general problem (like the AuthReqest not being generated).
  // If there is a SAMLRequestKey, we can proceed to contact the IdP to determine the current auth status of the user.
  if (idpSamlRequestData.samlRequestKey) {
    SSOAuthentication.clearCookiesAndSessionStorage();
    if (displayLogStatements) {
      console.log(
        'SSOVerifier.doSPInitSSOPrecheck() - call SSOAuthentication.callIdpSAMLLoginWithSAMLRequest()'
      );
      console.log(`SSOVerifier.doSPInitSSOPrecheck() - idpSamlRequestData${idpSamlRequestData}`);
    }
    // Send the SAMLRequestKey (and optional SAML relay state which is required when provided) to the IdP.
    const idpResponse = await SSOAuthentication.callIdpSAMLLoginWithSAMLRequest(idpSamlRequestData);

    if (displayLogStatements) {
      console.log(`SSOVerifier.doSPInitSSOPrecheck() - idpResponse: ${idpResponse}`);
    }
    if (idpResponse.success && idpResponse.response) {
      // The IdP will respond with either a SAML message or a login form depending on the auth status of the user.
      // If the SAML message contains a SAMLResponseKey, then the user is already authenticated with the IdP.
      const idpSamlResponseData = SSOAuthentication.parseSAMLResponsePayload(idpResponse.response);
      const isLoggedInTheSSO = !!idpSamlResponseData.samlResponseKey;
      if (isLoggedInTheSSO) {
        if (displayLogStatements) {
          console.log(
            'SSOVerifier.doSPInitSSOPrecheck() - call SSOAuthentication.callACSWithSAMLResponse()'
          );
        }

        // The SAMLResponseKey signature is forwarded to the Assertion Consumer Service (ACS) which will validate the SAMLResponseKey.
        const acsURLResponse = await SSOAuthentication.callACSWithSAMLResponse(idpSamlResponseData);

        // If the validation with ACS is successful, then the user's SAMLResponseKey is valid with the IdP
        if (acsURLResponse.success) {
          if (displayLogStatements) {
            console.log(
              'SSOVerifier.doSPInitSSOPrecheck() - call MMSSOAuthentication.getUserAccount()'
            );
          }

          // Retrieve the user's account and store any details required.
          const getUserAccountResponse = await SSOAuthentication.getUserAccount();

          // if the user is already authenticated, we can't know their username.
          // rather than leaving undefined, we specifically pass null
          // this won't stop the user account being loaded
          SSOAuthentication.loadUserAccount(getUserAccountResponse.response, null);
          isPrecheckSuccess = true;
        }
      }
    }
  }

  // fallback behaviour if the precheck failed conditions for any reason
  if (!isPrecheckSuccess) {
    await loginAsPublicCitizen(dispatch);
  }
}
/**
 * Resolves the login status of the user
 *
 * If we are not authenticated at all we need to log in as PUBLIC so that we can access the system configuration.
 * If we are already authenticated as a GENERATED user and in SSO mode, then we need to log out and in as PUBLIC. Any feature upon load (e.g. Eligibility) will resolve the access constraints required for that feature (such as remaining PUBLIC or logging the user in as GENERATED).
 * If we are already authenticated as a user type (e.g. PUBLIC, STANDARD, LINKED), then we don't need to make any attempts to log-in or log-out.
 *
 * @param {function} dispatch function that dispatches values to Redux
 * @returns {Promise} Promise object represents if the login as public citizen completed
 */
/* eslint-disable-next-line no-unused-vars */
async function resolveUserLogin(dispatch) {
  // Function is customized to remove public citizen login when loading landing page.  These ID's have been found to cause issues with SAML
  // authentication requests being rejected by weblogic and showing browser login prompts.  This does not affect functionality.

  // no user type - login as public
  if (Authentication.userTypeIs([Authentication.USER_TYPES.NO_USER_TYPE])) {
    if (displayLogStatements) {
      console.log(
        'SSOVerifier.resolveUserLogin: user type === no user type',
        Authentication.getUserAccount()
      );
    }
    await loginAsPublicCitizen(dispatch);
    // await callLoginAsPublicCitizen(() => callSystemConfig(dispatch));
  } else if (
    Authentication.isSSOEnabled() &&
    Authentication.userTypeIs([
      Authentication.USER_TYPES.PUBLIC,
      Authentication.USER_TYPES.GENERATED,
    ])
  ) {
    // generated user - logout first then login as public.
    if (displayLogStatements) {
      console.log(
        'SSOVerifier.resolveUserLogin: user type === generated',
        Authentication.getUserAccount()
      );
    }
    MMSSOAuthentication.removeAuthenticatedUser();
    // await callLoginAsPublicCitizen(() => callSystemConfig(dispatch));
  } else {
    // any other user type - poll system configs
    // eslint-disable-next-line no-lonely-if
    if (displayLogStatements) {
      console.log(
        'SSOVerifier.resolveUserLogin: user type === public / linked / standard',
        Authentication.getUserAccount()
      );
    }
    // await callSystemConfig(dispatch);
  }
}

/**
 * Performs the SSO precheck against the Single-Sign-On for IdP-init flow.
 *
 * The precheck is responsible for retrieving the user account only if the user is authenticated with the IdP.
 *
 * @param {function} dispatch function that dispatches values to Redux
 * @see SSOVerifier.executeSSOPreCheck
 */

/* eslint-disable-next-line sonarjs/cognitive-complexity */
async function doIdpInitSSOPrecheck(dispatch) {
  if (displayLogStatements) {
    console.log('Inside IDP Init SSO Precheck');
  }
  const userAccount = await Authentication.getCall(
    Authentication.getApiUrl('v1/ua/user_account_login'),
    {}
  );

  let isPrecheckSuccess = false;

  if (userAccount && userAccount.response !== null) {
    if (displayLogStatements) {
      console.log('Inside userAccount Response processing');
    }
    // If user is a generated or public user, kill the session.
    if (
      userAccount.response.userType === Authentication.USER_TYPES.GENERATED ||
      userAccount.response.userType === Authentication.USER_TYPES.PUBLIC
    ) {
      if (displayLogStatements) {
        console.log(`user Type is : ${userAccount.response.userType}`);
      }
      // This will clear the redux, re-triggering the SSOVerifier, so stopping pre-check processing with return
      // await AuthenticationTypes.JAASAuthentication.logout();

      // If user is STANDARD or LINKED, then external user already has session, something has just triggered the SSO Verifier.
      // This should generally only occur after being re-directed from Service Provider back to React app
    } else if (
      userAccount.response.userType === Authentication.USER_TYPES.STANDARD ||
      userAccount.response.userType === Authentication.USER_TYPES.LINKED
    ) {
      if (displayLogStatements) {
        console.log('user Type is :', userAccount.response.userType);
        console.log('SSOVerifier.doSPInitSSOPrecheck: made getUserAccount', userAccount);
        console.log('SSOVerifier.doSPInitSSOPrecheck: loadUserAccount');
      }
      // Check if sessionStorage already has a random ID
      let sessionID = sessionStorage.getItem('sessionID');
      if (!sessionID) {
        // Generate a random ID using MMRandomIDGenerator if sessionStorage doesn't have one
        sessionID = MMRandomIDGenerator();
        // Store the generated random ID in sessionStorage
        sessionStorage.setItem('randomId', sessionID);
      }
      MMSSOAuthentication.loadUserAccount(userAccount.response, null);
      isPrecheckSuccess = true;
    }
  }

  if (displayLogStatements) {
    console.log('isPrecheckSuccess :', isPrecheckSuccess);
  }

  if (!isPrecheckSuccess) {
    const { response } = await SSOAuthentication.callProtectedResource();
    const idpSamlRequestData = SSOAuthentication.parseSAMLRequestPayload(response);

    if (displayLogStatements) {
      console.log('idpSamlRequestData.samlRequestKey :', idpSamlRequestData.samlRequestKey);
    }
    if (idpSamlRequestData.samlRequestKey) {
      if (displayLogStatements) {
        console.log('SSOVerifier.doSPInitSSOPrecheck: has SAMLRequestKey');
      }
      // Send the SAMLRequestKey (and optional SAML relay state which is required when provided) to the IdP.
      const idpResponse = await SSOAuthentication.callIdPLoginInitial();
      if (displayLogStatements) {
        console.log(
          'SSOVerifier.doSPInitSSOPrecheck: made callIdpSAMLLoginWithSAMLRequest',
          idpResponse
        );
      }

      if (idpResponse.success && idpResponse.response) {
        // The IdP will respond with either a SAML message or a login form depending on the auth status of the user.
        // If the SAML message contains a SAMLResponseKey, then the user is already authenticated with the IdP.
        const idpSamlResponseData = SSOAuthentication.parseSAMLResponsePayload(
          idpResponse.response
        );
        if (displayLogStatements) {
          console.log(
            'SSOVerifier.doSPInitSSOPrecheck: parsed callIdpSAMLLoginWithSAMLRequest',
            idpSamlResponseData
          );
        }
        const isLoggedInTheSSO = !!idpSamlResponseData.samlResponseKey;

        if (isLoggedInTheSSO) {
          if (displayLogStatements) {
            console.log('SSOVerifier.doSPInitSSOPrecheck: we are already logged into the SSO');
          }
          // The SAMLResponseKey signature is forwarded to the Assertion Consumer Service (ACS) which will validate the SAMLResponseKey.
          const acsURLResponse = await SSOAuthentication.callACSWithSAMLResponse(
            idpSamlResponseData
          );
          if (displayLogStatements) {
            console.log(
              'SSOVerifier.doSPInitSSOPrecheck: made callACSWithSAMLResponse',
              acsURLResponse
            );
          }
          // If the validation with ACS is successful, then the user's SAMLResponseKey is valid with the IdP
          if (acsURLResponse.success) {
            if (displayLogStatements) {
              console.log('SSOVerifier.doSPInitSSOPrecheck: SAMLResponse is validated with ACS');
            }
            // Retrieve the user's account and store any details required.
            const getUserAccountResponse = await SSOAuthentication.getUserAccount();
            if (displayLogStatements) {
              console.log(
                'SSOVerifier.doSPInitSSOPrecheck: made getUserAccount',
                getUserAccountResponse
              );
            }

            // if the user is already authenticated, we can't know their username.
            // rather than leaving undefined, we specifically pass null
            // this won't stop the user account being loaded
            if (displayLogStatements) {
              console.log('SSOVerifier.doSPInitSSOPrecheck: loadUserAccount');
            }
            SSOAuthentication.loadUserAccount(getUserAccountResponse.response, null);
            isPrecheckSuccess = true;
          }
        }
      }
    }
  }

  if (!isPrecheckSuccess) {
    if (displayLogStatements) {
      console.log('SSOVerifier.doSPInitSSOPrecheck() - SAML Response Key is Not Present');
    }
    await resolveUserLogin(dispatch);
  }
}

/**
 * Executes the precheck for Single-Sign-On. The SSO precheck establishes the current logged-in status for the user against the IdP.
 *
 * The precheck is required as a user may already be authenticated with the IdP prior to navigating to Universal Access. When they arrive, we need to determine their authentication status and make initial communication with the application server/IdP (for call interception). This precheck differs between the IdP-init and SP-init flows and also with responses from different application servers.
 * If the user is already authenticated with the IdP, then the user can also be logged-in to Universal Access (by retrieving their user account and loading their user profile).
 * If the user is not already authenticated with the IdP, then the user can continue to use Universal Access as normal as a public/generated user. The user can decide at a later point to login which will use the login function configured for their registered Authentication Method.
 *
 * In situations where Single-Sign-On is not configured, the user is found to be unauthenticated with the IdP, or any error occurs during the precheck, the user will be logged-in to to Universal Access application as a public citizen
 *
 * @param {function} dispatch function that dispatches values to Redux
 */
async function executeSSOPreCheck(dispatch) {
  if (displayLogStatements) {
    console.log('SSOVerifier.executeSSOPreCheck() - call SSOVerifierActions.setSSOVerifier()');
  }
  // set flag to indicate we are verifying against the SSO
  SSOVerifierActions.setSSOVerifier(dispatch, true);

  // SSO prechecks only apply when SSO is enabled. If not, just authenticate as publiccitizen as normal
  if (Authentication.isSSOEnabled()) {
    if (Authentication.isSSOTypeSP()) {
      if (displayLogStatements) {
        console.log('SSOVerifier.executeSSOPreCheck() - call doSPInitSSOPrecheck()');
      }
      // SSO SP-init
      doSPInitSSOPrecheck(dispatch);
    } else {
      if (displayLogStatements) {
        console.log('SSOVerifier.executeSSOPreCheck() - call doIdpInitSSOPrecheck()');
      }
      // SSO IdP-init
      doIdpInitSSOPrecheck(dispatch);
    }
  } else {
    if (displayLogStatements) {
      console.log('SSOVerifier.executeSSOPreCheck() - call loginAsPublicCitizen()');
    }
    // not SSO - login as public citizen
    await loginAsPublicCitizen(dispatch);
  }
  if (displayLogStatements) {
    console.log('SSOVerifier.executeSSOPreCheck() - call setSSOVerifier()');
  }

  // regardless of precheck, we set a flag in Redux not to re-execute on render again
  SSOVerifierActions.setSSOVerifier(dispatch, false);
}

/**
 * This component will be responsible for verifying if the user was logged in an
 * SSO environment and try to use that in order to have it already logged in
 * the application
 *
 * @param {Object} children children that the component will render
 * @param {Object} placeholder place holder that the object will render when it is loading
 */
const SSOVerifier = ({ children, placeholder = <AppSpinner block small /> }) => {
  const dispatch = useDispatch();
  const isVerifyingSSO = useSelector(state => SSOVerifierSelectors.ssoVerifier(state));

  useEffect(() => {
    executeSSOPreCheck(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (isVerifyingSSO) {
      executeSSOPreCheck(dispatch);
    }
  }, [dispatch, isVerifyingSSO]);
  if (displayLogStatements) {
    console.log('children', children);
  }
  return isVerifyingSSO ? placeholder : children;
};

export default SSOVerifier;
