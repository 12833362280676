import _createClass from '@babel/runtime-corejs3/helpers/esm/createClass';
import _classCallCheck from '@babel/runtime-corejs3/helpers/esm/classCallCheck';
import _defineProperty from '@babel/runtime-corejs3/helpers/esm/defineProperty';

/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Authentication Action Types
 * @namespace AuthenticationActionTypes
 */
const actionTypes = /* #__PURE__ */ _createClass(function actionTypes() {
  _classCallCheck(this, actionTypes);
});

_defineProperty(actionTypes, 'SET_LOGGED_IN_USER', 'SET_LOGGED_IN_USER');

_defineProperty(actionTypes, 'SET_SSO_TOKEN', 'SET_SSO_TOKEN');

_defineProperty(actionTypes, 'LOGOUT', 'LOGOUT');

_defineProperty(actionTypes, 'SET_USER_ACCOUNT', 'SET_USER_ACCOUNT');

export { actionTypes as default };
