/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Stay logged in?',
    id: 'SessionTimeout_title',
  },
  titleAriaLabel: {
    defaultMessage:
      '{titleText}. Select the {logOutBtnText} button to logout or {stayLoggedInBtnText} to stay logged in.',
    id: 'SessionTimeout_titleAriaLabel',
  },
  primaryMsg: {
    defaultMessage:
      "You have been inactive for a while so we'll log you out to keep your information secure.",
    id: 'SessionTimeout_primaryMsg',
  },
  timerHeading: {
    defaultMessage: 'Time remaining:',
    id: 'SessionTimeout_timeoutHeading',
  },
  stayButton: {
    defaultMessage: 'Stay logged in',
    id: 'SessionTimeout_stayButton',
  },
  signOutButton: {
    defaultMessage: 'Log out',
    id: 'SessionTimeout_signoutButton',
  },
});
