/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_MM_ONLINE_RENEWAL_RIDP_FORM,
  POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP,
  FETCH_MM_ONLINE_CATEGORIES,
  POST_MM_IS_RENEWAL_SCRIPT,
  FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY,
  POST_MM_STORE_ONLINE_RENEWAL,
} from './actionTypes';

import {
  postMmOnlineRenewalRidpFormUtil,
  postMmSubmitOnlineRenewalRidpUtil,
  fetchMmOnlineCategoriesUtil,
  postMmIsRenewalScriptUtil,
  fetchMmIsRenewalToDoDisplayUtil,
  postMmStoreOnlineRenewalUtil,
} from './utils';

/**
 * Contains the action creator functions that update the MMOnlineRenewal slice of the store.
 *
 * @namespace MMOnlineRenewalActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/online_renewal_ridp_form
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static postMmOnlineRenewalRidpForm = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmOnlineRenewalRidpFormUtil.bind(null, {}),
      POST_MM_ONLINE_RENEWAL_RIDP_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmOnlineRenewalRidpForm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmOnlineRenewalRidpForm = (dispatch, data) => {
    const json = {
      type: POST_MM_ONLINE_RENEWAL_RIDP_FORM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmOnlineRenewalRidpForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmOnlineRenewalRidpFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_ONLINE_RENEWAL_RIDP_FORM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/submit_online_renewal_ridp
   *
   * @param {NewMMOnlineRenewalPersonKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static postMmSubmitOnlineRenewalRidp = (dispatch, newMMOnlineRenewalPersonKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmSubmitOnlineRenewalRidpUtil.bind(null, newMMOnlineRenewalPersonKey),
      POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmSubmitOnlineRenewalRidp.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmSubmitOnlineRenewalRidp = (dispatch, data) => {
    const json = {
      type: POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmSubmitOnlineRenewalRidp.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmSubmitOnlineRenewalRidpError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_SUBMIT_ONLINE_RENEWAL_RIDP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/online_categories
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static fetchMmOnlineCategories = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmOnlineCategoriesUtil,
      FETCH_MM_ONLINE_CATEGORIES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmOnlineCategories.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetFetchMmOnlineCategories = (dispatch, data) => {
    const json = {
      type: FETCH_MM_ONLINE_CATEGORIES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmOnlineCategories.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetFetchMmOnlineCategoriesError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_ONLINE_CATEGORIES.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/is_Renewal_script
   *
   * @param {NewUAApplicationFormIdentifier} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static postMmIsRenewalScript = (dispatch, newUAApplicationFormIdentifier, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmIsRenewalScriptUtil.bind(null, newUAApplicationFormIdentifier),
      POST_MM_IS_RENEWAL_SCRIPT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmIsRenewalScript.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmIsRenewalScript = (dispatch, data) => {
    const json = {
      type: POST_MM_IS_RENEWAL_SCRIPT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmIsRenewalScript.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmIsRenewalScriptError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_IS_RENEWAL_SCRIPT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/is_Renewal_ToDo_Display
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static fetchMmIsRenewalToDoDisplay = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMmIsRenewalToDoDisplayUtil,
      FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMmIsRenewalToDoDisplay.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetFetchMmIsRenewalToDoDisplay = (dispatch, data) => {
    const json = {
      type: FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMmIsRenewalToDoDisplay.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetFetchMmIsRenewalToDoDisplayError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MM_IS_RENEWAL_TO_DO_DISPLAY.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mm/store_Online_Renewal
   *
   * @param {NewMMRenewalExecutionkey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static postMmStoreOnlineRenewal = (dispatch, newMMRenewalExecutionkey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMmStoreOnlineRenewalUtil.bind(null, newMMRenewalExecutionkey),
      POST_MM_STORE_ONLINE_RENEWAL,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMmStoreOnlineRenewal.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmStoreOnlineRenewal = (dispatch, data) => {
    const json = {
      type: POST_MM_STORE_ONLINE_RENEWAL.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMmStoreOnlineRenewal.
   *
   * @param {function} dispatch
   * @function
   * @memberof MMOnlineRenewalActions
   */
  static resetPostMmStoreOnlineRenewalError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MM_STORE_ONLINE_RENEWAL.failureAction);
  };
}
