/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for MMNotices.
 *
 */

import { ReduxUtils } from '@spm/core';

const GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMNOTICES/GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID`
);

const GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `MMNOTICES/GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID`
);

export {
  GET_MM_COMMUNICATIONS_BY_COMMUNICATION_ID,
  GET_MM_COMMUNICATIONS_ATTACHMENTS_BY_ATTACHMENT_ID,
};
