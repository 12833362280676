/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */ // utils/randomIdGenerator.js
const MMRandomIDGenerator = () => {
  // Generate a random string of alphanumeric characters
  const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';
  for (let i = 0; i < 8; i += 1) {
    randomId += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));
  }
  return randomId;
};

export default MMRandomIDGenerator;
