/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAAddressDetails
 *
 * @class UAAddressDetails
 */
class UAAddressDetails {
  /**
   * Creates an instance of UAAddressDetails.
   * @param {*} json
   * @memberof UAAddressDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.addressLayoutType = json.addressLayoutType;

      // string
      this.addressJSON = json.addressJSON;
    }
  }
}

/**
 * Models the UAAddress
 *
 * @class UAAddress
 */
class UAAddress {
  /**
   * Creates an instance of UAAddress.
   * @param {*} json
   * @memberof UAAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.address_id = json.address_id;

      // string
      this.address = json.address;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // object - UAAddressDetails
      if (json.addressDetails) {
        this.addressDetails = new UAAddressDetails(json.addressDetails);
      }
    }
  }
}

/**
 * Models the UAEmailAddress
 *
 * @class UAEmailAddress
 */
class UAEmailAddress {
  /**
   * Creates an instance of UAEmailAddress.
   * @param {*} json
   * @memberof UAEmailAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.email_address_id = json.email_address_id;

      // string
      this.email = json.email;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }
    }
  }
}

/**
 * Models the UAIdentification
 *
 * @class UAIdentification
 */
class UAIdentification {
  /**
   * Creates an instance of UAIdentification.
   * @param {*} json
   * @memberof UAIdentification
   */
  constructor(json = null) {
    if (json) {
      // string
      this.identificationNumber = json.identificationNumber;

      // object - CodeTableType
      if (json.identificationType) {
        this.identificationType = new CodeTableType(json.identificationType);
      }
    }
  }
}

/**
 * Models the UAPhoneNumber
 *
 * @class UAPhoneNumber
 */
class UAPhoneNumber {
  /**
   * Creates an instance of UAPhoneNumber.
   * @param {*} json
   * @memberof UAPhoneNumber
   */
  constructor(json = null) {
    if (json) {
      // string
      this.phone_number_id = json.phone_number_id;

      // string
      this.areaCode = json.areaCode;

      // string
      this.countryCode = json.countryCode;

      // string
      this.extension = json.extension;

      // string
      this.number = json.number;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }
    }
  }
}

/**
 * Models the UARegisteredPersonDetails
 *
 * @class UARegisteredPersonDetails
 */
class UARegisteredPersonDetails {
  /**
   * Creates an instance of UARegisteredPersonDetails.
   * @param {*} json
   * @memberof UARegisteredPersonDetails
   */
  constructor(json = null) {
    if (json) {
      // array - UAAddress
      if (json.addresses) {
        this.addresses = json.addresses.map(object => new UAAddress(object));
      } else if (json.constructor === Array) {
        this.addresses = json.map(object => new UAAddress(object));
      }

      // array - UAEmailAddress
      if (json.emailAddresses) {
        this.emailAddresses = json.emailAddresses.map(object => new UAEmailAddress(object));
      } else if (json.constructor === Array) {
        this.emailAddresses = json.map(object => new UAEmailAddress(object));
      }

      // array - UAIdentification
      if (json.identifications) {
        this.identifications = json.identifications.map(object => new UAIdentification(object));
      } else if (json.constructor === Array) {
        this.identifications = json.map(object => new UAIdentification(object));
      }

      // array - UAPhoneNumber
      if (json.phoneNumbers) {
        this.phoneNumbers = json.phoneNumbers.map(object => new UAPhoneNumber(object));
      } else if (json.constructor === Array) {
        this.phoneNumbers = json.map(object => new UAPhoneNumber(object));
      }
    }
  }
}

/**
 * Models the UAProfile
 *
 * @class UAProfile
 */
class UAProfile {
  /**
   * Creates an instance of UAProfile.
   * @param {*} json
   * @memberof UAProfile
   */
  constructor(json = null) {
    if (json) {
      // string
      this.person_id = json.person_id;

      // string
      this.dateOfBirth = json.dateOfBirth;

      // string
      this.fullName = json.fullName;

      // string
      this.photo = json.photo;

      // object - UARegisteredPersonDetails
      if (json.registeredPersonDetails) {
        this.registeredPersonDetails = new UARegisteredPersonDetails(json.registeredPersonDetails);
      }
    }
  }
}

export default {
  CodeTableType,

  UAAddressDetails,

  UAAddress,

  UAEmailAddress,

  UAIdentification,

  UAPhoneNumber,

  UARegisteredPersonDetails,

  UAProfile,
};
