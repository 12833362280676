/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets UACommunicationDetails from api:
 * /v1/ua/mm/communications/{communication_id}
 *
 * @param {string} communication_id.
 * @param {any} callback function to call once this function has finished
 */
const getMmCommunicationsByCommunicationIdUtil = (communication_id, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/communications/${communication_id}`,
    models.UACommunicationDetails
  );
};

/**
 * Gets UAAttachment from api:
 * /v1/ua/mm/communications/{communication_id}/attachments/{attachment_id}
 *
 * @param {string} attachment_id.
 * @param {string} communication_id.
 * @param {any} callback function to call once this function has finished
 */
const getMmCommunicationsAttachmentsByAttachmentIdUtil = (
  attachment_id,
  communication_id,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/mm/communications/${communication_id}/attachments/${attachment_id}`,
    models.UAAttachment
  );
};

export {
  getMmCommunicationsByCommunicationIdUtil,
  getMmCommunicationsAttachmentsByAttachmentIdUtil,
};
